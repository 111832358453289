import _ from 'lodash';

import constants from '../lib/constants';
import vemEvents from '../lib/events';
import errorCodes from '../lib/errors';
import { BaseManager } from '../lib/base';
import fetch from 'cross-fetch';

import { Alert } from '../models/alert';

class MetricsManager extends BaseManager {
    /**
     * @class MetricsManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'MetricsManager');

        this._channelId = _.get(args, 'channelId');
        this._sessionId = _.get(args, 'sessionId');
        this._site = _.get(args, 'site');
        this._debugId = _.get(args, 'debugId', null);
        this._vemVersion = _.get(args, 'vemVersion');
        this._devLogs = _.get(args, 'devLogs', false);
        this._dataManager = _.get(args, 'dataManager', null);
        this._configManager = _.get(args, 'configManager', null);
        this._alertManager = _.get(args, 'alertManager', null);
        this._stateManager = _.get(args, 'stateManager', null);
        this._rapidInstance = _.get(args, 'rapidInstance', null);
        this._runMode = _.get(args, 'runMode', constants.runModes.CLIENT);

        this._eventBus.subscribe(constants.events.FETCH_VIDEO_EXPERIENCES_REJECTED, this._onFetchVideoExperiencesRejected.bind(this));
        this._eventBus.subscribe(constants.events.FETCH_REMOTE_CONFIG_REJECTED, this._onFetchRemoteConfigRejected.bind(this));
        this._eventBus.subscribe(constants.events.LOCATION_UPDATED, this._onLocationUpdated.bind(this));
        this._eventBus.subscribe(constants.events.LOCATION_DENIED, this._onLocationUpdated.bind(this));
        this._eventBus.subscribe(constants.events.SCH_VIDEO_PLAYBACK, this._onScheduledVideoPlaybackStart.bind(this));
        this._eventBus.subscribe(constants.events.PLAYLIST_PLAYBACK, this._onPlaylistPlaybackStart.bind(this));
        this._eventBus.subscribe(constants.events.CHYRON_SHOWN, this._onChyronShown.bind(this));
        this._eventBus.subscribe(constants.events.CHYRON_HIDDEN, this._onChyronHidden.bind(this));
        this._eventBus.subscribe(constants.events.CHYRON_TAPPED, this._onChyronTapped.bind(this));
        this._eventBus.subscribe(constants.events.CHYRON_LOCATION_DENIED_SHOWN, this._onChyronLocationDeniedShown.bind(this));
        this._eventBus.subscribe(constants.events.VIDEO_SELECT_SHOWN, this._onVideoSelectionShown.bind(this));
        this._eventBus.subscribe(constants.events.VIDEO_SELECT_HIDDEN, this._onVideoSelectionHidden.bind(this));
        this._eventBus.subscribe(constants.events.VIDEO_SELECT_TAPPED, this._onVideoSelectionTapped.bind(this));
        this._eventBus.subscribe(constants.events.CUSTOM_LOG, this._onCustomLog.bind(this));
        this._eventBus.subscribe(constants.events.REMOTE_LOG, this._onRemoteLog.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_START, this._onPlaybackStart.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_ERROR, this._onPlaybackError.bind(this));
        this._eventBus.subscribe(constants.events.INITIALIZE, this._onInitialize.bind(this));
        this._eventBus.subscribe(constants.events.LOCATION_REQUEST_SERVER_RESOLVED, this._onWaitForLocationResolved.bind(this));
        this._eventBus.subscribe(constants.events.SESSION_ATTRIBUTION, this._onSessionAttribution.bind(this));
        this._eventBus.subscribe(constants.events.COUNTDOWN_SHOWN, this._onCountdownShown.bind(this));
        this._eventBus.subscribe(constants.events.COUNTDOWN_HIDDEN, this._onCountdownHidden.bind(this));
        this._eventBus.subscribe(constants.events.API_PLAY_STREAM, this._onApi_PlayStream.bind(this));
        this._eventBus.subscribe(constants.events.PLAYER_RESPAWN, this._onPlayerRespawn.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_WILL_START, this._onPlaybackWillStart.bind(this));

        this._eventBus.subscribe(vemEvents.onAlertStart, this._onAlertStart.bind(this));
        this._eventBus.subscribe(vemEvents.onAlertAction, this._onAlertAction.bind(this));
        this._eventBus.subscribe(vemEvents.onScheduledVideoStart, this._onScheduledVideoStart.bind(this));
        this._eventBus.subscribe(vemEvents.onScheduledVideoComplete, this._onScheduledVideoEnd.bind(this));
        this._eventBus.subscribe(vemEvents.onPlaylistStart, this._onPlaylistStart.bind(this));
        this._eventBus.subscribe(vemEvents.onPlaylistComplete, this._onPlaylistComplete.bind(this));
        
    }

    _getCommonParams() {
        const output = {
            cid: this._channelId,
            sid: this._sessionId,
            did: this._debugId || 0,
            ver: this._vemVersion,
            rmd: this._runMode,
            site: this._site,
            ts: Date.now()
        };
        return output;
    }
    _getScheduledVideoIds(scheduledVideos) {
        const scheduledVideoIds = [];
        for (const video of scheduledVideos) {
            scheduledVideoIds.push(video.videoId);
        }
        return scheduledVideoIds;
    }
    _getAlerts(alerts) {
        const alertActionNames = [];
        for (const alert of alerts) {
            alertActionNames.push(alert.actionName);
        }
        return alertActionNames;
    }
    async _sendBeacon(params) {
        const logName = '_sendBeacon';
        if ((process.env.NODE_ENV === 'production') || this._devLogs) {
            const logUrl = _.get(this, '_configManager._loggerEndpoint') || constants.VE_MODULE_LOG_URL;
            if (this._devLogs) {
                this.debug.info(logName, 'logUrl', logUrl);
            }
            this.debug.info(logName, 'sending remote log event', params.evt);
            try {
                const response = await fetch(logUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                });
                this.debug.info(logName, 'resolved');
                if (this._devLogs) {
                    this.debug.info(logName, 'response', response);
                }
                if (response.status >= 400) {
                    throw response;
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.debug.info(errorCodes.SKIP_REMOTE_LOG);
        }
    }
    _sendRapidBeacon(beaconType, params, options={}, eventName='', clickParams=null) {
        // check if we have the rapid instance on global window
        let windowRapidInstance = null;
        if (typeof window !== 'undefined') {
            windowRapidInstance = window.rapidInstance || window.myRapidInstance || null;
        }
        if (this._devLogs) {
            if (this._rapidInstance) {
                this.debug.log('_sendRapidBeacon', 'using passed rapid instance');
            } else if (windowRapidInstance) {
                this.debug.log('_sendRapidBeacon', 'using window rapid instance');
            } else {
                this.debug.log('_sendRapidBeacon', 'no rapid instance');
            }
        }
        // fallback to window rapid instance if the rapid instance is not passed as a VE module config param
        const rapidInstance = this._rapidInstance || windowRapidInstance;
        if (this._devLogs) {
            this.debug.log('_sendRapidBeacon', 'beaconType', beaconType);
            this.debug.log('_sendRapidBeacon', 'params', params);
        }
        if (beaconType && rapidInstance && params) {
            switch (beaconType) {
                case constants.rapid.beaconTypes.CLICK:
                    if (this._devLogs) {
                        this.debug.log('_sendRapidBeacon', 'sending rapid beacon click', params, options, clickParams);
                    }
                    rapidInstance.beaconClick(params.sec, params.slk, params.pos, clickParams, null, null, options);
                    break;
                case constants.rapid.beaconTypes.LINK_VIEW:
                    if (this._devLogs) {
                        this.debug.log('_sendRapidBeacon', 'sending rapid beacon link views', params, options);
                    }
                    rapidInstance.beaconLinkViews([params], 0, options);
                    break;
                case constants.rapid.beaconTypes.EVENT:
                    if (this._devLogs) {
                        this.debug.log('_sendRapidBeacon', 'sending rapid beacon event', params, options);
                    }
                    rapidInstance.beaconEvent(eventName, params, eventName);
                    break;
                case constants.rapid.beaconTypes.PERFORMANCE:
                    if (this._devLogs) {
                        this.debug.log('_sendRapidBeacon', 'sending rapid beacon performance data \'' + eventName + '\'', params);
                    }
                    rapidInstance.beaconPerformanceData({
                        perf_usertime: {
                            utm: params
                        }
                    });
                    break;
            }
        }
    }
    _getSlaData(vemsData) {
        const startTime = _.get(vemsData, 'sla_trt_st', 0);
        const pageTime = _.get(vemsData, 'sla_trt_if_page', 0);
        const vemsTime = _.get(vemsData, 'sla_trt_if_vems', 0);
        const vemTime = _.get(vemsData, 'sla_trt_if_vem', 0);
        const endTime = _.get(vemsData, 'sla_trt_et', 0);
        const startTime_to_pageTime = pageTime - startTime;
        const pageTime_to_vemTime = vemTime - pageTime;
        return {
            startTime: startTime,
            endTime: endTime,
            pageTime: pageTime,
            vemTime: vemTime,
            vemsTime: vemsTime,
            vemsTime_to_pageTime: pageTime - vemsTime,
            pageTime_to_vemTime: pageTime_to_vemTime,
            vemTime_to_endTime: endTime - vemTime,
            startTime_to_pageTime: startTime_to_pageTime,
            startTime_to_vemsTime: vemsTime - startTime,
            startTime_to_vemTime: pageTime_to_vemTime + startTime_to_pageTime,
            totalRoundTrip: endTime - startTime
        };
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onInitialize(payload) {
        // send a log event for Splunk
        this._sendBeacon(_.assign(this._getCommonParams(), {
            evt: constants.logEvents.VEM_INIT,
            // the server pushed a state.requestLocation
            lrs: payload.locationRequestServer,
            // do we have geo coordinates
            loc: payload.currentLocation,
            // is there a videoplayer
            vp: payload.videoPlayer,
            // is there videoExperience data
            ve: payload.videoExperiences,
            // how many scheduled videos
            c_sv: payload.scheduledVideosCount,
            // how many playlist items
            c_p: payload.playlistCount,
            // how many alerts
            c_a: payload.alertsCount
        }));
        // send a rapid beacon for MAW
        this._sendRapidBeacon(constants.rapid.beaconTypes.PERFORMANCE, {
            vem_evt: constants.rapid.moduleNames.VEM_INIT,
            // the server pushed a state.requestLocation
            vem_lrs: payload.locationRequestServer,
            // do we have geo coordinates
            vem_loc: payload.currentLocation,
            // is there a videoplayer
            vem_vp: payload.videoPlayer,
            // is there videoExperience data
            vem_ve: payload.videoExperiences,
            // how many scheduled videos
            vem_c_sv: payload.scheduledVideosCount,
            // how many playlist items
            vem_c_p: payload.playlistCount,
            // how many alerts
            vem_c_a: payload.alertsCount
        }, null, constants.rapid.moduleNames.VEM_INIT);
    }
    _onSessionAttribution(payload) {
        this._sendBeacon(_.assign(this._getCommonParams(), {
            evt: constants.logEvents.SESSION_ATTRIBUTION
        }));
    }
    _onAlertStart(alert) {
        const params = this._getCommonParams();
        _.assign(params, {
            type: alert.type,
            act_name: alert.actionName,
            act: alert.actionType,
            dur: alert.duration,
            evt: constants.logEvents.ALERT_START
        });
        this._sendBeacon(params);
    }
    _onAlertAction(alert) {
        const params = this._getCommonParams();
        _.assign(params, {
            type: alert.type,
            act_name: alert.actionName,
            act: alert.actionType,
            dur: alert.duration,
            thm: alert.theme,
            id: alert.id,
            ack_id: alert.ackId,
            evt: constants.logEvents.ALERT_ACTION
        });
        this._sendBeacon(params);
    }
    _onScheduledVideoStart(video) {
        const params = this._getCommonParams();
        _.assign(params, {
            uuid: video.videoId,
            start_ts: video.startTime.getTime(),
            evt: constants.logEvents.SCHEDULED_VIDEO_START
        });
        this._sendBeacon(params);
    }
    _onScheduledVideoEnd(video) {
        const params = this._getCommonParams();
        _.assign(params, {
            uuid: video.videoId,
            start_ts: video.startTime.getTime(),
            evt: constants.logEvents.SCHEDULED_VIDEO_END
        });
        this._sendBeacon(params);
    }
    _onPlaylistStart(playlist) {
        const params = this._getCommonParams();
        _.assign(params, {
            playlist_len: playlist ? playlist.length : 0, //TODO should we add more params/info?
            evt: constants.logEvents.PLAYLIST_START
        });
        this._sendBeacon(params);
    }
    _onPlaylistComplete(playlist) {
        const params = this._getCommonParams();
        _.assign(params, {
            playlist_len: playlist ? playlist.length : 0, //TODO should we add more params/info?
            evt: constants.logEvents.PLAYLIST_END
        });
        this._sendBeacon(params);
    }
    _onWaitForLocationResolved(payload) {

        this._sendBeacon(_.assign(this._getCommonParams(), {
            evt: constants.logEvents.LOCATION_REQUEST_SERVER_RESOLVED,
            // do we have geo coordinates
            loc: payload.currentLocation ? 1 : 0,
            // what mediaItem was passed to the player
            uuid: payload.mediaItem,
            // how many scheduled videos
            c_sv: payload.scheduledVideosCount || 0,
            // how many live videos
            c_lv: payload.liveVideosCount || 0
        }));

        this._sendRapidBeacon(constants.rapid.beaconTypes.LINK_VIEW, {
            sec: constants.logEvents.LOCATION_REQUEST_SERVER_RESOLVED,
            _links: [
                {
                    slk: 'current-location',
                    t1: payload.currentLocation || 0
                },
                {
                    slk: 'scheduled-videos-count',
                    t1: payload.scheduledVideosCount || 0
                },
                {
                    slk: 'live-videos-count',
                    t1: payload.liveVideosCount || 0
                },
                {
                    slk: 'media-item',
                    t1: payload.mediaItem
                }
            ]
        });

    }
    _onFetchRemoteConfigRejected(payload) {
        const params = this._getCommonParams();
        _.assign(params, {
            status: payload?.status,
            error: payload?.statusText,
            url: payload?.url,
            evt: constants.logEvents.REMOTE_CONFIG
        });
        // this.debug.log('_onFetchRemoteConfigRejected', 'params', params);
        this._sendBeacon(params);
    }
    _getVideoSelectionRapidLinkData(scheduledVideos) {
        const links = [];
        for (const video of scheduledVideos) {
            const link = {
                slk: video.title
            }
            links.push(link);
        }
        const linkData = {
            sec: constants.rapid.moduleNames.NFL_GAME_PICKER,
            _links: links
        };
        return linkData;
    }
    _onFetchVideoExperiencesRejected(payload) {
        const params = this._getCommonParams();
        _.assign(params, {
            status: payload?.status,
            sv_ids: [],
            alerts: [],
            playlist_len: 0,
            error: payload?.statusText,
            url: payload?.url,
            evt: constants.logEvents.VE_API
        });
        // this.debug.log('_onFetchVideoExperiencesRejected', 'params', params);
        this._sendBeacon(params);
    }
    _onLocationUpdated(location) {
        const params = this._getCommonParams();
        _.assign(params, {
            on: !location ? 0 : 1,
            evt: constants.logEvents.LOCATION,
            lrs: this._stateManager.isActive(constants.states.REQUEST_LOCATION)
        });
        if (this._alertManager) {
            let alert = this._alertManager.getLocationAlert();
            if (_.has(alert, 'ipLocation')) {
                params.ipl = _.get(alert, 'ipLocation', false);
            }
        }
        this._sendBeacon(params);
    }    
    _onScheduledVideoPlaybackStart(payload) {
        const video = payload.video;
        const params = this._getCommonParams();
        _.assign(params, {
            uuid: video.videoId,
            start_ts: video.startTime.getTime(),
            already_started: payload.alreadyStarted,
            evt: constants.logEvents.SCHEDULED_VIDEO_PLAYBACK_START,
            lrs: Number(this._stateManager.isActive(constants.states.REQUEST_LOCATION))
        });
        this._sendBeacon(params);
    }
    _onPlaylistPlaybackStart(playlist) {
        const params = this._getCommonParams();
        _.assign(params, {
            playlist_len: playlist ? playlist.length : 0, //TODO should we add more params/info?
            evt: constants.logEvents.PLAYLIST_PLAYBACK_START
        });
        this._sendBeacon(params);
    }
    _onChyronShown(alert) {
        const commonParams = this._getCommonParams();
        const logEvents = constants.logEvents;
        const buttonActionName = alert.actionName;
        const buttonActionNames = Alert.ACTION_NAMES;
        const rapidConstants = constants.rapid;
        const buttonNames = rapidConstants.buttonNames;
        const rapidModuleNames = rapidConstants.moduleNames;
        const rapidBeaconTypes = rapidConstants.beaconTypes;
        const rapidBeaconType = rapidBeaconTypes.LINK_VIEW;
        
        _.assign(commonParams, {
            type: alert.type,
            ctx: buttonActionName,
            act: alert.actionType,
            dur: alert.duration,
            ipl: alert.ipLocation,
            evt: logEvents.CHYRON_SHOWN,
            lrs: Number(this._stateManager.isActive(constants.states.REQUEST_LOCATION))
        });
        this._sendBeacon(commonParams);

        if (buttonActionName === buttonActionNames.RequestLocation) {
            
            const linkData = {
                sec: rapidModuleNames.LOCATION_CHYRON,
                _links: [
                    {
                        slk: buttonNames.ALLOW_LOCATION
                    }
                ]
            };
            this._sendRapidBeacon(rapidBeaconType, linkData);

        } else if ([buttonActionNames.RequireInstantApp, buttonActionNames.RequireSportsApp].indexOf(buttonActionName) !== -1) {
            
            const rapidLinks = [
                {
                    slk: buttonNames.WATCH_NOW
                }
            ];
            if (buttonActionName === buttonActionNames.RequireSportsApp) {
                rapidLinks.push({
                    slk: buttonNames.UPDATE_OS
                });
            }
            const linkData = {
                sec: rapidModuleNames.WATCH_NOW_CHYRON,
                _links: rapidLinks
            };
            this._sendRapidBeacon(rapidBeaconType, linkData);

        }
    }
    _onChyronHidden(alert) {
        const params = this._getCommonParams();
        _.assign(params, {
            type: alert.type,
            act_name: alert.actionName,
            act: alert.actionType,
            dur: alert.duration,
            ipl: alert.ipLocation,
            evt: constants.logEvents.CHYRON_HIDDEN,
            lrs: Number(this._stateManager.isActive(constants.states.REQUEST_LOCATION))
        });
        this._sendBeacon(params);
    }
    _onChyronTapped(payload) {
        const alert = payload.alert;
        const commonParams = this._getCommonParams();
        const logEvents = constants.logEvents;
        const buttonActionName = alert.actionName;
        const buttonActionNames = Alert.ACTION_NAMES;
        const rapidConstants = constants.rapid;
        const rapidButtonNames = rapidConstants.buttonNames;
        const rapidBeaconTypes = rapidConstants.beaconTypes;
        const rapidBeaconType = rapidBeaconTypes.CLICK;
        const rapidModuleNames = rapidConstants.moduleNames;

        if (buttonActionName === buttonActionNames.RequestLocation) {
            const buttonType = rapidButtonNames.ALLOW_LOCATION;

            _.assign(commonParams, {
                evt: logEvents.CHYRON_LOCATION_TAPPED,
                lrs: Number(this._stateManager.isActive(constants.states.REQUEST_LOCATION)),
                ipl: _.get(alert, 'ipLocation', false),
                btn: buttonType,
                ctx: buttonActionName
            });
            this._sendBeacon(commonParams);

            const linkParams = {
                sec: rapidModuleNames.LOCATION_CHYRON,
                slk: buttonType,
                pos: 1
            };
            const clickParams = {
                ll1: buttonActionName
            };
            const options = {
                itc: 1
            };
            this._sendRapidBeacon(rapidBeaconType, linkParams, options, null, clickParams);

        } else if ([buttonActionNames.RequireInstantApp, buttonActionNames.RequireSportsApp].indexOf(buttonActionName) !== -1) {
            const buttonType = payload.buttonContext || rapidButtonNames.WATCH_NOW;
            const buttonPosition = payload.buttonPosition || 1;

            _.assign(commonParams, {
                evt: logEvents.CHYRON_WATCH_NOW_TAPPED,
                ctx: buttonActionName,
                btn: buttonType
            });
            this._sendBeacon(commonParams);

            const linkParams = {
                sec: rapidModuleNames.WATCH_NOW_CHYRON,
                slk: buttonType,
                pos: buttonPosition
            };
            const clickParams = {
                ll1: buttonActionName
            };
            const options = {
                itc: 1
            };
            this._sendRapidBeacon(rapidBeaconType, linkParams, options, null, clickParams);

        }
    }
    _onChyronLocationDeniedShown(message) {
        const params = this._getCommonParams();
        _.assign(params, {
            msg: message,
            evt: constants.logEvents.CHYRON_LOCATION_DENIED_SHOWN,
            lrs: Number(this._stateManager.isActive(constants.states.REQUEST_LOCATION))
        });
        if (this._alertManager) {
            let alert = this._alertManager.getLocationAlert();
            if (_.has(alert, 'ipLocation')) {
                params.ipl = _.get(alert, 'ipLocation', false);
            }
        }
        this._sendBeacon(params);

        // send rapid link view beacon
        const linkData = {
            sec: constants.rapid.moduleNames.LOCATION_CHYRON_DENIED,
            _links: [
                {
                    slk: constants.rapid.buttonNames.DENIED_LOCATION
                }
            ]
        };
        this._sendRapidBeacon(constants.rapid.beaconTypes.LINK_VIEW, linkData);
    }
    _onCountdownShown(alert) {
        this.debug.log('_onCountdownShown');
        const params = this._getCommonParams();
        _.assign(params, {
            // type: _.get(alert, 'type', ''),
            // act_name: _.get(alert, 'actionName', ''),
            // act: _.get(alert, 'actionType', ''),
            // dur: _.get(alert, 'duration', 0),
            // ipl: _.get(alert, 'ipLocation', false),
            evt: constants.logEvents.COUNTDOWN_SHOWN
        });
        this._sendBeacon(params);
    }
    _onCountdownHidden(alert) {
        this.debug.log('_onCountdownHidden');
        const params = this._getCommonParams();
        _.assign(params, {
            // type: _.get(alert, 'type', ''),
            // act_name: _.get(alert, 'actionName', ''),
            // act: _.get(alert, 'actionType', ''),
            // dur: _.get(alert, 'duration', 0),
            // ipl: _.get(alert, 'ipLocation', false),
            evt: constants.logEvents.COUNTDOWN_HIDDEN
        });
        this._sendBeacon(params);
    }
    _onVideoSelectionShown(payload) {
        const params = this._getCommonParams();
        _.assign(params, {
            videos_len: payload.videos ? payload.videos.length : 0,
            evt: constants.logEvents['VIDEO_SELECT_' + (payload.userActivated ? 'USER_' : '') + 'SHOWN']
        });
        this._sendBeacon(params);

        // send rapid link view beacon
        const optParams = {
            pp: {
                ll1: payload.userActivated ? 'user-select' : 'auto-select'
            }
        };
        this._sendRapidBeacon(constants.rapid.beaconTypes.LINK_VIEW, this._getVideoSelectionRapidLinkData(payload.videos), optParams);
    }
    _onVideoSelectionHidden(payload) {
        const params = this._getCommonParams();
        _.assign(params, {
            videos_len: payload.videos ? payload.videos.length : 0,
            evt: constants.logEvents['VIDEO_SELECT_' + (payload.userActivated ? 'USER_' : '') + 'HIDDEN']
        });
        this._sendBeacon(params);

        // if user closed view then send rapid click beacon
        if (payload.userActivated) {
            const rapidParams = {
                sec: constants.rapid.moduleNames.NFL_GAME_PICKER,
                slk: constants.rapid.buttonNames.CLOSE,
                pos: 1
            };
            const optParams = {
                itc: 1,
                elm: constants.rapid.buttonNames.CLOSE
            };

            this._sendRapidBeacon(constants.rapid.beaconTypes.CLICK, rapidParams, optParams);
        }
    }
    _onVideoSelectionTapped(payload) {
        if (!_.isEmpty(payload)) {
            const video = payload.video;
            const params = this._getCommonParams();
            _.assign(params, {
                uuid: video && video.videoId,
                evt: constants.logEvents.VIDEO_SELECT_TAPPED
            });
            this._sendBeacon(params);

            // send rapid click beacon
            const slk = video.title;
            const pos = payload.index ? (payload.index + 1) : 1;
            const rapidParams = {
                sec: constants.rapid.moduleNames.NFL_GAME_PICKER,
                slk: slk,
                pos: pos
            };
            const optParams = {
                itc: 1,
                elm: 'btn',
                elmt: 'video-start',
                g: video && video.videoId
            };
            this._sendRapidBeacon(constants.rapid.beaconTypes.CLICK, rapidParams, optParams);
        }
    }
    _onCustomLog(params) {
        const cParams = this._getCommonParams();
        _.assign(cParams, {
            context: params.context,
            custom_log: params.msg,
            evt: constants.logEvents.CUSTOM_LOG
        });
        this._sendBeacon(cParams);
    }
    _onRemoteLog(params) {
        const cParams = this._getCommonParams();
        _.assign(cParams, {
            context: params.context,
            custom_log: params.msg,
            evt: constants.logEvents.REMOTE_LOG
        });
        this._sendBeacon(cParams);
    }
    _onPlaybackWillStart(video) {
        const cParams = this._getCommonParams();
        const id = video.videoId || video.id;
        _.assign(cParams, {
            uuid: id,
            evt: constants.logEvents.PLAYBACK_WILL_START
        });
        this._sendBeacon(cParams);
    }
    _onPlaybackStart(video) {
        const cParams = this._getCommonParams();
        const id = video.videoId || video.id;
        _.assign(cParams, {
            uuid: id,
            evt: constants.logEvents.PLAYBACK_START
        });
        this._sendBeacon(cParams);
    }
    _onPlaybackError(params) {
        const cParams = this._getCommonParams();
        const error = params.error;
        const video = params.video;
        const id = video.videoId || video.id;
        _.assign(cParams, {
            uuid: id,
            error: error,
            evt: constants.logEvents.PLAYBACK_ERROR
        });
        this._sendBeacon(cParams);
    }
    _onApi_PlayStream(params) {
        const cParams = this._getCommonParams();
        _.assign(cParams, {
            id: params?.id,
            id_type: params?.idType,
            video: params?.video,
            player: params?.player,
            guid: params?.guid,
            uuid: params?.uuid,
            error: params?.error,
            evt: constants.logEvents.API_PLAY_STREAM
        });
        this._sendBeacon(cParams);
    }
    /***
     * @method _onPlayerRespawn
     */
    _onPlayerRespawn(params) {
        const cParams = this._getCommonParams();
        Object.assign(cParams, {
            evt: constants.logEvents.PLAYER_RESPAWN,
            rpa: params?.respawnPlayerAttempts,
            rpat: params?.respawnPlayerAttemptsThreshold,
            rpl: params?.respawnPlayerLevel,
            pid: params?.playerId,
            pver: params?.playerVersion,
            pet: params?.playerErrorType,
            pec: params?.playerErrorCode,
            ipsv: Number(params?.isPlayingScheduledVideo),
            lse: Number(params?.liveStreamEnded),
            ra: Number(params?.respawnAllowed),
        });
        this._sendBeacon(cParams);
    }

}

export {
    MetricsManager
};
