/**
 * @namespace External
 */
/**
 * @namespace window
 */
export default {
    /**
     * Emitted after the bundle has been downloaded and evaluated
     * @event onVEModuleLibraryLoaded
     * @memberof window
     * @type {null}
     */
    onVEModuleLibraryLoaded: "onVEModuleLibraryLoaded",
    /**
     * Called EVERY TIME VEModule receives data after initialization
     * @event onDataLoaded
     * @memberof External
     * @type {null}
     */
    onDataLoaded: "onDataLoaded",
    /**
     * Called only if the VEModule receives error response data after initialization
     * @event onDataError
     * @memberof External
     * @type {null}
     */
    onDataError: "onDataError",
    /**
     * Fired when an alert starts
     * @event onAlertStart
     * @memberof External
     * @type {null}
     * @property {Alert} - instance containing information about the alert.
     */
    onAlertStart: "onAlertStart",
    /**
     * Fired when an action is performed on the alert(User taps on it)
     * @event onAlertAction
     * @memberof External
     * @type {null}
     * @property Alert instance containing information about the alert.
     */
    onAlertAction: "onAlertAction",
    /**
     * Fired when video is selected on video selection UI (User taps on it)
     * @event onVideoSelectAction
     * @memberof External
     * @type {null}
     * @property ScheduledVideo instance containing metadata about the event video
     */
    onVideoSelectAction: "onVideoSelectAction",
    /**
     * Fired when a scheduled event start time is passed
     * @event onScheduledVideoStart
     * @memberof External
     * @type {null}
     * @property ScheduledVideo instance containing metadata about the event video
     */
    onScheduledVideoStart: "onScheduledVideoStart",
    /**
     * Called when a scheduled event starts playing in the player
     * @event onScheduledVideoPlaybackStart
     * @memberof External
     * @type {null}
     * @property ScheduledVideo instance containing metadata about the event video
     */
    onScheduledVideoPlaybackStart: "onScheduledVideoPlaybackStart",
    /**
     * Fired when a scheduled event ends
     * @event onScheduledVideoPlaybackComplete
     * @memberof External
     * @type {null}
     * @property ScheduledVideo instance containing metadata about the event video
     */
    onScheduledVideoPlaybackComplete: "onScheduledVideoPlaybackComplete",
    /**
     * Called when a scheduled event attempts to play in the player, but fails
     * @event onScheduledVideoPlaybackFailed
     * @memberof External
     * @type {Object}
     * @param ScheduledVideo instance containing metadata about the event video
     */
    onScheduledVideoPlaybackFailed: "onScheduledVideoPlaybackFailed",
    /**
     * Fired when a scheduled event ends
     * @event onScheduledVideoComplete
     * @memberof External
     * @type {null}
     * @property ScheduledVideo instance containing metadata about the event video
     */
    onScheduledVideoComplete: "onScheduledVideoComplete",
    /**
     * Fired when a playlist is set on the player
     * @event onPlaylistStart
     * @memberof External
     * @type {null}
     * @property Array of VideoMetadata instances containing information about the videos in the playlist
     */
    onPlaylistStart: "onPlaylistStart",
    /**
     * Fired when a playlist is finished playing
     * @event onPlaylistComplete
     * @memberof External
     * @type {null}
     * @property Array of VideoMetadata instances containing information about the videos in the playlist
     */
    onPlaylistComplete: "onPlaylistComplete",
    /**
     * Fired when a playlist section starts
     * @event onSectionStart
     * @memberof External
     * @type {null}
     * @property {SectionItem}
     */
    onSectionStart: "onSectionStart",
    /**
     * Fired when a playlist section completes
     * @event onSectionComplete
     * @memberof External
     * @type {null}
     * @property {SectionItem}
     */
    onSectionComplete: "onSectionComplete",
    /**
     * Fired when a playlist video is going to start
     * @event onVideoWillStart
     * @memberof External
     * @type {null}
     * @property {MediaItem}
     */
    onVideoWillStart: "onVideoWillStart",
    /**
     * Fired when a playlist video is going to complete
     * @event onVideoWillComplete
     * @memberof External
     * @type {null}
     * @property {MediaItem}
     */
    onVideoWillComplete: "onVideoWillComplete",
    /**
     * Fired when a playlist video starts
     * @event onVideoStart
     * @memberof External
     * @type {null}
     * @property {MediaItem}
     */
    onVideoStart: "onVideoStart",
    /**
     * Fired when a playlist video completes
     * @event onVideoComplete
     * @memberof External
     * @type {null}
     * @property {MediaItem}
     */
    onVideoComplete: "onVideoComplete",
    /**
     * Fired each time a playlist item is loaded
     * @event onMediaItemLoaded
     * @memberof External
     * @type {null}
     * @property none
     */
    onMediaItemLoaded: "onMediaItemLoaded",
    /**
     * Fired each time a playlist item is moved into the current position and is ready to use
     * @event onMediaItemCurrent
     * @memberof External
     * @type {null}
     * @property none
     */
    onMediaItemCurrent: "onMediaItemCurrent",
    /**
     * Fired when a playlist changes and new sections are available
     * @event onSectionListUpdate
     * @memberof External
     * @type {null}
     * @property {SectionList}
     */
    onSectionListUpdate: "onSectionListUpdate",
    /**
     * Fired when a playlist changes and new entities are available
     * @event onEntityListUpdate
     * @memberof External
     * @type {null}
     * @property {Entities}
     */
    onEntityListUpdate: "onEntityListUpdate",
    /**
     * Fired when a playlist changes to a new mediaItem
     * @event onEntityUpdate
     * @memberof External
     * @type {null}
     * @property {Entities}
     */
    onEntityUpdate: "onEntityUpdate",
    /**
     * Fired when a mediaItem has been watched (>N% where N is configuratble, default 90)
     * @event onVideoWatched
     * @memberof External
     * @type {null}
     * @property {MediaITem}
     */
    onVideoWatched: "onVideoWatched",
    /**
     * Fired when the history of watched mediaItems is updated
     * @event onHistoryUpdate
     * @memberof External
     * @type {null}
     * @property {null}
     */
    onHistoryUpdate: "onHistoryUpdate",
    /**
     * Fired when items in history expire
     * @event onHistoryPurge
     * @memberof External
     * @type {null}
     * @property {null}
     */
    onHistoryPurge: "onHistoryPurge",
    /**
     * Fired when video segment for current playing video was updated through
     * one of markers or timed metadata received.
     * @event onVideoSegmentChanged
     * @memberof External
     * @type {null}
     * @property {null}
     */
    onVideoSegmentChanged: "onVideoSegmentChanged",
    /**
     * Fired when a schedule fetch completes. Used by VEMI
     * @event onVideoExperiencesUpdated
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onVideoExperiencesUpdated: "onVideoExperiencesUpdated",
    /**
     * Fired when a remote config fetch completes. Used by VEMI
     * @event onRemoteConfigUpdated
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onRemoteConfigUpdated: "onRemoteConfigUpdated",
    /**
     * Fired when clients location is updated
     * @event onLocationUpdated
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onLocationUpdated: "onLocationUpdated",
    /**
     * Fired when user rejects the location request
     * @event onLocationDenied
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onLocationDenied: "onLocationDenied",
    /**
     * Fired when vem receives markers from the player
     * @event onMarkersReceived
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onMarkersReceived: "onMarkersReceived",
    /**
     * Fired when vem receives timed metadata from the player
     * @event onTimedMetadataReceived
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onTimedMetadataReceived: "onTimedMetadataReceived",
    /**
     * Fired anytime a remote config or schedule call is made. Used by VEMI
     * @event onFetch
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onFetch: "onFetch",
    /**
     * Fired anytime the EventBus fires a public facing event. Used by VEMI
     * @event onEventPublished
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onEventPublished: "onEventPublished",
    /**
     * Fired when a scheduled video is played but no other scheduled video was playing before it
     * @event onScheduleStarted
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onScheduleStarted: "onScheduleStarted",
    /**
     * Fired when there is a scheduled video playing, it completes, and there are no more scheduled videos to play
     * @event onScheduleCompleted
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onScheduleCompleted: "onScheduleCompleted",
    /**
     * Fired any time the StateManager sets a new state
     * @event onStateChanged
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onStateChanged: "onStateChanged",
    /**
     * If the VEM gets a videoExperiences response that does not contain any scheduled_videos but does contain an alert then the VEM will enter into a WAITING state and surface the LocationChyron. If the user clicks the chyron and allows location then the VEM makes another schedule call using geo coordinates. The result of this may contain scheduled_videos. If it does then we fire this event and include LiveVideos from the DataManager along with the current state of VEM and a player ready location object.
     * @event onWaitForLocationResolved
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onWaitForLocationResolved: "onWaitForLocationResolved",
    /**
     * This will fire in the case where the users location is required but they don't ALLOW.
     * @event onWaitForLocationRejected
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onWaitForLocationRejected: "onWaitForLocationRejected",
    /**
     * This will fire when the player emits an MEDIA_ITEM_CURRENT event
     * @event onCurrentEntityChanged
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onCurrentEntityChanged: "onCurrentEntityChanged",
    /**
     * This will fire when the VEM needs a missing player. This request must
     * by responded to by the caller of the program or the requested
     * playback will fail. To respond the caller should create a player, wire
     * up its onPlayerReady event to call vem.setPlayer with the player
     * instance.
     * @event onRequestPlayerInstance
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onRequestPlayerInstance: "onRequestPlayerInstance",
    /**
     * This fires when the VEM is done loading, initializing and has completed
     * its remote configuration call.
     * @event onInitialized
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onInitialized: "onInitialized",
    /**
     * This fires when a stream playback was attempted but the start time had
     * not been reached yet so we threw up a countdown timer in the UI and then
     * the countdown was reached.
     * @event onCountdownComplete
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onCountdownComplete: "onCountdownComplete",
    /**
     * This fires when the VideoPlayer emits a PLAYER_RENDERED event.
     * @event onVideoplayerRendered
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onVideoplayerRendered: "onVideoplayerRendered",
    /**
     * This fires when the VideoPlayer emits a PLAYER_READY event.
     * @event onVideoplayerReady
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onVideoplayerReady: "onVideoplayerReady",
    /**
     * This fires when the VideoPlayer emits a PLAYER_ERROR event.
     * @event onVideoplayerError
     * @memberof External
     * @type {null}
     * @property {null}
     */    
    onVideoplayerError: "onVideoplayerError",
    /**
     * Fired when vem receives timed metadata from the player
     * @event onDateRangeStarted
     * @memberof External
     * @type {null}
     * @property {null}
     */    
     onDateRangeStarted: "onDateRangeStarted",
    /**
     * Fired when vem receives timed metadata from the player
     * @event onDateRangeEnded
     * @memberof External
     * @type {null}
     * @property {null}
     */    
     onDateRangeEnded: "onDateRangeEnded",
};
