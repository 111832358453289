import _ from 'lodash';

const utils = {
    /**
     * @param {String} dateString 
     */
    convertStrToDate: function (dateString) {
        // TODO: If there is a standard date format across clients, server
        // should return that otherwise, for now, the following should work
        let result = null;
        if (dateString) {
            if (dateString.indexOf && (dateString.indexOf('+0000') !== -1)) {
                dateString = dateString.replace('+0000', 'Z');
            }
            result = new Date(dateString);
        }
        return result;
    },
    /**
     * 
     * @param {*} template 
     * @param {*} opts 
     * @param {*} partials 
     */
    render: function (template, opts, partials) {
        const container = document.createElement('div');
        if (template && (typeof template.render === 'function')) {
            container.innerHTML = template.render(opts, partials);
        }
        return container.children[0];
    },

    isSafariIOS: function() {
        let result = false;
        if (typeof navigator !== 'undefined') {
            let ua = navigator.userAgent;
            if (ua) {
                ua = ua.toLowerCase();
                result = /(ipad|iphone|ipod)/.test(ua) && /(safari)/.test(ua);
            }
        }
        return result;
    },

    pad: function(pad, str, padLeft) {
        if (typeof str === 'undefined') {
            return pad;
        }
        if (padLeft) {
            return (pad + str).slice(-pad.length);
        } else {
            return (str + pad).substring(0, pad.length);
        }
    },

    isUUID: function (id) {
        // uuid=64543d2c-bc2a-37f6-983f-d3fb93256302
        // gameId=nfl.g.20191013014
        const tuples = id.split('-');
        return (_.size(tuples) === 5) && (_.size(tuples[0]) === 8) && (_.size(tuples[1]) === 4) && (_.size(tuples[2]) === 4) && (_.size(tuples[3]) === 4) && (_.size(tuples[4]) === 12);
    },

   /**
     * generates a random string that starts with an alpha, is 8 characters
     * long and uppercase
     * @returns {String}
     */
    generateSessionId: function() {
        const charset1 = 'abcdefghijklmnopqrstuvwxyz';
        const charset2 = charset1 + '1234567890';
        return (_.times(1, () => {
            return charset1[Math.floor(Math.random() * charset1.split('').length)]
        }).join('') + _.times(7, () => {
            return charset2[Math.floor(Math.random() * charset2.split('').length)]
        }).join('')).toUpperCase();
    },

    getVideoType: function (video) {
        // check for nba
        const gameId = _.get(video, 'gameId', null);
        // default for now, since this is new
        // TODO: we need an explicit way to determine stream type
        let type = 'nba';
        if (gameId) {
            type = _.first(_.split(gameId, '.'));
        }
        return type;
    }

};

export {
    utils
};
