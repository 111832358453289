import _ from 'lodash';

import { utils } from '../lib/utils';

/*
 * @name VideoMetadata
 * @param videoDescription {String} Description for the video
 * @param startTime {Date} Start time for the video if it is scheduled
 * @param thumbnailUrl {String} Thumbnail url for the video
 * @param title {String} Title for the video
 * @param type {String} video type
 * @param videoId {String} uuid for the video
 * @param gameId {String} gameId for the video(if the video is for a game)
 */
class VideoMetadata {
    /**
     * @class VideoMetadata
     * @classdesc
     * @param {Object} json 
     */
    constructor(json) {
        this.videoDescription = json.description || '';
        this.startTime = utils.convertStrToDate(json.date) || null;
        this.isPrioritized = json.is_prioritized || false;
        this.thumbnailUrl = json.thumbnail_url || '';
        this.title = json.title || '';
        this.segmentTitles = json.segment_titles || {};
        this.markerType = json.marker_type || null;
        this.type = json.video_type || '';
        this.videoId = json.uuid || '';
        this.gameId = json.game_id || '';
        this.conditions = json.conditions || [];
        this.eosMarker = json.eos_marker || '';
        this.betStream = json.bet_stream || false;
        this.isBumper = json?.playlist_config?.is_bumper || false;
        this.countdownUntil = json.count_down_until || null;
        this.backgroundPlay = json.background_play || false;
        if (json.entities) {
            // normalize object to array to support both formats
            let entities = [];
            if (_.isArray(json.entities)) {
                entities = json.entities;
            } else {
                _.each(json.entities, (entity, key, list) => {
                    const item = {};
                    item[key] = entity
                    entities.push(item);
                });
            }
            _.each(entities, (entity, key) => {
                if (_.has(entity, 'home_team')) {
                    this.homeTeam = entity.home_team;
                }
                if (_.has(entity, 'away_team')) {
                    this.awayTeam = entity.away_team;
                }
            });
        }
        if (json.lang) {
            this.lang = json.lang.toLowerCase() || '';
        }
        if (json.header) {
            this.header = json.header;
        }
    }
}

export {
    VideoMetadata
};
