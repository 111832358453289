import { VideoMetadata } from './video-metadata';
/*
 * @global ScheduledVideo(instance)
 * @param {String} videoDescription - Description for the video
 * @param {Date} startTime - Start time for the video if it is scheduled
 * @param {String} thumbnailUrl - Thumbnail url for the video
 * @param {String} title - Title for the video
 * @param {String} type - video type
 * @param {String} videoId - uuid for the video
 * @param {String} gameId - gameId for the video(if the video is for a game)
 * @param {Boolean} forcePlay - If forcePlay == true, we will start playback in
 * the player. If forcePlay == false, the event handler should determine what
 * to do. In either case, the onScheduledVideoStart event will be fired.
 */

class ScheduledVideo extends VideoMetadata {
    /**
     * @class ScheduledVideo
     * @classdesc 
     * @extends VideoMetadata
     * @param {Object} json
     */
    constructor(json) {
        super(json);
        this.forcePlay = json.force_play;
    }
}
/**
 * @static CONDITIONS
 */
ScheduledVideo.CONDITIONS = {
    REQUIRE_LAT_LONG: 'require_lat_long',
    CELLULAR_ONLY: 'cellular_only',
    REQUIRE_INSTANT_APP: 'require_instant_app',
    REQUIRE_SPORTS_APP: 'require_sports_app',
};

export {
    ScheduledVideo
};
