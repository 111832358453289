import _ from 'lodash';

import constants from '../lib/constants';
import vemEvents from '../lib/events';
import { BaseManager } from '../lib/base';

class HistoryManager extends BaseManager {
    /**
     * @class HistoryManager
     * @classdesc The idea here is to listen to a playback request and keep track of the
     * current items uuid(videoId), total playback duration and current
     * progress until another playback request is made. During this request
     * we still have access to the previous items information(still stored
     * under 'this.currentItem') as well as the newly requested items
     * information. 
     * @description keeps track of what a user watched
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'HistoryManager');
        
        this._dataManager = _.get(args, 'dataManager', null);
        this._playerManager = _.get(args, 'playerManager', null);
        
        // percent threshold after which the item is considered 'watched'
        // default is 90%
        this.watchedThreshold = _.get(args, 'threshold', 90);
        // the time a record can live in the history before it's removed
        // default is 3 days in milliseconds
        this.watchedExpiration = _.get(args, 'expiration', (60 * 60 * 24 * 3)) * 1000;
        
        this.initHistory();
        this.initCurrentItem();
        this.initPlaylist();
        
        this._eventBus.subscribe(constants.events.PLAYBACK_START, this._onPlaybackStart.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_COMPLETE, this._onPlaybackComplete.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_PROGRESS, this._onPlaybackProgress.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_REQUEST, this._onPlaybackRequest.bind(this));
        
        this.debug.log('initialized');
    }
    initHistory() {
        this.history = this.getHistoryFromLocalStorage();
        this.purgeExpiredItems();
        this.saveHistoryToLocalStorage();
    }
    initCurrentItem() {
        this.setCurrentItem({
            // uuid is set to empty string which we use to determine the initial playback
            // see '!_.isEmpty(this.currentItem.uuid)' in _onPlaybackRequest
            uuid: '',
            duration: 0,
            progress: 0
        });
    }
    initPlaylist() {
        // this will skip over watched items in the playlist
        // until it finds an item that hasn't been watched
        var player = this._playerManager.getPlayer();
        if (player) {
            var nextUnwatchedItemId = this.getNextUnwatchedItem(player.playlist);
            if (nextUnwatchedItemId !== this.getCurrentItemId()) {
                player.playlist.setPositionById(nextUnwatchedItemId);
            }
            this.setCurrentItem({
                uuid: nextUnwatchedItemId,
                duration: 0,
                progress: 0
            });
        }
    }

    getCurrentItemId() {
        return this._playerManager.getCurrentPlayingId();
    }
    getCurrentItemDuration() {
        return this._playerManager.getPlayer().controls.getDuration();
    }
    getItemDurationById(uuid) {
        return this._playerManager.getPlayer().playlist.getItemById(uuid).duration;
    }
    getCurrentItemTime() {
        // we are going to pass around whole numbers
        // this is because we just have to be 'over' in our calculations
        // this is because the duration reported by the player is floored
        // which means we can't be accurate so we will error on being 'over'
        return Number(this._playerManager.getPlayer().controls.getCurrentTime().toFixed());
    }
    getTimeRemaining(duration, time) {
        return duration - time;
    }
    getPercentComplete(duration, time) {
        //console.log(this.name, 'getPercentComplete', 'duration', duration, 'time', time, '%', Number(((time / duration) * 100).toFixed()));
        // time can be greater than the duration
        // duration seems to be floored where time is accurate to 2 decimals
        // to account for this we use toFixed to round up/down
        // if we are over(which is likely most of the time) then we send 100%
        return Number((time > duration) ? 100 : ((time / duration) * 100).toFixed());
    }
    getNextUnwatchedItem(playlist) {
        var nextUnwatchedItemId = null;
        _.each(playlist.getItems(), (videoId) => {
            if (!this.isItemWatched(videoId)) {
                nextUnwatchedItemId = videoId;
                return false;
            }
        });
        return nextUnwatchedItemId;
    }
    getHistoryItemByUuid(uuid) {
        return _.find(this.history, ['uuid', uuid]);
    }
    getHistory() {
        return this.history;
    }
    setItemWatched(updateItem) {
        const item = updateItem || this.currentItem;
        let update = false;
        if (updateItem) {
            update = true;
            const itemIndex = _.findIndex(this.history, ['uuid', item.uuid]);
            this.history[itemIndex] = _.assign({}, this.history[itemIndex], item, {
                timestamp: Date.now()
            });
        } else {
            if (!this.getHistoryItemByUuid(item.uuid)) {
                update = true;
                this.history.push(_.assign({}, item, {
                    timestamp: Date.now()
                }));
            }
        }
        if (update) {
            const percent = this.getPercentComplete(item.duration, item.progress);
            //this.debug.log('setItemWatched', item.uuid + ' (' + percent + '%)');
            this.purgeExpiredItems();
            this.saveHistoryToLocalStorage();
            this._publishVideoWatched(item);
            this._publishHistoryUpdate();
        }
    }
    setCurrentItem(state) {
        this.currentItem = _.assign({}, _.get(this, 'currentItem', {}), state);
    }
    purgeExpiredItems() {
        let expiredList = [];
        this.history = _.filter(this.history, (item) => {
            const expired = (item.timestamp + this.watchedExpiration) < Date.now();
            if (expired) {
                expiredList.push(item);
            }
            return !expired;
        });
        if (!_.isEmpty(expiredList)) {
            //this.debug.log('purgeExpiredItems', JSON.stringify(_.map(expiredList, 'uuid')));
            this._publishHistoryPurge(expiredList);
        }
    }
    skipItem(videoId) {
        const player = this._playerManager.getPlayer();
        const nextItem = this._playerManager.getNextMediaItemByCurrentMediaItemId(videoId);
        if (!_.isEmpty(nextItem)) {
            player.playlist.setPositionById(nextItem.videoId);
            player.controls.play();
        }
    }
    isItemWatched(videoId) {
        return _.find(this.history, ['uuid', videoId]) ? true : false;
    }
    _publishVideoWatched(item) {
        this._eventBus.publish(vemEvents.onVideoWatched, item);
    }
    _publishHistoryUpdate() {
        if (!_.isEmpty(this.history)) {
            this._eventBus.publish(vemEvents.onHistoryUpdate);
        }
    }
    _publishHistoryPurge(items) {
        this._eventBus.publish(vemEvents.onHistoryPurge, items);
    }    

    /**************************************************************************
        Local Storage
    **************************************************************************/
    getHistoryFromLocalStorage() {
        return _.get(this._getStateFromLocalStorage(), 'history', []);
    }
    saveHistoryToLocalStorage() {
        this._saveStateToLocalStorage({
            history: this.history
        });
    }
    _getStateFromLocalStorage() {
        return JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_KEY_MAIN)) || {};
    }
    _saveStateToLocalStorage(state) {
        localStorage.setItem(constants.LOCAL_STORAGE_KEY_MAIN, JSON.stringify(_.extend((JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_KEY_MAIN)) || {}), state)));
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onPlaybackStart(mediaItem) {
        //this.debug.log('_onPlaybackStart', mediaItem.videoId);
        // record the videoId for this playback request as the now 'current' item
        // the duration and progress will be updated by _onPlaybackProgress
        this.setCurrentItem({
            uuid: mediaItem.videoId,
            duration: 0,
            progress: 0
        });
        //console.log(this.name, subject, 'currentItem', this.currentItem);
    }
    _onPlaybackRequest(videoId) {
        //this.debug.log('_onPlaybackRequest', videoId);
        if (this.getHistoryItemByUuid(videoId)) {
            //this.debug.log('_onPlaybackRequest', videoId, 'already watched this item so skipping it');
            this.skipItem(videoId);
        } else {
            // if there is a current uuid set
            //   note: if there is no uuid set yet then this is an initial
            //         playback(no items have previously been played)
            // and it does not match the videoId for the playback request
            // and its percent completion is less than the threshold
            //   note: if the threshold were exceeded then the item would have
            //         already been marked by _onPlaybackProgress
            // then mark it as watched
            if ((!_.isEmpty(this.currentItem.uuid) && (this.currentItem.uuid !== videoId)) && (this.getPercentComplete(this.currentItem.duration, this.currentItem.progress) < this.watchedThreshold)) {
                // this catches items that were skipped at less progress than the threshold
                //this.debug.log('_onPlaybackRequest', this.currentItem.uuid, 'previous item was skipped over so setting it to watched');
                this.setItemWatched(this.currentItem);
            }
        }
    }
    // to save cycles we will not continue to record progress after the item
    // has been marked, until, we receive an _onPlaybackComplete
    _onPlaybackProgress(videoId) {
        // update the duration and progress for the current item
        this.setCurrentItem({
            uuid: videoId,
            duration: this.getCurrentItemDuration(),
            progress: this.getCurrentItemTime()
        });
        // if we exceed the threshold the mark the item as watched
        // this threshold is configurable via a constructor option
        if (this.getPercentComplete(this.currentItem.duration, this.currentItem.progress) >= this.watchedThreshold) {
            this.setItemWatched();
        }
    }
    _onPlaybackComplete(mediaItem) {
        // update the duration and progress for the current item
        const item = this.getHistoryItemByUuid(mediaItem.videoId);
        this.setItemWatched({
            uuid: item.uuid,
            duration: item.duration,
            progress: item.duration
        });
        //this.debug.log('_onPlaybackComplete', mediaItem.videoId);
    }
    
}

export {
    HistoryManager
};
