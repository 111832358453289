/*
TODO: rewrite console extensions using lambdas
*/
import _ from 'lodash';

import packageInfo from '../../package.json';

import constants from './constants';
import vemEvents from './events';
import error from './errors';

const vemVersion = packageInfo.version;

let globalActive = false;
let globalLevel = [];

const vemd = {
    name: 'VEMD',
    version: vemVersion,
    modules: {},
    instances: 0,
    Debugger: null,
    meta: {
        error: error,
        constants: constants,
        events: vemEvents
    },
    get active () {
        return globalActive;
    },
    set active (active) {
        globalActive = active;
        _.each(this.modules, (module) => {
            module.active = globalActive;
        });
    },
    get level () {
        return globalLevel;
    },
    set level (level) {
        globalLevel = level;
        _.each(this.modules, (module) => {
            module.level = globalLevel;
        });
    }
};

class DebugFactory {
    constructor(factoryConfig) {
        let _active = _.get(factoryConfig, 'active', false);
        let _level = _.has(factoryConfig, 'level') ? (_.isString(_.get(factoryConfig, 'level')) ? [_.get(factoryConfig, 'level')] : _.get(factoryConfig, 'level')) : [];
        
        const _factoryInstance = this;
        const _env = process.env.NODE_ENV;
        const _noop = (noop => {});
        const _consoleMethods = ['log', 'warn', 'error', 'exception', 'info', 'trace', 'debug', 'dir', 'time', 'timeEnd', 'timeLog', 'group', 'groupEnd', 'groupCollapsed'];
        
        const _bindActivationProperty = (scope) => {
            Object.defineProperty(scope, 'active', {
                get: () => { return _active; },
                set: (active) => { _active = active; }
            });
        };
        
        const _bindLevelProperty = (scope) => {
            Object.defineProperty(scope, 'level', {
                get: () => { return _level; },
                set: (level) => {
                    _level = (level instanceof Array) ? level : [];
                }
            });
        };

        _bindActivationProperty(this);
        _bindLevelProperty(this);

        this.sessionId = _.get(factoryConfig, 'session', '-session');
        this.vemEventBus = _.get(factoryConfig, 'bus', null);
        this.vemInstance = _.get(factoryConfig, 'module', null);
        this.playerInstance = _.get(factoryConfig, 'player', null);
        this.consoleBindings = {};
        this.factoryCreationIndex = vemd.instances++;
        
        if (!_.has(this, 'vemEventBus')) {
            this.vemEventBus = _.get(this, 'vemInstance._eventBus', null);
        }
        
        const sessionTag = vemd.name + ':' + this.sessionId;
        
        _.each(_consoleMethods, (method) => {
            this.consoleBindings[method] = _.has(console, method) ? console[method].bind(console, sessionTag) : _noop;
        });
        
        let Debugger = function (config) {
            this.name = _.get(config, 'name', ('Debugger:' + error.MISSING_NAME));

            _bindActivationProperty(this);
            _bindLevelProperty(this);
            
            _.each(_consoleMethods, (method) => {
                Object.defineProperty(this, method, {
                    get: () => {
                        return (_factoryInstance.active && ((this.level[0] === '*') || (_.indexOf(this.level, method) !== -1))) ? _factoryInstance.consoleBindings[method].bind(this, '[' + this.name + ']') : _noop;
                    }
                });
            });
            
            Object.defineProperty(this, 'bus', {
                get: () => {
                    return (_factoryInstance.active && ((this.level[0] === '*') || (_.indexOf(this.level, 'bus') !== -1))) ? _factoryInstance.consoleBindings.info.bind(this, '[' + this.name + ']') : _noop;
                }
            });
            
            Object.defineProperty(this, 'remote', {
                get: () => {
                    if (_.has(_factoryInstance, 'vemEventBus')) {
                        console.log('ve-debugger', 'using \'remote\' logger');
                        return function () {
                            _factoryInstance.bus.publish(constants.events.REMOTE_LOG, {
                                context: this.name,
                                msg: Array.prototype.slice.call(arguments)
                            });
                        };
                    } else {
                        return _noop;
                    }
                }
            });
            
        };
        _.extend(Debugger, {
            setPlayer: (player) => {
                this.playerInstance = player;
            }
        });
        
        vemd.Debugger = Debugger;
        
        vemd.modules[this.sessionId] = this;
        
        return Debugger;
    }
}

DebugFactory.controller = vemd;

// make the debugger available globally
if (typeof window !== 'undefined') {
    window[DebugFactory.controller.name] = _.get(window, DebugFactory.controller.name, DebugFactory.controller);
}

export {
    DebugFactory
};
