import _ from 'lodash';

import constants from '../lib/constants';
import vemEvents from '../lib/events';
import { BaseManager } from '../lib/base';

/**
 * @name EntityItem
 * @description containing any entities associated with the mediaItem
 */

class EntityList {
    /**
     * @class EntityList
     * @classdesc
     * @description containing all entities in corresponding order with the playlist
     * @example
     *  [
     *    EntityItem,
     *    EntityItem,
     *    ...
     *  ]
     */
    constructor(mediaItems) {
        let map = [];
        _.each(mediaItems, (mediaItem, index) => {
            map.push(_.get(mediaItem, ((_.has(mediaItem, 'meta') ? 'meta.' : '') + 'entities'), []));
        });
        return map;
    }

}

class EntityManager extends BaseManager {
    /**
     * @class EntityManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'EntityManager');

        this._dataManager = _.get(args, 'dataManager', null);
        this._playerManager = _.get(args, 'playerManager', null);
        
        this.reset();
        
        this.entityList = this._dataManager.getEntities();
        
        this._publishEntityListUpdate();
        
        this._eventBus.subscribe(constants.events.MEDIA_ITEM_CURRENT, this._onMediaItemCurrent.bind(this));
        this._eventBus.subscribe(constants.events.DATA_UPDATED, this._onDataUpdated.bind(this));

    }
    destroy() {
        this.reset();
    }
    reset() {
        this.entityList = {};
    }

    getPlayer() {
        return this._playerManager.getPlayer();
    }
    getCurrentMediaItemId() {
        return this._playerManager.getCurrentPlayingId();
    }
    /**
     * Returns an object containing all entities in the playlist
     * @returns {EntityList}
     */
    getEntityList() {
        return _.get(this, 'entityList', []);
    }
    /**
     * Returns an object containing all entities related to a mediaItem
     * @param videoId {String} The id/uuid/videoId of the mediaItem
     * @returns {EntitiesList}
     */
    getEntitiesByMediaItemId(videoId) {
        const mediaItemIndex = this._playerManager.getMediaItemIndexByMediaItemId(videoId);
        return (mediaItemIndex === -1) ? [] : this.entityList[mediaItemIndex];
    }
    /**
     * Returns an object containing all entities related to a mediaItem
     * @param playlistIndex {Number} The index/position of the mediaItem
     * @returns {EntityList}
     */
    getEntitiesByPosition(position) {
        // TODO: add safety
        return this.entityList[position];
    }
    /**
     * Returns an object containing current entity information
     * @returns {EntityItem}
     */
    getEntitiesForCurrentItem() {
        return this.getEntitiesByMediaItemId(this.getCurrentMediaItemId());
    }

    _publishEntityListUpdate() {
        if (!_.isEmpty(this.entityList)) {
            this._eventBus.publish(vemEvents.onEntityListUpdate);
        }
    }
    _publishCurrentEntityChanged() {
        if (!_.isEmpty(this.entityList)) {
            this._eventBus.publish(vemEvents.onCurrentEntityChanged, this.getEntitiesForCurrentItem());
        }
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onDataUpdated(lastResponse) {
        this.reset();
        this.entityList = _.get(lastResponse, 'entities', {});
        this._publishEntityListUpdate();
    }
    _onMediaItemCurrent(mediaItem) {
        this.currentMediaItemId = mediaItem.videoId;
        this._publishCurrentEntityChanged();
    }

}

export {
    EntityManager,
    EntityList
};
