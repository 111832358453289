import _ from 'lodash';

import constants from '../lib/constants';
import { BaseManager } from '../lib/base';

class SchedulingManager extends BaseManager {
    /**
     * @class SchedulingManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'SchedulingManager');

        this.init();
        this._dataManager = _.get(args, 'dataManager');
        
        this.updateData();

        this._eventBus.subscribe(constants.events.DATA_UPDATED, this._onDataUpdated.bind(this));
    }
    init() {
        this._currentScheduledVideo = null;
        this._dataManager = null;
        this._queue = [];
        this._timer = null;
        this._playlistLoopUntilTimer = null;
    }
    destroy() {
        this.resetTimer();
        this._dataManager = null;
    }
    clean() {
        this._queue = this._queue.filter(a => {
             return a.startTime.getTime() - Date.now() > 0;
        });
    }

    updateData() {
        const videos = this._dataManager.getScheduledVideos();
        // Create copy of videos.
        this._queue = videos.slice(0, videos.length);
        for (const video of videos) {
            if (video.startTime.getTime() < Date.now()) {
                this.fireOnSchedule(video);
            }
        }
        this.scheduleNext();

    }
    resetTimer() {
        if (this._scheduledEventTimer) {
            clearTimeout(this._scheduledEventTimer);
        }
        this._scheduledEventTimer = null;
    }
    scheduleNext() {
        this.resetTimer();
        this.clean();
        if (this._queue && this._queue.length > 0) {
            // Sort by startTime
            this._queue.sort((a, b) => {
                return a.startTime.getTime() - b.startTime.getTime();
            });

            const first = this._queue[0];
            this._scheduledEventTimer = setTimeout(this._onScheduledTimer.bind(this), first.startTime.getTime() - Date.now());
        }
    }
    fireOnSchedule(item) {
        this._eventBus.publish(constants.events.SCH_EVENT_INIT, item);
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onScheduledTimer() {
        if (this._queue && this._queue.length > 0) {
            const item = this._queue.shift();
            this.scheduleNext();
            this.fireOnSchedule(item);
        }
    }
    _onDataUpdated() {
        this.updateData();
    }

}

export {
    SchedulingManager
};
