// TBD: possibly add event name to outgoing payload to be used instead of
// constants everywhere

import _ from 'lodash';

import constants from './constants';
import vemEvents from './events';
import { BaseBus } from './base';

class EventBus extends BaseBus {
    /**
     * @class EventBus
     * @classdesc 
     * @extends BaseBus
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'EventBus');
        
        this._listeners = {};
        this._eventKeyCounter = 0;
        this._buffer = [];
        this._isRunning = false;
        this._runMode = _.get(args, 'runMode', constants.runModes.CLIENT);
    }
    init() {
        this.debug.log('initializing');
        this._isRunning = true;
        _.each(this._buffer, (fn) => {
            fn();
        });
    }
    destroy() {
        this._isRunning = false;
        this._listeners = {};
        this._eventKeyCounter = 0;
    }
    
    subscribe(event, callback) {
        this._listeners[event] = this._listeners[event] || {};
        this._eventKeyCounter++;
        if (this._runMode === constants.runModes.CLIENT) {
            this.debug.info('subscribing', event + '[' + this._eventKeyCounter + ']');
        }
        const subscriptionKey = event + '_' + this._eventKeyCounter;
        this._listeners[event][subscriptionKey] = callback;
        return subscriptionKey;
    }
    unsubscribe(event, subscriptionKey) {
        if (this._listeners[event]) {
            if (this._listeners[event].hasOwnProperty(subscriptionKey)) {
                delete this._listeners[event][subscriptionKey];
            }
        }
    }
    publish(event, args={}) {
        const listeners = this._listeners[event];
        if (listeners) {
            _.each(listeners, (listener, key) => {
                const now = Date.now();
                if (typeof listener === 'function') {
                    // check to see if this event is an external event and if so queue it
                    const isPublicEvent = (event in vemEvents) ? true : false;
                    const shouldQueue = (!this._isRunning && isPublicEvent) ? true : false;
                    this.debug.info((shouldQueue ? 'queueing' : 'publishing'), event + '[' + key.substring(key.lastIndexOf('_') + 1) + ']');
                    if (isPublicEvent && (event !== vemEvents.onEventPublished)) {
                        this.publish(vemEvents.onEventPublished, {
                            event: event,
                            time: now
                        });
                    }
                    if (shouldQueue) {
                        this._buffer.push(() => {
                            listener(args);
                        });
                    } else {
                        try {
                            listener(args);
                        } catch (error) {
                            // always print this error
                            console.log(error);
                        }
                    }
                } else {
                    this.debug.info('not a function');
                }
            });
        } else {
            this.debug.info('no matching listeners');
        }
    }

}

export {
    EventBus
};
