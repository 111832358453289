import _ from 'lodash';

import { BaseManager } from '../lib/base';

import constants from '../lib/constants';
import { utils } from '../lib/utils';

import '../ui/styles/core.scss';
import './styles/chyron.scss';

class Chyron extends BaseManager {
    /**
     * @class Chyron
     * @classdesc 
     * @param {Object}    args - Constructor args object that contains the following:
     */
    constructor(args, name='Chyron') {
        super(args, name);

        this._themeManager = _.get(args, 'themeManager', null);

        this._container = _.get(args, 'container', null);
        // 'normal' is the short chyron
        // if set to 'normal' routines will run that update gradients
        this._type = 'normal'; // prompt, location
        // Here we use a ThemeManager method to grab configs and overlay them
        // on top of the existing Alert.
        this._alert = this._applyTheme(_.get(args, 'alert', {}));
        this._id = _.get(this._alert, 'id', null);
        this._theme = _.get(this._alert, 'theme', null); // nfl, nba
        this._actionName = _.get(this._alert, 'actionName', null);
        this._actionTrigger = _.get(this._alert, 'actionTrigger', null);
        this._template = require('./templates/chyron.html');
        this._node = null;

        this._classNames = {
            container: '.ve-chyron',
            title: '.ve-chyron-title',
            subtitle: '.ve-chyron-subtitle'
        };

    }
    destroy() {
        this.unregisterEvents();
        if (this._node && this._node.parentNode) {
            this._node.parentNode.removeChild(this._node);
        }
        this._node = null;
    }
    create() {
        this._node = this.render();
        this._container.appendChild(this._node);
        this.registerEvents();
        if (this._type === 'normal') {
            this.updateGradients(this._alert);
        }
        return this._node;
    }    
    render() {
        const alert = this._alert;
        return utils.render(this.getTemplate(), {
            title: alert.title,
            subtitle: alert.subtitle
        });
    }
    show() {
        if (this._node && (this._node.style.display !== 'block')) {
            this._node.style.display = 'block';
            this._eventBus.publish(constants.events.CHYRON_SHOWN, this._alert);
        }
    }
    hide() {
        if (this._node && (this._node.style.display !== 'none')) {
            this._node.style.display = 'none';
            this._eventBus.publish(constants.events.CHYRON_HIDDEN, this._alert);
        }
    }
    update(alert) {
        const titleDiv = this._node.querySelector(this._classNames.title);
        if (titleDiv) {
            titleDiv.innerHTML = this._alert.title;
        }
        const subtitleDiv = this._node.querySelector(this._classNames.subtitle);
        if (subtitleDiv) {
            subtitleDiv.innerHTML = this._alert.subtitle;
        }
    }
    _applyTheme(alert) {
        return this._themeManager.applyTheme(alert);
    }
    getType() {
        return this._type;
    }
    getTemplate() {
        return this._template;
    }
    getActionName() {
        return this._actionName;
    }
    updateGradients() {
        if (alert.gradientStartColor && alert.gradientEndColor && this._node) {
            this._node.style.background = 'linear-gradient(to right,' + alert.gradientStartColor + ',' + alert.gradientEndColor +')';
        }
    }
    updateSubtitle(subtitle) {
        const subtitleDiv = this._node.querySelector(this._classNames.subtitle);
        if (subtitleDiv) {
            subtitleDiv.innerHTML = subtitle;
        }
    }
    registerEvents() {
        if (this._node) {
            this._node.addEventListener('mouseup', this._onSelect.bind(this), false);
            this._node.addEventListener('touchend', this._onSelect.bind(this), false);
        }
    }
    unregisterEvents() {
        if (this._node) {
            this._node.removeEventListener('mouseup', this._onSelect.bind(this));
            this._node.removeEventListener('touchend', this._onSelect.bind(this));
        }
    }
    isShown() {
        return this._node && (this._node.style.display == 'block');
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onSelect(event) {
        this._eventBus.publish(constants.events.CHYRON_TAPPED, {
            alert: this._alert
        });
        this.hide();
        event.preventDefault();
    }

}

export {
    Chyron
};
