import constants from './constants';

const InputController = function (args) {
    const eventBus = args.bus;
    const keysPressed = [];
    const keyMap = constants.keyMap;
    const passPhrase = [keyMap.v, keyMap.e, keyMap.m, keyMap.d];

    window.addEventListener('keydown', (event) => {
        if (event.isComposing || (event.keyCode === 229)) {
            return;
        }

        keysPressed.push(event.keyCode);
        
        if (keysPressed.length > passPhrase.length) {
            keysPressed.shift();
        }

        if (keysPressed.slice(Math.max(keysPressed.length - passPhrase.length, 0)).toString() === passPhrase.toString()) {
            eventBus.publish(constants.events.SESSION_ATTRIBUTION);
            return;
        }

    });
    
};

export {
    InputController
};
