import _ from 'lodash';

import constants from '../lib/constants';
import vemEvents from '../lib/events';
import { BaseManager } from '../lib/base';
import { SectionList } from './section';
import { EntityList } from './entity';
import { VideoExperiencesResponse } from '../models/video-experiences-response';

class DataManager extends BaseManager {
    /**
     * @class DataManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'DataManager');
        
        this._channelId = _.get(args, 'channelId', '');
        this._lastError = null;
        this._lastResponse = null;
        this._lastResponseRaw = null
        this._lastResponseLocation = null;
        this._lastScheduleUrl = null;
        this._lastScheduleState = null;
        this._watchTokens = _.get(args, 'watchTokens', null);
        this._containerNode = _.get(args, 'containerNode', null);
        this._playlistLoopUntil = 0;
        this._debugCountdownSeconds = _.get(args, 'debugCountdownSeconds', null);
        this._debugPlaylistLoopSeconds = _.get(args, 'debugPlaylistLoopSeconds', null);

        this._locationManager = _.get(args, 'locationManager', null);
        this._networkingManager = _.get(args, 'networkingManager', null);
        this._stateManager = _.get(args, 'stateManager', null);
        this._themeManager = _.get(args, 'themeManager', null);
        
        this._eventBus.subscribe(constants.events.FETCH_VIDEO_EXPERIENCES_RESOLVED, this._onFetchVideoExperiencesResolved.bind(this));
        this._eventBus.subscribe(constants.events.FETCH_VIDEO_EXPERIENCES_REJECTED, this._onFetchVideoExperiencesRejected.bind(this));

    }
    destroy() {
        this._channelId = null;
    }

    /**
     * 
     * @param {Object} videoExperiences 
     * @param {Boolean} skipResolve 
     */
    setVideoExperiences(videoExperiences, skipResolve) {
        // this.debug.log('setVideoExperiences');
        if (videoExperiences) {
            this.debug.info('setVideoExperiences', ((skipResolve ? 'skip' : 'perform') + ' resolve'));
            this._onFetchVideoExperiencesResolved({
                data: videoExperiences,
                location: this._locationManager.getCurrentLocation(),
                skipResolve: skipResolve
            });
        } else {
            const statusText = 'videoExperiences missing';
            this.debug.log('setVideoExperiences', statusText);
            this._onFetchVideoExperiencesRejected({
                statusText: statusText
            });
        }
    }
    getContainerNode() {
        return this._containerNode;
    }
    setContainerNode(containerNode) {
        this.debug.log('setContainerNode', 'update', 'containerNode', containerNode);
        this._containerNode = containerNode;
    }
    /**
     * 
     * @param {Object} params 
     */
    setCommonParams(params) {
        if (params) {
            this._networkingManager.setCommonParams(params);
        }
    }
    /**
     * @returns {Object}
     */
    getCommonParams() {
        return this._networkingManager.getCommonParams();
    }
    /**
     * sets the next fetch time for the schedule api call
     * @param {Date} date 
     */
    setNextFetchTime(date) {
        this._networkingManager.setNextFetchTime(date);
    }
    /**
     * returns the next scheduled fetch time the schedule api call
     * @returns {Date}
     */
    getNextFetchTime() {
        return this._networkingManager.getNextFetchTime();
    }
    setWatchTokens(tokens) {
        // TODO: add safety
        this.debug.log('setWatchTokens', 'updating watch tokens');
        this._watchTokens = tokens;
    }
    getWatchTokens() {
        return _.get(this, '_watchTokens', []);
    }
    /**
     * returns alerts from the last schedule api call
     * @returns {Array}
     */
    getAlerts() {
        return _.get(this, '_lastResponse.alerts', []);
    }
    /**
     * return sscheduled videos from the last schedule api call
     * @returns {Array} 
     */
    getScheduledVideos() {
        return _.get(this, '_lastResponse.scheduledVideos', []);
    }
    dedupeScheduledVideosByGameId(blockedGameId) {
        return _.remove(this._lastResponse.scheduledVideos, (item) => {
            return (item.gameId === blockedGameId);
        });
    }
    /**
     * 
     */
    getPlaylist() {
        return _.get(this, '_lastResponse.playlist', []);
    }
    /**
     * 
     */
    getPlaylistLoopUntil() {
        return this._playlistLoopUntil ? new Date(this._playlistLoopUntil) : null;
    }
    /**
     * @returns sections object generated from the last response
     */
    getSections() {
        return _.get(this, '_lastResponse.sections', []);
    }
    /**
     * @returns entities object generated from the last response
     */
    getEntities() {
        return _.get(this, '_lastResponse.entities', []);
    }
    getVideoExperiences(raw) {
        return raw ? this._lastResponseRaw : this._lastResponse;
    }
    /**
     * @returns location object associated with last schedule api call
     */
    getLastResponseLocation() {
        return this._lastResponseLocation;
    }
    /**
     * @returns the server state object from the last schedule api call
     */
    getScheduleState() {
        return this._lastScheduleState;
    }
    /**
     * 
     * @param {Object} playlist 
     * @param {Boolean} firstOnly=false
     */
    getPlayerPlaylist(playlist, variant) {
        const firstOnly = _.isBoolean(variant) ? variant : false;
        const videoId = _.isString(variant) ? variant : false;
        // accepts scheduled_videos or live_videos from DataManager
        let idList = [];
        playlist = playlist || this.getPlaylist();
        if (videoId) {
            playlist = [_.find(playlist, ['videoId', videoId])];
        }
        _.each(playlist, (video) => {
            idList.push({
                id: _.isString(video) ? video : video.videoId,
                mimetype: 'media/sapi'
            });
        });
        if (firstOnly) {
            idList = [_.first(idList)];
        }
        return idList;
    }
    getNextCountdown(currentPlayingVideoId) {
        const now = Date.now();
        const scheduledVideos = this.getScheduledVideos();
        let playlistCountdown = this._lastResponse?.countdownTimerEndTime || 0;
        if (this.debug.active && this._debugCountdownSeconds) {
            // adjust for querystring param of 'vemdcdus'
            playlistCountdown = Date.now() + (this._debugCountdownSeconds * 1000);
        }
        let countdown = 0;
        let streamCountdown = 0;

        _.each(scheduledVideos, (video) => {
            const streamDate = video.startTime.getTime();
            if ((now < streamDate) || (currentPlayingVideoId === video.videoId)) {
                // only include streams that are in the future
                const streamOffset = video?.countdownUntil || null;
                if (streamOffset) {
                    // stream has offset
                    const countdownEndTime = streamDate + (streamOffset * 1000);
                    // get the nearest date
                    if (countdownEndTime > now) {
                        if (streamCountdown === 0) {
                            streamCountdown = countdownEndTime;
                        } else {
                            if (streamCountdown > countdownEndTime) {
                                streamCountdown = countdownEndTime;
                            }
                        }
                    }
                }
            }
        });
        if (streamCountdown) {
            countdown = new Date(streamCountdown);
            this.debug.log('getNextCountdown', 'using stream countdown', countdown);
        } else {
            countdown = playlistCountdown;
            this.debug.log('getNextCountdown', 'using playlist countdown', countdown);
        }

        return (countdown > 0) ? countdown : null;
    }

    processVideoExperiences(rawVideoExperiences) {
        //const logName = 'processVideoExperiences';
        //console.log(logName, 'rawVideoExperiences', rawVideoExperiences);
        const data = rawVideoExperiences.video_experiences || rawVideoExperiences;
        //console.log(logName, 'data', data);
        const scheduleResponse = new VideoExperiencesResponse(data);
        //console.log(logName, 'scheduleResponse', scheduleResponse);
        scheduleResponse.theme = _.first(_.split(_.get(_.first(scheduleResponse.scheduledVideos), 'gameId', ''), '.'));
        scheduleResponse.entities = new EntityList(data.playlist);
        scheduleResponse.sections = new SectionList(data.playlist);
        // TBD: we want to incorporate this with the StateManager
        scheduleResponse.state = _.get(data, 'state', {});
        scheduleResponse.intercept = _.get(data, 'intercept', false);
        // console.log(logName, 'scheduleResponse.intercept', scheduleResponse.intercept);
        if (scheduleResponse.intercept) {
            // check if the response is coming from a proxy with a designated
            // header. if so, then we want to update the next_fetch_time in the
            // response so that the program will fetch again. in these situations
            // the response is likely coming from a static file and the
            // next_fetch_time would be stale
            const nextFetchTimePre = _.get(scheduleResponse, 'nextFetchTime', 0);
            let nextFetchTimePost = nextFetchTimePre;
            let dateNow = new Date(Date.now());
            let dateNowPlusTwoMinutes = new Date(dateNow.valueOf() + (2*60*1000));
            if (nextFetchTimePre < dateNow) {
                nextFetchTimePost = new Date(dateNowPlusTwoMinutes.toISOString().replace('Z', '+0000'));
                this.debug.log('next fetch time', 'stale so setting it +2m from now');
                this.debug.log('next fetch time', new Date(nextFetchTimePre), '(original)');
                this.debug.log('next fetch time', nextFetchTimePost, '(adjusted)');
            }
            if (nextFetchTimePre !== nextFetchTimePost) {
                scheduleResponse.nextFetchTime = nextFetchTimePost;
            }
        }
        scheduleResponse.playlistLoopUntil = _.get(data, 'playlist_loop_until', this._playlistLoopUntil);
        scheduleResponse.countdownTimerEndTime = _.get(data, 'count_down_until', 0);
        
        // if there is a zip_woeid in the state object then update locally
        if (_.has(scheduleResponse, 'state.zip_woeid')) {
            this._zipWoeid = _.get(scheduleResponse, 'state.zip_woeid', null);
        }
        return scheduleResponse;
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    /**
     * @listens event:FETCH_VIDEO_EXPERIENCES_RESOLVED
     * @fires event:onVideoExperiencesUpdated
     * @fires event:DATA_UPDATED
     * @param {Object} payload 
     */
    _onFetchVideoExperiencesResolved(payload) {
        // this.debug.log('_onFetchVideoExperiencesResolved', 'payload', payload);
        const scheduleResponseRaw = _.get(payload, 'data', null);
        // this.debug.log('_onFetchVideoExperiencesResolved', 'scheduleResponseRaw', scheduleResponseRaw);
        const scheduleResponseProcessed = this.processVideoExperiences(scheduleResponseRaw);
        // this.debug.log('_onFetchVideoExperiencesResolved', 'scheduleResponseProcessed', scheduleResponseProcessed);
        if (scheduleResponseProcessed) {
            this._lastResponse = scheduleResponseProcessed;
            this._lastResponseRaw = scheduleResponseRaw;
            this._lastResponseLocation = _.get(payload, 'location', null);
            this._lastScheduleUrl = _.get(payload, 'url', null);
            this._lastScheduleState = scheduleResponseProcessed.state;
            this._playlistLoopUntil = scheduleResponseProcessed.playlistLoopUntil;
            if (this._debugPlaylistLoopSeconds) {
                console.log('this._debugPlaylistLoopSeconds', this._debugPlaylistLoopSeconds);
                this._playlistLoopUntil = Date.now() + (this._debugPlaylistLoopSeconds * 1000);
            }
            let activeTheme = this._themeManager.getTheme();
            if (!activeTheme) {
                const discoveredTheme = _.get(scheduleResponseProcessed, 'theme', null);
                if (discoveredTheme) {
                    activeTheme = this._themeManager.setTheme(discoveredTheme);
                }
            }
            const proxyIntercept = _.get(scheduleResponseProcessed, 'intercept', false);
            if (this.debug.active) {
                const state = this._stateManager.getStateString();
                this.debug.info('videoExperiences', this._lastResponse);
                this.debug.log('proxyIntercept', proxyIntercept);
                this.debug.log('scheduleState', this._lastScheduleState);
                this.debug.log('activeTheme', (activeTheme || 'indeterminate'));
                this.debug.log('scheduledVideos [' + _.size(this._lastResponse.scheduledVideos) + ']');
                this.debug.log('playlistVideos [' + _.size(this._lastResponse.playlist) + ']');
                this.debug.log('playlistLoopUntil', this.getPlaylistLoopUntil());
                this.debug.log('countdownTimerEndTime', this.getNextCountdown());
                // this.debug.log('debugCountdownSeconds', this._debugCountdownSeconds);
            }

            this.setNextFetchTime(this._lastResponse.nextFetchTime);
            
            this._eventBus.publish(constants.events.UPDATE_THEME, activeTheme);

            if (!payload.skipResolve) {
                this._eventBus.publish(vemEvents.onVideoExperiencesUpdated, {
                    // if this is set this means we have received a schedule
                    // response that contains a video_experiences.intercept
                    // property. this can be set by the author of the response
                    // and if present will trigger integration level logic
                    // for example, the VEMI uses this flag to automatically
                    // update next_fetch_time by adding +2 minutes from now
                    // if it finds that the provided time in the response is before
                    // Date.now
                    intercept: proxyIntercept
                });
            }
            this._networkingManager._scheduleRefetch();
            // The payload sent is used in some places and not others. It
            // should be made consistent across all listeners and the constant
            // name should be updated to reflect a more specific purpose. The
            // event emitted internally is DATA_UPDATED, it is typically
            // handled by listeners called onDataUpdated and yet the event
            // emitted to clients is onDataLoaded. It's intended purpose was
            // to simply 'tell' the client that the fetched schedule data is
            // ready to use.
            // The data being sent has already been parsed through
            // 'video-experiences-response' and so is not the raw schedule api
            // response.
            this._eventBus.publish(constants.events.DATA_UPDATED, this._lastResponse);
        } else {
            this.debug.log('_onFetchVideoExperiencesResolved', 'schedule response empty');
        }

    }
    /**
     * @listens event:FETCH_VIDEO_EXPERIENCES_REJECTED
     * @fires event:DATA_ERROR
     * @param {Object} payload 
     */    
    _onFetchVideoExperiencesRejected(payload) {
        //this.debug.log('_onFetchVideoExperiencesRejected', 'payload', payload);
        this._lastError = payload;
        this._eventBus.publish(constants.events.DATA_ERROR, payload);
    }

}

export {
    DataManager
};
