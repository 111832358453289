const constants = {
    LOCAL_STORAGE_KEY_MAIN: "vem",
    VE_MODULE_REMOTE_CONFIG_URL: "https://s.yimg.com/rb/videoexp/v2/config/prod/$site/$cid/client_config.json",
    VE_MODULE_LOG_URL: "https://video.mobile.yahoo.com/log",
    VE_MODULE_CDN_ASSETS: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/',
    rapid: {
        beaconTypes: {
            CLICK: 'rapid_beacon_click',
            LINK_VIEW: 'rapid_beacon_link_view',
            EVENT: 'rapid_beacon_event',
            PERFORMANCE: 'rapid_performance_data'
        },
        moduleNames: {
            VEM_INIT: 'vem-init',
            NFL_GAME_PICKER: 'nfl-game-picker',
            LOCATION_CHYRON: 'nfl-location-chyron',
            LOCATION_CHYRON_DENIED: 'nfl-location-chyron-denied',
            WATCH_NOW_CHYRON: 'nfl-watch-now-chyron'
        },
        buttonNames: {
            ALLOW_LOCATION: 'allow_location',
            DENIED_LOCATION: 'denied_location',
            CLOSE: 'close',
            WATCH_NOW: 'watch_now',
            UPDATE_OS: 'update_os'
        }
    },
    /**
     * possible program states
     * @enum {Number}
     */
    states: {
        UNLOADED: -1,
        LOADING: 0,
        LOADED: 1,
        INITIALIZING: 2,
        INITIALIZED: 3,
        RUNNING: 4,
        PAUSED: 5,
        WAITING: 6,
        REQUEST_LOCATION: 7,
        INSTANT_APP: 8,
        SPORTS_APP: 9
    },
    /**
     * @namespace Internal
     */
    events: {
        /**
         * @event DATA_UPDATED
         * @memberof Internal
         */
        DATA_UPDATED: "DATA_UPDATED",
        /**
         * @event DATA_ERROR
         * @memberof Internal
         */
        DATA_ERROR: "DATA_ERROR",
        /**
         * @event CHYRON_TAPPED
         * @memberof Internal
         */
        CHYRON_TAPPED: "CHYRON_TAPPED",
        /**
         * @event VIDEO_SELECT_TAPPED
         * @memberof Internal
         */
        VIDEO_SELECT_TAPPED: "VIDEO_SELECT_TAPPED",
        /**
         * @event REMOTE_CONFIG_CALL
         * @memberof Internal
         */
        REMOTE_CONFIG_CALL: "REMOTE_CONFIG_CALL",
        /**
         * @event CONFIG_UPDATED
         * @memberof Internal
         */
        CONFIG_UPDATED: "CONFIG_UPDATED",
        /**
         * @event LOCATION_UPDATED
         * @memberof Internal
         */
        LOCATION_UPDATED: "LOCATION_UPDATED",
        /**
         * @event LOCATION_NOT_CHANGED_ENOUGH
         * @memberof Internal
         */
        LOCATION_NOT_CHANGED_ENOUGH: "LOCATION_NOT_CHANGED_ENOUGH",
        /**
         * @event LOCATION_DENIED
         * @memberof Internal
         */
        LOCATION_DENIED: "LOCATION_DENIED",
        /**
         * @event SCHEDULE_API_CALL
         * @memberof Internal
         */
        SCHEDULE_API_CALL: "SCHEDULE_API_CALL",
        /**
         * @event REMOTE_CONFIG
         * @memberof Internal
         */
        REMOTE_CONFIG: "REMOTE_CONFIG",
        /**
         * @event SCH_VIDEO_PLAYBACK
         * @memberof Internal
         */
        SCH_VIDEO_PLAYBACK: "SCH_VIDEO_PLAYBACK",
        /**
         * @event SCH_VIDEO_PLAYBACK_ALREADY_STARTED
         * @memberof Internal
         */
        SCH_VIDEO_PLAYBACK_ALREADY_STARTED: "SCH_VIDEO_PLAYBACK_ALREADY_STARTED",
        /**
         * @event SCH_VIDEO_PLAYBACK_FAILED
         * @memberof Internal
         */
        SCH_VIDEO_PLAYBACK_FAILED: "SCH_VIDEO_PLAYBACK_FAILED",
        /**
         * @event PLAYLIST_PLAYBACK
         * @memberof Internal
         */
        PLAYLIST_PLAYBACK: "PLAYLIST_PLAYBACK",
        /**
         * @event CHYRON_SHOWN
         * @memberof Internal
         */
        CHYRON_SHOWN: "CHYRON_SHOWN",
        /**
         * @event CHYRON_HIDDEN
         * @memberof Internal
         */
        CHYRON_HIDDEN: "CHYRON_HIDDEN",
        /**
         * @event CHYRON_LOCATION_TAPPED
         * @memberof Internal
         */
        CHYRON_LOCATION_TAPPED: "CHYRON_LOCATION_TAPPED",
        /**
         * @event CHYRON_LOCATION_DENIED_SHOWN
         * @memberof Internal
         */
        CHYRON_LOCATION_DENIED_SHOWN: "CHYRON_LOCATION_DENIED_SHOWN",
        /**
         * @event VIDEO_SELECT_SHOWN
         * @memberof Internal
         */
        VIDEO_SELECT_SHOWN: "VIDEO_SELECT_SHOWN",
        /**
         * @event VIDEO_SELECT_HIDDEN
         * @memberof Internal
         */
        VIDEO_SELECT_HIDDEN: "VIDEO_SELECT_HIDDEN",
        /**
         * @event CUSTOM_LOG
         * @memberof Internal
         */
        CUSTOM_LOG: "CUSTOM_LOG",
        /**
         * @event REMOTE_LOG
         * @memberof Internal
         */
        REMOTE_LOG: "REMOTE_LOG",
        /**
         * @event DEBUG_LOG
         * @memberof Internal
         */
        DEBUG_LOG: "DEBUG_LOG",
        /**
         * @event PLAYBACK_START
         * @memberof Internal
         */
        PLAYBACK_START: "VIDEO_PLAYBACK_START",
        /**
         * @event PLAYBACK_ERROR
         * @memberof Internal
         */
        PLAYBACK_ERROR: "VIDEO_PLAYBACK_ERROR",
        /**
         * @event PLAYBACK_COMPLETE
         * @memberof Internal
         */
        PLAYBACK_COMPLETE: "VIDEO_PLAYBACK_COMPLETE",
        /**
         * @event SECTION_START
         * @memberof Internal
         */
        SECTION_START: "VIDEO_SECTION_START",
        /**
         * @event SECTION_COMPLETE
         * @memberof Internal
         */
        SECTION_COMPLETE: "VIDEO_SECTION_COMPLETE",
        /**
         * @event PLAYBACK_WILL_START
         * @memberof Internal
         */
        PLAYBACK_WILL_START: "PLAYBACK_WILL_START",
        /**
         * @event PLAYBACK_WILL_COMPLETE
         * @memberof Internal
         */
        PLAYBACK_WILL_COMPLETE: "PLAYBACK_WILL_COMPLETE",
        /**
         * @event PLAYBACK_PROGRESS
         * @memberof Internal
         */
        PLAYBACK_PROGRESS: "PLAYBACK_PROGRESS",
        /**
         * @event PLAYBACK_REQUEST
         * @memberof Internal
         */
        PLAYBACK_REQUEST: "PLAYBACK_REQUEST",
        /**
         * @event MEDIA_ITEM_CURRENT
         * @memberof Internal
         */
        MEDIA_ITEM_CURRENT: "MEDIA_ITEM_CURRENT",
        /**
         * @event PLAYLIST_COMPLETE
         * @memberof Internal
         */
        PLAYLIST_COMPLETE: "PLAYLIST_COMPLETE",
        /**
         * @event SHOW_VIDEO_SELECT
         * @memberof Internal
         */
        SHOW_VIDEO_SELECT: "SHOW_VIDEO_SELECT",
        /**
         * @event FETCH_VIDEO_EXPERIENCES_RESOLVED
         * @memberof Internal
         */
        FETCH_VIDEO_EXPERIENCES_RESOLVED: "FETCH_VIDEO_EXPERIENCES_RESOLVED",
        /**
         * @event FETCH_VIDEO_EXPERIENCES_REJECTED
         * @memberof Internal
         */
        FETCH_VIDEO_EXPERIENCES_REJECTED: "FETCH_VIDEO_EXPERIENCES_REJECTED",
        /**
         * @event FETCH_REMOTE_CONFIG_RESOLVED
         * @memberof Internal
         */
        FETCH_REMOTE_CONFIG_RESOLVED: "FETCH_REMOTE_CONFIG_RESOLVED",
        /**
         * @event FETCH_REMOTE_CONFIG_REJECTED
         * @memberof Internal
         */
        FETCH_REMOTE_CONFIG_REJECTED: "FETCH_REMOTE_CONFIG_REJECTED",
        /**
         * @event VIDEOPLAYER_ATTACHED
         * @memberof Internal
         */
        VIDEOPLAYER_ATTACHED: "VIDEOPLAYER_ATTACHED",
        /**
         * @event VIDEOPLAYER_READY
         * @memberof Internal
         */
        VIDEOPLAYER_READY: "VIDEOPLAYER_READY",
        /**
         * @event VIDEOPLAYER_RENDERED
         * @memberof Internal
         */
        VIDEOPLAYER_RENDERED: "VIDEOPLAYER_RENDERED",
        /**
         * @event VIDEOPLAYER_ERROR
         * @memberof Internal
         */
        VIDEOPLAYER_ERROR: "VIDEOPLAYER_ERROR",
        /**
         * @event STATE_CHANGE
         * @memberof Internal
         */
        STATE_CHANGE: "STATE_CHANGE",
        /**
         * @event ALERT_START
         * @memberof Internal
         */
        ALERT_START: "ALERT_START",
        /**
         * @event SCH_EVENT_INIT
         * @memberof Internal
         */
        SCH_EVENT_INIT: "SCH_EVENT_INIT",
       /**
         * @event CHYRON_WAIT_FOR_LOCATION_SHOWN
         * @memberof Internal
         */
        CHYRON_WAIT_FOR_LOCATION_SHOWN: "CHYRON_WAIT_FOR_LOCATION_SHOWN",
        /**
         * @event INITIALIZE
         * @memberof Internal
         */
        INITIALIZE: "INITIALIZE",
        /**
         * @event LOCATION_REQUEST_SERVER_RESOLVED
         * @memberof Internal
         */
        LOCATION_REQUEST_SERVER_RESOLVED: "LOCATION_REQUEST_SERVER_RESOLVED",
        /**
         * @event SESSION_ATTRIBUTION
         * @memberof Internal
         */
        SESSION_ATTRIBUTION: "SESSION_ATTRIBUTION",
        /**
         * @event COUNTDOWN_SHOWN
         * @memberof Internal
         */
        COUNTDOWN_SHOWN: "COUNTDOWN_SHOWN",
        /**
         * @event COUNTDOWN_HIDDEN
         * @memberof Internal
         */
        COUNTDOWN_HIDDEN: "COUNTDOWN_HIDDEN",
        /**
         * @event COUNTDOWN_COMPLETE
         * @memberof Internal
         */
        COUNTDOWN_COMPLETE: "COUNTDOWN_COMPLETE",
        /**
         * @event UPDATE_THEME
         * @memberof Internal
         */
        UPDATE_THEME: "UPDATE_THEME",
        /**
         * @event API_PLAY_STREAM
         * @memberof Internal
         */
        API_PLAY_STREAM: "API_PLAY_STREAM",
        /**
         * @event PLAYER_RESPAWN
         * @memberof Internal
         */
        PLAYER_RESPAWN: "PLAYER_RESPAWN"
    },
    /**
     * @namespace Log
     */
    logEvents: {
        /**
         * @event ALERT_START
         * @memberof Log
         */
        ALERT_START: "ve_alert_start",
        /**
         * @event ALERT_ACTION
         * @memberof Log
         */
        ALERT_ACTION: "ve_alert_action",
        /**
         * @event SCHEDULED_VIDEO_START
         * @memberof Log
         */
        SCHEDULED_VIDEO_START: "ve_scheduled-video_start",
        /**
         * @event SCHEDULED_VIDEO_END
         * @memberof Log
         */
        SCHEDULED_VIDEO_END: "ve_scheduled-video_end",
        /**
         * @event PLAYLIST_START
         * @memberof Log
         */
        PLAYLIST_START: "ve_playlist_start",
        /**
         * @event PLAYLIST_END
         * @memberof Log
         */
        PLAYLIST_END: "ve_playlist_end",
        /**
         * @event VE_API
         * @memberof Log
         */
        VE_API: "ve_api-response",
        /**
         * @event REMOTE_CONFIG
         * @memberof Log
         */
        REMOTE_CONFIG: "ve_remote-config",
        /**
         * @event LOCATION
         * @memberof Log
         */
        LOCATION: "ve_location",
        /**
         * @event SCHEDULED_VIDEO_PLAYBACK_START
         * @memberof Log
         */
        SCHEDULED_VIDEO_PLAYBACK_START: "ve_scheduled-video_playback_start",
        /**
         * @event PLAYBACK_START
         * @memberof Log
         */
        PLAYBACK_START: "ve_playback_start",
        /**
         * @event PLAYBACK_ERROR
         * @memberof Log
         */
        PLAYBACK_ERROR: "ve_playback_error",
        /**
         * @event PLAYLIST_PLAYBACK_START
         * @memberof Log
         */
        PLAYLIST_PLAYBACK_START: "ve_playlist_playback_start",
        /**
         * @event PLAYLIST_PLAYBACK_END
         * @memberof Log
         */
        PLAYLIST_PLAYBACK_END: "ve_playlist_playback_end",
        /**
         * @event CHYRON_SHOWN
         * @memberof Log
         */
        CHYRON_SHOWN: "ve_chyron_shown",
        /**
         * @event CHYRON_HIDDEN
         * @memberof Log
         */
        CHYRON_HIDDEN: "ve_chyron_hidden",
        /**
         * @event CHYRON_LOCATION_TAPPED
         * @memberof Log
         */
        CHYRON_LOCATION_TAPPED: "ve_chyron_location_tapped",
        /**
         * @event CHYRON_LOCATION_DENIED_SHOWN
         * @memberof Log
         */
        CHYRON_LOCATION_DENIED_SHOWN: "ve_chyron_location_denied_shown",
        /**
         * @event VIDEO_SELECT_SHOWN
         * @memberof Log
         */
        VIDEO_SELECT_SHOWN: "ve_video-select_shown",
        /**
         * @event VIDEO_SELECT_HIDDEN
         * @memberof Log
         */
        VIDEO_SELECT_HIDDEN: "ve_video-select_hidden",
        /**
         * @event VIDEO_SELECT_TAPPED
         * @memberof Log
         */
        VIDEO_SELECT_TAPPED: "ve_video-select_tapped",
        /**
         * @event VIDEO_SELECT_USER_SHOWN
         * @memberof Log
         */
        VIDEO_SELECT_USER_SHOWN: "ve_video-select_user-shown",
        /**
         * @event VIDEO_SELECT_USER_HIDDEN
         * @memberof Log
         */
        VIDEO_SELECT_USER_HIDDEN: "ve_video-select_user-hidden",
        /**
         * @event CUSTOM_LOG
         * @memberof Log
         */
        CUSTOM_LOG: "ve_custom_log",
        /**
         * @event REMOTE_LOG
         * @memberof Log
         */
        REMOTE_LOG: "ve_remote_log",
        /**
         * @event DEBUG_LOG
         * @memberof Log
         */
        DEBUG_LOG: "ve_debug_log",
        /**
         * @event VEM_INIT
         * @memberof Log
         */
        VEM_INIT: "ve_init",
        /**
         * @event LOCATION_REQUEST_SERVER_RESOLVED
         * @memberof Log
         */
        LOCATION_REQUEST_SERVER_RESOLVED: "ve_lrs_resolved",
        /**
         * @event SESSION_ATTRIBUTION
         * @memberof Log
         */
        SESSION_ATTRIBUTION: "ve_session_attribution",
        /**
         * @event API_PLAY_STREAM
         * @memberof Log
         */
        API_PLAY_STREAM: "ve_api_play-stream",
        /**
         * @event CHYRON_LOCATION_TAPPED
         * @memberof Log
         */
        CHYRON_WATCH_NOW_TAPPED: "ve_chyron_watch-now_tapped",
        /**
         * @event PLAYER_RESPAWN
         * @memberof Log
         */
        PLAYER_RESPAWN: "ve_player_respawn",
        /**
         * @event PLAYBACK_WILL_START
         * @memberof Internal
         */
        PLAYBACK_WILL_START: "ve_playback_will_start"
    },
    keyMap: {
        v: 86,
        e: 69,
        m: 77,
        d: 68
    },
    runModes: {
        'CLIENT': 'CLIENT',
        'SERVER': 'SERVER'
    },
    StreamEventTypes: {
        'pre': 'pre',
        'live': 'live',
        'post': 'post'
    },
    PositionEventTypes: {
        'before-event': 'before-event',
        'during-event': 'during-event',
        'after-event': 'after-event'
    },
    RespawnPlayerLevels: {
        'off': 0,
        'on': 1,
        'liveOnly': 2,
        'vodOnly': 3
    },
    PlayerRespawnAllowList: {
        '400': ['602']
    }
};

export default constants;
