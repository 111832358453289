/*
TODO: REPLACE_ID_EXTRACTIONS
There is more than one method to get an id list for the UP
Search for them all and consolidate.

TODO: make sure to account for player respawns after the playlist has changed
This will require saving the current player playlist and reinserting it into
the playerConfig before constructing the new player instance

TBD: Should I be attempting to respawn the player if the error contains
isRecoverable:true

*/
import _ from 'lodash';

import { utils } from '../lib/utils';
import constants from '../lib/constants';
import vemEvents from '../lib/events';
import { BaseManager } from '../lib/base';
import { VideoMetadata } from '../models/video-metadata';

class PlayerManager extends BaseManager {
    /**
     * @class PlayerManager
     * @classdesc
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'PlayerManager');
        // this is used to setPlayer on the debugger
        // FIX: we should be able to do this from just this.debug, right ?
        this._Debugger = _.get(args, 'Debugger');
        this._stateManager = _.get(args, 'stateManager', null);
        this._videoplayer = _.get(args, 'videoplayer', null);
        this._isOath = false;
        this._dataManager = _.get(args, 'dataManager', null);
        this._locationManager = _.get(args, 'locationManager', null);
        // TODO: this should use the dataManager to retrieve the container
        // otherwise if the container is updated before render we won't have it
        this._container = _.get(args, 'container', null);
        this._playerNode = null;
        this._restorePlaylist = null;
        this._playerConfig = _.get(args, 'playerConfig', null)

        // used to periodically try and add the other feeds button
        // in cases where the player is not quite DOM ready
        this._otherFeedsButtonTimer = null;
        // during a play attempt we want to track the id
        // if we get a PLAYBACK_STARTED event from the player
        // then we know the video is playing
        // if we get a PLAYER_INFO from the player then there
        // was an error which we can respond to
        // in cases where the video times out we get neither
        // TODO: we should consider a timeout value on loading videos
        this._playAttemptId = null;
        this._storageKey = 'pm';
        this._state = this.getStateFromLocalStorage();
        this._videoSelectionButton = {
            element: null,
            icon: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjEuMjIgMTYuMTNsLTQuMjA1LTIuNjQ3LjAwMiA0LjI1MkgyLjc3OVY2LjI2NWgxNC4yMzZ2NC4yNTNMMjEuMjIgNy44N3Y4LjI2em0uNDE0LTEwLjYxbC0yLjg0IDEuNzg3VjUuMzgyYS44ODYuODg2IDAgMCAwLS44OS0uODgySDEuODlhLjg4Ni44ODYgMCAwIDAtLjg5Ljg4MnYxMy4yMzZjMCAuNDg4LjM5OS44ODIuODkuODgyaDE2LjAxNWMuNDkyIDAgLjg5LS4zOTQuODktLjg4MnYtMS45MjRsMi44NCAxLjc4N2MuMjcyLjE3MS42Mi4xODMuOTA0LjAyN2EuODguODggMCAwIDAgLjQ2MS0uNzczVjYuMjY1YS44ODIuODgyIDAgMCAwLS40Ni0uNzczLjkuOSAwIDAgMC0uOTA2LjAyOHoiIGZpbGw9IndoaXRlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iLjMiPjwvcGF0aD48L3N2Zz4=",
            toolTip: {
                text: 'Streams',
                surfacedCount: 0,
                surfacedLimit: 1,
                exhausted: Boolean(Number(_.get(this, '_state.tte', 0))),
                timer: null,
                autoHideTimeout: _.get(args, 'videoSelectionToolTipAutoHideTimeout', 8000)
            },
            classNames: {
              oldButton: 'feeds-asset',
              button: 'other-feeds',
              tooltip: 'other-feeds-tool-tip'
            }
        };

        this._lastVideoSegmentId = null;

        // keep track of player listeners so we can remove them later
        this._attachedListeners = [];

        this.__resolvePlayerReady = null;
        this.__rejectPlayerReady = null;
        this.__resolvePlayerRendered = null;
        this.__rejectPlayerRendered = null;

    }
    destroy() {
        if (this._videoplayer) {
            this.detachPlayerListeners();
            try {
                this._videoplayer.destroy();
            } catch (error) {
                // explicitly do nothing
            }
        }
        this._videoplayer = null;
        this._restorePlaylist = null;
    }
    /**************************************************************************
        Local Storage
    **************************************************************************/
    getStateFromLocalStorage() {
        return _.get((JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_KEY_MAIN)) || {}), this._storageKey, {});
    }
    saveStateToLocalStorage() {
        localStorage.setItem(constants.LOCAL_STORAGE_KEY_MAIN, JSON.stringify(_.extend((JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_KEY_MAIN)) || {}), {
            [this._storageKey]: this._state
        })));
    }

    /**************************************************************************
        Platform/Device
    **************************************************************************/
    getVideoPlatform() {
        return _.get(_.get(window, 'OATH', _.get(window, 'YAHOO')), 'VideoPlatform', null);
    }
    getDeviceType() {
        return this._dataManager.getCommonParams().dev_type || 'desktop';
    }

    /**************************************************************************
        Player
    **************************************************************************/
    /**
     * MUST: only support UP (ie, no fireball support)
     */
    async constructPlayer(playerConfig) {
        playerConfig = playerConfig || this._playerConfig;
        const logName = 'constructPlayer';
        // console.log(logName, 'playerConfig', playerConfig);

        // CRITICAL: This promise blocks not only the creation of the player
        // but also waits until both the PLAYER_READY and PLAYER_RENDERED
        // events have been emitted by the player itself.
        // We MUST wait for this delayed signal to proceed with the program if
        // we are managing the player instance.
        const playerReadyPromise = new Promise((resolve, reject) => {
            this.__resolvePlayerReady = async (playerInstance) => {
                try {
                    await this.renderPlayer(playerInstance, this._getActiveContainerNode());
                    resolve(playerInstance);
                } catch (error) {
                    reject(error);
                }
            };
            this.__rejectPlayerReady = (error) => {
                reject(error);
            };
        });

        if (this._videoplayer) {
            this.__resolvePlayerReady(this._videoplayer);
        } else {
            const videoPlatform = this.getVideoPlatform();
            if (videoPlatform) {
                if (playerConfig) {
                    try {
                        try {
                            this._updatePlayerReferences(await this.buildPlayer(videoPlatform, playerConfig));
                        } catch (error) {
                            this.__rejectPlayerReady(error);
                        }
                    } catch (error) {
                        this.__rejectPlayerReady(error);
                    }
                } else {
                    this.__rejectPlayerReady('playerConfig missing');
                }
            } else {
                this.__rejectPlayerReady('videoPlatform missing');
            }
        }

        return playerReadyPromise;
    }
    /**
     * 
     */
    buildPlayer(videoPlatform, playerConfig) {
        const logName = 'buildPlayer';
        let result = null;
        let resultType = 'resolve';

        playerConfig = Object.assign({}, playerConfig, {
            autoplay: true,
            // The 3 settings for loop, continuousPlay and videoRecommendations
            // work in concert to provide us a player that will loop the existing
            // playlist but will NOT fetch recommendations.
            // CAUTION: In order to use the 'setLoop' and 'setContinuousPlay'
            // methods later the 'loop' and 'continuousPlay' must be declared in
            // the playerConfig during construction, the value MSUT be
            // true/false.
            // NOTE: There are no getters for the 'setLoop' and
            // 'setContinuousPlay' methods.
            // REQUIRED
            loop: false,
            // REQUIRED
            continuousPlay: false,
            // REQUIRED
            // this always needs to be false when we are active
            videoRecommendations: false,
            // playlist: {
            //     mediaItems: []
            // }
        });

        try {
            result = new videoPlatform.VideoPlayer(playerConfig);
            this.debug.log(logName, 'player instance created');
        } catch (error) {
            result = error;
            resultType = 'reject';
        }

        return Promise[resultType](result);
    }
    /**
     * 
     */
    renderPlayer(playerInstance, containerNode) {
        playerInstance = playerInstance || this._videoplayer;
        containerNode = containerNode || this._getActiveContainerNode();
        const isRendered = playerInstance.isRendered();

        // CRITICAL: This promise blocks not only the creation of the player
        // but also waits until the PLAYER_RENDERED event has been emitted by
        // the player itself. We MUST wait for this delayed signal to proceed
        // with the program if we are managing the player instance.
        const playerRenderedPromise = new Promise((resolve, reject) => {
            this.__resolvePlayerRendered = (playerInstance) => {
                resolve(playerInstance);
            };
            this.__rejectPlayerRendered = (error) => {
                reject(error);
            };
        });

        if (playerInstance) {
            if (!isRendered) {
                if (containerNode) {
                    playerInstance.render(containerNode);
                } else {
                    this.__rejectPlayerRendered('container missing');
                }
            } else {
                this.__rejectPlayerRendered('already rendered');
            }
        } else {
            this.__rejectPlayerRendered('player instance missing');
        }

        return playerRenderedPromise;
    }
    _updatePlayerReferences(playerInstance) {
        const logName = '_updatePlayerReferences';
        this._videoplayer = playerInstance || this._videoplayer;
        
        // IF we are managing the player...
        // we MUST have a 'videoPlayer' reference at this point
        // if not we reject

        this._isOath = window.OATH ? true : false;

        // this.debug.log(logName, 'playerInstance',  !!this._videoplayer);
        if (this._videoplayer) {

            // update debugger with player reference
            this._Debugger.setPlayer(this._videoplayer);
            
            // attach vem listeners to player events
            this.attachPlayerListeners();

            // check for watch tokens
            const watchTokens = this._dataManager.getWatchTokens();
            this.debug.log(logName, 'watchTokens', !!_.size(watchTokens));
            if (!_.isEmpty(watchTokens)) {
                const uuid = this.getCurrentPlayingId();
                this.debug.log(logName, 'uuid', uuid);
                const video = _.find(this._dataManager.getScheduledVideos(), ['videoId' , uuid]);
                this.debug.info(logName, 'video', video);
                const videoType = utils.getVideoType(video);
                this.debug.log(logName, 'videoType', videoType);
                const watchToken = _.get(_.find(watchTokens, ['type', videoType]), 'token', null);
                this.debug.log(logName, 'watchToken', watchToken);
                if (watchToken) {
                    this._setSapiOptions(uuid, {
                        wtk: watchToken
                    });
                }
            }

            // check for container updates
            const containerNode = this._refreshContainerNode();
            this.debug.log(logName, 'containerNode', !!containerNode);
            
        }

    }
    /**
     * @param {Object} playerInstance - a VideoPlayer instance
     * @param {Boolean} preventVideoStart - if we should start in a paused state
     * @param {Boolean} renderInstance - if we should attempt to render the playerInstance. If
     * so then we also need a container to render in
     * @fires event:VIDEOPLAYER_ATTACHED
     */
    setPlayer(playerInstance, preventVideoStart=false, renderInstance=false) {
        const logName = 'setPlayer';
        let result = null;
        // "renderInstance" is an internal switch used during the
        // "waitForPlayerVideo" routine. it is sent from the setPlayer call
        // in the controller.
        this._updatePlayerReferences(playerInstance);

        if (renderInstance) {
            try {
                this.renderPlayer(this._videoplayer, this._getActiveContainerNode());
            } catch (error) {
                console.log('ERROR', error);
            }
        } else {
            this._eventBus.publish(constants.events.VIDEOPLAYER_ATTACHED, preventVideoStart);
        }

        this.updateLocation(this.getCurrentLocation());

        return result;
    }
    /**
     * @returns {Object} VideoPlayer instance
     */
    getPlayer() {
        const player = this._videoplayer;
        if (!player) {
            this.debug.info('getPlayer', 'player missing');
        }
        return player;
    }
    getPlayerConfig() {
        const player = this._videoplayer;
        let result = null;
        if (player) {
            result = player.config;
        }
        return result;
    }
    attachPlayerListeners() {
        const platform = this.getVideoPlatform();
        const playerEvents = _.get(platform, 'Events', null) || _.get(platform, 'API_Events', null);
        // console.log('attachPlayerListeners', 'playerEvents', playerEvents);
        const player = this.getPlayer();

        if (platform && player) {
            /**
             * @namespace Player
             */
            // VEMD.modules.HB7E06OC.playerInstance.__setError('player-error','S',400,600,'abcd');
            this._attachedListeners.push(player.on(playerEvents.PLAYER_ERROR, this._onPlayerError.bind(this)));
            //
            this._attachedListeners.push(player.on(playerEvents.PLAYER_READY, this._onPlayerReady.bind(this)));
            /**
             * @event PLAYBACK_ERROR
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.PLAYBACK_ERROR, this._onPlaybackError.bind(this)));
            /**
             * @event PLAYBACK_TIME_UPDATE
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.PLAYBACK_TIME_UPDATE, this._onPlaybackTimeUpdate.bind(this)));
            /**
             * @event PLAYBACK_START
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.PLAYBACK_START, this._onPlaybackStart.bind(this)));
            /**
             * @event PLAYBACK_COMPLETE
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.PLAYBACK_COMPLETE, this._onPlaybackComplete.bind(this)));
            /**
             * @event MARKERS_RECEIVED
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.MARKERS_RECEIVED, this._onMarkersReceived.bind(this)));
            /**
             * @event TIMED_METADATA_RECEIVED
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.TIMED_METADATA_RECEIVED, this._onTimedMetadataReceived.bind(this)));

            /**
             * @event DATERANGE_STARTED
             * @memberof Player
             */
             this._attachedListeners.push(player.on(playerEvents.DATERANGE_STARTED, this._onDateRangeStarted.bind(this)));
            /**
             * @event DATERANGE_ENDED
             * @memberof Player
             */
             this._attachedListeners.push(player.on(playerEvents.DATERANGE_ENDED, this._onDateRangeEnded.bind(this)));


            /**
             * @event UI_INTERACT
             * @memberof Player
             */
            this._attachedListeners.push(player.on(playerEvents.UI_INTERACT, this._onPlayerUserInterfaceInteraction.bind(this)));
            if (this._isOath) {
                /**
                 * this is a list item loading event before playback events start
                 * this event may be fired before the vem is completely initialized and listening
                 * but it gives us a way to get section information before a playback event occurs
                 * @event MEDIA_ITEM_CURRENT
                 * @memberof Player
                 */
                this._attachedListeners.push(player.on(playerEvents.MEDIA_ITEM_CURRENT, this._onMediaItemCurrent.bind(this)));
                /**
                 * this is just before the playback start event
                 * @event PLAYBACK_STARTED
                 * @memberof Player
                 */
                this._attachedListeners.push(player.on(playerEvents.PLAYBACK_STARTED, this._onPlaybackWillStart.bind(this)));
                /**
                 * this is just before the playback end event
                 * @event PLAYBACK_ENDED
                 * @memberof Player
                 */
                this._attachedListeners.push(player.on(playerEvents.PLAYBACK_ENDED, this._onPlaybackWillComplete.bind(this)));
                /**
                 * this will fire if there is an error due to playback
                 * @event PLAYER_INFO
                 * @memberof Player
                 */
                this._attachedListeners.push(player.on(playerEvents.PLAYER_INFO, this._onPlayerInfo.bind(this)));
                //
                this._attachedListeners.push(player.on(playerEvents.PLAYER_RENDERED, this._onPlayerRendered.bind(this)));
            } else {
                // fireball
                // this is a list item loading event before playback events start
                // this event may be fired before the vem is completely initialized and listening
                // but it gives us a way to get section information before a playback event occurs
                this._attachedListeners.push(player.getEventBus().subscribe('media-item-loaded', this._onMediaItemCurrent.bind(this)));
                // this is just before the playback start event
                this._attachedListeners.push(player.getEventBus().subscribe('playback-started', this._onPlaybackWillStart.bind(this)));
                // this is just before the playback end event
                this._attachedListeners.push(player.getEventBus().subscribe('playback-ended', this._onPlaybackWillComplete.bind(this)));
            }
            this.debug.log('attachPlayerListeners', 'player listeners attached');
        }
    }
    detachPlayerListeners() {
        const player = this.getPlayer();
        if (player) {
            player.removeEventListeners(this._attachedListeners);
        }
        this._attachedListeners = [];
        this.debug.log('detachPlayerListeners', 'player listeners detached');
    }
    _getActiveContainerNode() {
        return this._dataManager.getContainerNode();
    }
    _refreshContainerNode() {
        const logName = '_refreshContainerNode';
        let activeContainerNode = this._getActiveContainerNode();

        const playerNode = this._getPlayerNode();
        if (playerNode) {
            this.debug.log(logName, 'playerNode found, updating containerNode', playerNode);
            this._dataManager.setContainerNode(playerNode);
            activeContainerNode = playerNode;
        }

        this.debug.info(logName, 'activeContainerNode', activeContainerNode);

        return activeContainerNode;
    }
    _getPlayerNode() {
        const logName = '_getPlayerNode';
        let playerNode = null;
        if (this.getVideoPlatform()) {
            playerNode = document.querySelector(this._isOath ? '.vp-main' : '.yvp-main');
            if (playerNode) {
                playerNode = playerNode.parentNode.parentNode;
                this.debug.log(logName, 'playerNode', 'found', playerNode);
            } else {
                this.debug.info(logName, 'playerNode', 'missing');
            }
        } else {
            this.debug.info(logName, 'VideoPlatform', 'missing');
        }
        return playerNode;
    }

    /**
     * Toggle background playback based on video metadata
     * @method setStartInBackground
     * @param value {Boolean}
     */
    setStartInBackground(value=false) {
        const videoPlayer = this.getPlayer();
        if (videoPlayer) {
            this.debug.log('setStartInBackground', value);
            videoPlayer.setStartInBackground(value);
        }
    }

    /**************************************************************************
        Sapi

        options
            {wtk:'mock-watch-token'}

        sampled sapi call:
            https://video-api.yql.yahoo.com/v1/video/sapi/streams/64543d2c-bc2a-37f6-983f-d3fb93256302
                devtype=desktop
                offnetwork=false
                region=US
                site=frontpage
                lang=en-US
                autoplay=false
                wtk=page-mock-token-nfl
                geo-position=33.123456;-82.123456 epu=60

    **************************************************************************/
    _setSapiOptions(videoId, options) {
        const player = this.getPlayer();
        const logName = 'setSapiOptions';
        let result = true;
        if (player) {
            options = _.isEmpty(options) ? null : options;
            this.debug.log(logName, 'videoId', videoId);
            this.debug.info(logName, 'options', options);
            if (videoId && options) {
                if (_.isFunction(player.setSapiOptions)) {
                    player.setSapiOptions(videoId, options);
                } else {
                    this.debug.log(logName, 'player api missing');
                    result = false;
                }
            }
        }
        return result;
    }

    /**************************************************************************
        Location
    **************************************************************************/
    getCurrentLocation() {
        return this._locationManager.getCurrentLocation();
    }
    /**
     *
     * @param {*} location
     */
    updateLocation(location) {
        this.debug.info('updateLocation', 'location for player', location);
        if (!_.isNull(location)) {
            const player = this.getPlayer();
            if (player) {
                if (_.isFunction(player.updateGeoConfig)) {
                    player.updateGeoConfig({
                        allowGeoRequest: true,
                        geoData: location
                    });
                }
            } else {
                this.debug.log('updateLocation', 'video player missing');
            }
        }
    }

    /**************************************************************************
        Playlist
    **************************************************************************/
    /**
     * @fires event:PLAYLIST_PLAYBACK
     * @param {Object} playlist
     */
    playPlaylist(playlist) {
        const videoPlayer = this._videoplayer;
        if (videoPlayer) {
            videoPlayer.playlist.setItems(playlist);
            videoPlayer.playlist.setPosition(0);
            videoPlayer.controls.setMute(videoPlayer.controls.getMute());
            videoPlayer.controls.play();
            this._eventBus.publish(constants.events.PLAYLIST_PLAYBACK, playlist);
        }
    }
    saveCurrentPlaylist() {
        if (this._videoplayer) {
            // save current playlist in case location is denied
            this._restorePlaylist = this._videoplayer.playlist.getItems();
        }
    }
    playSavedPlaylist() {
        if (this._restorePlaylist) {
            this.playPlaylist(this._restorePlaylist);
        }
    }
    /**
     * TODO: REPLACE_ID_EXTRACTIONS
     * @param {Object} playlist
     */
    getMediaItemIdListFromPlaylist(playlist) {
        // accepts scheduled_videos or live_videos from DataManager
        const idList = [];
        _.each(playlist, (video) => {
            idList.push(video.videoId);
        });
        return idList;
    }
    /**
     *
     * @param {Object} playlist
     */
    startPlaylist(playlist) {
        let status = false;
        if (this._videoplayer) {
            status = true;
            this._videoplayer.playlist.clear();
            this.playPlaylist(this.getMediaItemIdListFromPlaylist(playlist));
        }
        return status;
    }
    emptyPlaylist(keepCurrent=false) {
        // this.debug.log('emptyPlaylist', 'keepCurrent', keepCurrent);
        if (this._videoplayer) {
            if (keepCurrent) {
                _.each(this._videoplayer.playlist.getItems(), (uuid) => {
                    if (uuid !== this._videoplayer.playlist.getCurrentItemId()) {
                        this._videoplayer.playlist.removeItemById(uuid);
                    }
                });
            } else {
                this._videoplayer.playlist.clear();
            }
        }
    }

    /**************************************************************************
        Scheduled Videos
    **************************************************************************/
    /**
     *
     * @emits event:SCH_VIDEO_PLAYBACK
     * @param {Object} video
     */
    playScheduledVideo(video) {
        video = this.normalizeMediaItem(video);
        const logName = 'playScheduledVideo';
        const videoPlayer = this._videoplayer;
        this.debug.log(logName, ('videoplayer ' + (videoPlayer ? 'found' : 'missing')));
        if (videoPlayer) {
            const currentPlayingId = this.getCurrentPlayingId();
            const currentPlayingMatchesRequested = (currentPlayingId === video.videoId);
            this.debug.log(logName, 'requested video id:', video.videoId);
            this.debug.log(logName, 'current video id:', currentPlayingId);
            
            // do this before swapping out the players playlist
            this.setStartInBackground(video?.backgroundPlay);
            
            if ((currentPlayingId && !currentPlayingMatchesRequested) || !currentPlayingId) {
                this._playAttemptId = video.videoId;
                this.debug.log(logName, 'playback attempt started', this._playAttemptId);
                try {
                    if (this._isOath) {
                        // fireball player throws some js error in this flow
                        videoPlayer.playlist.clear();	
                    }
                    /**
                     * We want to unmute before swapping playlist items because
                     * swapping playlist items causes a play event to be
                     * triggered. And if the player is muted when a play event
                     * is triggered and the player is backgrounded then swapping
                     * the playlist items will not start playing the newly
                     * added video. This is defined player behavior.
                     */
                    videoPlayer.controls.setMute(videoPlayer.controls.getMute());
                    if (this._isOath) {
                        videoPlayer.playlist.addItem(String(video.videoId));
                    } else {
                        // since we aren't calling clear in fireball player
                        // we need to make sure to use this method which will
                        // internally clear the playlist
                        videoPlayer.playlist.setItems([String(video.videoId)]);
                    }
                    videoPlayer.playlist.setPosition(0);
                } catch (error) {
                    // explicitly do nothing
                    this.debug.log(logName, 'error', error);
                    // bug in player when using setItems, addItem or addItems
                    // ReferenceError: setImmediate is not defined
                }
                // Calling setPosition on UP is also starting playback so we
                // don't need to call play here, at least for non-fireball.
                // I haven't physically tested this flow on fireball so fo now
                // keep the play control in tact for fireball only
                if (!this._isOath) {
                    videoPlayer.controls.play();
                }
                
                // As a result of this there will either be a PLAYER_INFO or a
                // PLAYBACK_STARTED event.
                this._eventBus.publish(constants.events.SCH_VIDEO_PLAYBACK, {
                    video: video,
                    alreadyLoaded: false
                });
            }

            if (currentPlayingId && currentPlayingMatchesRequested) {
                /*
                2020-09-25: EJF: NOTE:
                The player has already started with a uuid(A) and then
                setPlayer was called which triggered playing uuid(A).
                This is ok because the stream got to the user earlier. We
                just need to keep track of it.

                If the player was started with a uuid that failed, then it
                will be too late to do anything about it here. This is
                because the PLAYER_INFO event would have already fired.

                If the player was started with a uuid that succeeded then
                all of the relative playback events will have already been
                swallowed.
                */
                const isPlaying = videoPlayer.controls.isPlaying();

                if (isPlaying) {
                    this.debug.log(logName, 'playback already started');
                } else {
                    this.debug.log(logName, 'playback resuming');
                    videoPlayer.controls.play();
                }

                this._eventBus.publish(constants.events.SCH_VIDEO_PLAYBACK, {
                    video: video,
                    alreadyLoaded: true
                });
            }

        }
    }
    getCurrentPlayingScheduledVideoItem() {
        return _.find(this._dataManager.getScheduledVideos(), ['videoId', this.getCurrentPlayingId()]) || null;
    }
    getCurrentPlayingScheduledVideoMarkerType() {
        return _.get(this.getCurrentPlayingScheduledVideoItem(), 'markerType', null);
    }
    getCurrentPlayingScheduledVideoSegmentTitles() {
        return _.get(this.getCurrentPlayingScheduledVideoItem(), 'segmentTitles', {});
    }
    getCurrentPlayingScheduledVideoTitle() {
        return _.get(this.getCurrentPlayingScheduledVideoItem(), 'title', '');
    }
    /**
     *
     * @param {*} videoSegmentId
     */
    _getCurrentPlayingScheduledVideoSegmentTitle(videoSegmentId) {
        return _.get(this.getCurrentPlayingScheduledVideoSegmentTitles(), videoSegmentId, this.getCurrentPlayingScheduledVideoTitle());
    }

    /**************************************************************************
        MediaItems
    **************************************************************************/
    normalizeMediaItem(mediaItem) {
        // find identifier somehow
        let videoId = '';
        if (_.isEmpty(mediaItem)) {
            videoId = this.getCurrentPlayingId();
        } else if (_.isString(mediaItem)) {
            videoId = mediaItem;
        } else if (_.has(mediaItem, 'id') || _.has(mediaItem, 'videoId')) {
            videoId = mediaItem.id || mediaItem.videoId;
        }
        // get mediaItem metadata from player
        const mediaItemPlayer = this.getCurrentPlayingItem();
        // get mediaItem metadata from vem
        let mediaItemVem = {};
        if (mediaItem instanceof VideoMetadata) {
            mediaItemVem = mediaItem;
        } else {
            // mediaItem came from UP
            mediaItemVem = this.getCurrentPlayingScheduledVideoItem();
            if (!mediaItemVem) {
                mediaItemVem = this.getMediaItemByMediaItemId(videoId, this._dataManager.getPlaylist());
            }
        }
        // compose merged metadata
        const newMediaItem = {
            ...mediaItemPlayer,
            ...mediaItemVem,
            // make sure both identifier signatures are available
            id: videoId,
            videoId: videoId,
            // override sapi description with videoMetadata 
            description: mediaItemVem?.videoDescription || mediaItemPlayer?.description
        };
        // console.log('normalizeMediaItem', 'newMediaItem', newMediaItem);
        return newMediaItem;
    }
    getCurrentPlayingId() {
        const currentItem = this.getCurrentPlayingItem();
        return _.get(currentItem, 'id', null);
    }
    getCurrentPlayingItem() {
        if (this._videoplayer) {
            const currentItem = this._videoplayer.playlist.getCurrentItem();
            if (currentItem) {
                return currentItem;
            }
        }
        return null;
    }
    getMediaItems() {
        return this._dataManager.getPlaylist();
    }
    /**
     *
     * @param {*} id
     * @param {*} mediaItems
     */
    getMediaItemByMediaItemId(id, mediaItems) {
        return _.find(mediaItems || this.getMediaItems(), ['videoId', id]) || {};
    }
    /**
     *
     * @param {*} id
     * @param {*} mediaItems
     */
    getMediaItemIndexByMediaItemId(id, mediaItems) {
        return _.findIndex(mediaItems || this.getMediaItems(), { 'videoId': id });
    }
    /**
     *
     * @param {*} id
     * @param {*} mediaItems
     */
    getNextMediaItemByCurrentMediaItemId(id, mediaItems) {
        mediaItems = mediaItems || this.getMediaItems();
        const nextMediaItemIndex = this.getMediaItemIndexByMediaItemId(id, mediaItems) + 1;
        return mediaItems[((nextMediaItemIndex + 1) <= mediaItems.length) ? nextMediaItemIndex : 0];
    }
    /**
     * @fires External.event:onVideoSegmentChanged
     * @param {String} videoSegmentId
     */
    _updateMediaItemTitle(videoSegmentId) {
        if (videoSegmentId && (videoSegmentId !== this._lastVideoSegmentId)) {
            const player = this.getPlayer();
            if (player) {
                const currentUUID = this.getCurrentPlayingId();
                this._lastVideoSegmentId = videoSegmentId;
                const currentlyPlayingScheduledVideoSegmentTitle = this._getCurrentPlayingScheduledVideoSegmentTitle(videoSegmentId);
                player.setMediaItemTitle(currentUUID, currentlyPlayingScheduledVideoSegmentTitle);
                this._eventBus.publish(vemEvents.onVideoSegmentChanged, {
                    uuid: currentUUID,
                    segmentId: videoSegmentId,
                    segmentTitle: currentlyPlayingScheduledVideoSegmentTitle
                });
            }
        }
    }

    /**************************************************************************
        Segments/Markers
    **************************************************************************/
    /**
     *
     * @param {String} videoSegmentLabel
     */
    getUpdatedTitle(videoSegmentLabel) {
        const hasLabel = !_.isEmpty(videoSegmentLabel);
        this.debug.info('getUpdatedTitle', 'videoSegmentLabel', hasLabel ? videoSegmentLabel : 'missing');
        return hasLabel ? '' : this._getCurrentPlayingScheduledVideoSegmentTitle(videoSegmentLabel);
    }
    /**
     *
     * @param {Object} markers
     */
    _getVideoSegmentIdFromMarkers(markers) {
        this.debug.log('_getVideoSegmentIdFromMarkers');
        let label = null;
        const startTimeOfStream = this.getCurrentPlayingScheduledVideoItem().startTime.getTime();
        const scheduledVideoMarkerType = this.getCurrentPlayingScheduledVideoMarkerType();
        _.some(_.sortBy(markers[this.getCurrentPlayingId()], 'start_time'), (marker, index, list) => {
            // if the marker.start_time is < 24 hours(reasonably there won't be
            // any video longer than that but that number will most definately
            // be smaller than a timestamp), then assume it is an offset
            // otherwise assume the start_time is a timestamp
            // doing this will accomodate both the old 'start_time'(which was
            // an offset from the start_time of the stream) and the new
            // 'start_time' which is an actual timestamp
            const markerTime = (marker.start_time < 86400) ? (startTimeOfStream + marker.start_time) : marker.start_time;
            label = ((markerTime > Date.now()) && (marker.type === scheduledVideoMarkerType)) ? _.get(marker, 'label', label) : label;
            label = (!label && (marker.start_time === _.last(list).start_time)) ? _.get(marker, 'label', label) : label;
            return label;
        });
        return label;
    }
    /**
     *
     * @param {String} metadata
     */
    _getVideoSegmentIdFromMetadata(metadata) {
        return metadata.startsWith('segment:') ? metadata.replace('segment:', '') : null;
    }

    /**************************************************************************
        UI Augmentation
    **************************************************************************/
    /**
     *
     * @param {Object} liveVideos
     */
    addOtherFeedsButton(liveVideos) {
        clearInterval(this._otherFeedsButtonTimer);
        var player = this.getPlayer();
        this.debug.log('addOtherFeedsButton', 'player', !!player, 'liveVideos', !!liveVideos);
        if (player) {
            let oldFeedsAssetNode = document.getElementsByClassName(this._videoSelectionButton.classNames.oldButton)[0];
            if (oldFeedsAssetNode) {
                oldFeedsAssetNode.parentNode.removeChild(oldFeedsAssetNode);
            }
            let otherFeeds = document.getElementsByClassName(this._videoSelectionButton.classNames.button)[0];
            if (!otherFeeds) {
                otherFeeds = this._createOtherFeedsButton();
            }
            if (otherFeeds) {
                otherFeeds.style.display = liveVideos ? 'inline-block' : 'none';
            }
        } else {
            // We want to try periodically to add the button.
            // This could happen when the integration calls setPlayer in
            // response to a PLAYER_READY event which does not signify that
            // the player is attached to the DOM and ready but only that the
            // player instance itself is finished constructing.
            // Instead, setPlayer should only be called once the player is
            // attached to the DOM. This is communicated by the PLAYER_RENDERED
            // event.
            let otherFeedsButtonThreshold = 25;
            let otherFeedsButtonCounter = 0;
            this._otherFeedsButtonTimer = setInterval(function () {
                otherFeedsButtonCounter++;
                const player = this.getPlayer();
                this.debug.info('_otherFeedsButtonTimer[' + otherFeedsButtonCounter + '/' + otherFeedsButtonThreshold + ']', 'player', !!player);
                if (player) {
                    clearInterval(this._otherFeedsButtonTimer);
                    this._onPlayerRendered();
                } else {
                    if (otherFeedsButtonCounter >= otherFeedsButtonThreshold) {
                        // after 25 attempts(5 seconds) abort the attempt
                        clearInterval(this._otherFeedsButtonTimer);
                    }
                }
            }.bind(this), 200);
        }
    }
    /**
     * @fires event:SHOW_VIDEO_SELECT
     * @todo abstract all of this into the ui folder as a unique component
     */
    _createOtherFeedsButton() {
        const deviceType = this.getDeviceType();
        var customElementNode = null;
        var player = this.getPlayer();
        if (player) {
            if (this._videoSelectionButton.element) {
                customElementNode = this._videoSelectionButton.element;
            } else {
                customElementNode = player.addCustomControlElement({
                    icon: this._videoSelectionButton.icon,
                    label: this._videoSelectionButton.classNames.button,
                    className: this._videoSelectionButton.classNames.button
                });
                if (customElementNode) {
                    this._videoSelectionButton.element = customElementNode;
                    customElementNode.style.opacity = 1;
                    customElementNode.addEventListener('click', function () {
                        this._eventBus.publish(constants.events.SHOW_VIDEO_SELECT);
                    }.bind(this), false);
                    customElementNode.addEventListener('touchend', function () {
                        this._eventBus.publish(constants.events.SHOW_VIDEO_SELECT);
                    }.bind(this), false);
                    customElementNode.addEventListener('mouseover', function () {
                        this.style.opacity = 1;
                    }.bind(customElementNode), false);
                    customElementNode.addEventListener('mouseout', function () {
                        this.style.opacity = (deviceType === 'smartphone') ? 1 : 0.8;
                    }.bind(customElementNode), false);
                    if (!this._videoSelectionButton.toolTip.exhausted) {
                        var toolTipContainer = document.createElement('div');
                        toolTipContainer.className = this._videoSelectionButton.classNames.tooltip;
                        toolTipContainer.style.backgroundColor = 'black';
                        toolTipContainer.style.padding = '2px 6px';
                        toolTipContainer.style.position = 'absolute';
                        toolTipContainer.style.color = 'white';
                        toolTipContainer.style.borderRadius = '3px';
                        toolTipContainer.style.opacity = '0.6';
                        toolTipContainer.style.zIndex = 5;
                        if (deviceType === 'smartphone') {
                            toolTipContainer.style.top = '-70%';
                            toolTipContainer.style.left = '-36%';
                            toolTipContainer.style.fontSize = '80%';
                        } else {
                            toolTipContainer.style.top = '-65%';
                            toolTipContainer.style.left = '-45%';
                            toolTipContainer.style.fontSize = '55%';
                        }
                        toolTipContainer.appendChild(document.createTextNode(this._videoSelectionButton.toolTip.text));
                        customElementNode.appendChild(toolTipContainer);
                    }
                }
            }
            return customElementNode;
        }
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    /**
     * @method
     * @listens Player.event:PLAYER_READY
     * @emits External.event:onVideoplayerReady
     */
    _onPlayerReady(payload) {
        //this.debug.log('_onPlayerReady');
        this.__resolvePlayerReady(this._videoplayer);
        this._eventBus.publish(vemEvents.onVideoplayerReady, this._videoplayer);
    }
    /**
     * @method
     * @listens Player.event:PLAYER_RENDERED
     * @emits External.event:onVideoplayerRendered
     */
    _onPlayerRendered(payload) {
        //this.debug.log('_onPlayerRendered');
        this.__resolvePlayerRendered(this._videoplayer);
        this._eventBus.publish(vemEvents.onVideoplayerRendered, this._videoplayer);
    }
    /**
     * @method
     * @listens Player.event:PLAYER_INFO
     * @emits Internal.event:SCH_VIDEO_PLAYBACK_FAILED
     * @param {Object} payload
     */
    _onPlayerInfo(payload) {
        // fires and provides error info in the case of a player error
        if (this._playAttemptId) {
            this.debug.log('_onPlayerInfo', 'playback failed');
            this._eventBus.publish(constants.events.SCH_VIDEO_PLAYBACK_FAILED, {
                videoId: this._playAttemptId,
                errorMsg: _.get(payload, 'errorInfo.msg', 'reason unknown')
            });
            // unset this for use during the next play attempt
            this._playAttemptId = null;
        }
    }
    /**
     * @method
     * @listens Player.event:PLAYER_ERROR
     * @emits Internal.event:VIDEOPLAYER_ERROR
     * @param {Object} payload
     */
    _onPlayerError(error) {
        // this.debug.log('_onPlayerError', 'error', error);
        this._eventBus.publish(constants.events.VIDEOPLAYER_ERROR, {
            error: error,
            player: this._videoplayer
        });
    }
    /**
     * @method
     * @listens Player.event:PLAYBACK_ENDED
     * @emits Internal.event:PLAYBACK_WILL_COMPLETE
     * @emits External.event:onVideoWillComplete
     * @param {Object} mediaItem
     */
    _onPlaybackWillComplete(mediaItem) {
        mediaItem = this.normalizeMediaItem(mediaItem);
        // this.debug.log('_onPlaybackWillComplete', mediaItem.videoId);
        this._eventBus.publish(constants.events.PLAYBACK_WILL_COMPLETE, mediaItem);
        this._eventBus.publish(vemEvents.onVideoWillComplete, mediaItem);
    }
    /**
     * @method
     * @listens Player.event:PLAYBACK_TIME_UPDATE
     * @emits Internal.event:PLAYBACK_PROGRESS
     * @param {String} videoId
     */
    _onPlaybackTimeUpdate(videoId) {
        this._eventBus.publish(constants.events.PLAYBACK_PROGRESS, videoId);
    }
    /**
     * @method
     * @listens Player.event:PLAYBACK_COMPLETE
     * @emits Internal.event:PLAYBACK_COMPLETE
     * @emits External.event:onVideoComplete
     * @param {Object} mediaItem
     */
    _onPlaybackComplete(mediaItem) {
        mediaItem = this.normalizeMediaItem(mediaItem);
        // this.debug.log('_onPlaybackComplete', mediaItem.videoId);
        this._eventBus.publish(vemEvents.onVideoComplete, mediaItem);
        this._eventBus.publish(constants.events.PLAYBACK_COMPLETE, mediaItem);
    }
    /**
     * @method
     * @listens Player.event:PLAYLIST_COMPLETE
     * @emits Internal.event:PLAYLIST_COMPLETE
     * @emits External.event:onPlaylistComplete
     * @todo this doesn't seem to be wired up to anything
     */
    _onPlaylistComplete() {
        //this.debug.log('_onPlaylistComplete');
        this._eventBus.publish(vemEvents.onPlaylistComplete);
        this._eventBus.publish(constants.events.PLAYLIST_COMPLETE);
    }
    /**
     * @method
     * @listens Player.event:PLAYBACK_ERROR
     * @emits Internal.event:PLAYBACK_ERROR
     * @param {Object} error
     */
    _onPlaybackError(error) {
        //this.debug.log('_onPlaybackError:' + JSON.stringify(error));
        const video = this.getCurrentPlayingItem();
        this._eventBus.publish(constants.events.PLAYBACK_ERROR, {video: video, error: error});
    }
    /**
     * @method
     * @listens Player.event:PLAYBACK_STARTED
     * @emits Internal.event:PLAYBACK_WILL_START
     * @emits External.event:onVideoWillStart
     * @param {Object} mediaItem
     */
    _onPlaybackWillStart(mediaItem) {
        // fires when an item is about to play
        // fires before onPlaybackStart
        mediaItem = this.normalizeMediaItem(mediaItem);
        this.debug.log('_onPlaybackWillStart', mediaItem.videoId);
        // unset this for use during the next play attempt
        this._playAttemptId = null;
        this._eventBus.publish(constants.events.PLAYBACK_WILL_START, mediaItem);
        this._eventBus.publish(vemEvents.onVideoWillStart, mediaItem);
    }
    /**
     * @method
     * @listens Player.event:PLAYBACK_START
     * @emits Internal.event:PLAYBACK_START
     * @emits External.event:onVideoStart
     * @param {Object} mediaItem
     */
    _onPlaybackStart(mediaItem) {
        mediaItem = this.normalizeMediaItem(mediaItem);
        this.debug.log('_onPlaybackStart', mediaItem.videoId);
        this._eventBus.publish(constants.events.PLAYBACK_START, mediaItem);
        this._eventBus.publish(vemEvents.onVideoStart, mediaItem);
    }
    /**
     * @method
     * @listens Player.event:MEDIA_ITEM_CURRENT
     * @emits Internal.event:MEDIA_ITEM_CURRENT
     * @emits External.event:onMediaItemCurrent
     * @param {Object} mediaItem
     */
    _onMediaItemCurrent(mediaItem) {
        mediaItem = this.normalizeMediaItem(mediaItem);
        // this.debug.log('_onMediaItemCurrent', mediaItem.videoId);
        this._eventBus.publish(constants.events.MEDIA_ITEM_CURRENT, mediaItem);
        this._eventBus.publish(vemEvents.onMediaItemCurrent, mediaItem);
        this._lastVideoSegmentId = null;
    }
    /**
     * @method
     * @listens Player.event:DATERANGE_STARTED
     * @emits External.event:onDateRangeStarted
     * @param {Object} markers
     */
     _onDateRangeStarted(payload) {
        const states = {
            PRE: 'PRE',
            LIVE: 'LIVE',
            POST: 'POST',
            ARCHIVE: 'ARCHIVE',
            NO_ARCHIVE: 'NO_ARCHIVE'
        };
        const stateTag = 'X-LIVE-STATE';
        const dataTag = 'rawData';
        let state = null;
        if (dataTag in payload) {
            let data = null;
            try {
                data = JSON.parse(payload[dataTag]);
            } catch (error) {
                // intentionally falling through
            }
            if (stateTag in data) {
                state = data[stateTag]
            }
        }
        const videoId = this.getCurrentPlayingId();
        const scheduleVideos = this._dataManager.getScheduledVideos();
        this.debug.log('_onDateRangeStarted', 'state:' + state, 'videos[' + scheduleVideos.length + '] current:' + videoId);
        switch (state) {
            case states.POST:
            case states.ARCHIVE:
            case states.NO_ARCHIVE:
                if (scheduleVideos) {
                    const currentScheduledVideo = scheduleVideos.find(video => video.videoId === videoId);
                    if (currentScheduledVideo) {
                        this.debug.log('_onDateRangeStarted', stateTag + '(' + state + ') received, terminating current stream');
                        this._onPlaybackComplete(this.getCurrentPlayingItem(), true);
                    }
                }
                break;
        }
        this._eventBus.publish(vemEvents.onDateRangeStarted, payload);
    }
    /**
     * @method
     * @listens Player.event:DATERANGE_ENDED
     * @emits External.event:onDateRangeEnded
     * @param {Object} markers
     */
     _onDateRangeEnded(payload) {
        this._eventBus.publish(vemEvents.onDateRangeEnded, payload);
    }
    /**
     * @method
     * @listens Player.event:MARKERS_RECEIVED
     * @emits External.event:onMarkersReceived
     * @param {Object} markers
     */
    _onMarkersReceived(markers) {
        this.debug.log('_onMarkersReceived', markers);
        this._eventBus.publish(vemEvents.onMarkersReceived, markers);
        this._updateMediaItemTitle(this._getVideoSegmentIdFromMarkers(markers));
    }
    /**
     * @method
     * @listens Player.event:TIMED_METADATA_RECEIVED
     * @param {String} metadata
     */
    _onTimedMetadataReceived(metadata) {
        this._updateMediaItemTitle(this._getVideoSegmentIdFromMetadata(metadata));
        // start checking if current metadata matches "eos_marker"
        if (!this._dataManager || !metadata) {
            return;
        }
        const scheduleVideos = this._dataManager.getScheduledVideos();
        const videoId = this.getCurrentPlayingId();
        if (scheduleVideos) {
            const currentScheduledVideo = scheduleVideos.find(video => video.videoId === videoId);
            let normalizedMetadata = metadata.replace('segment:', '');
            const controlCharacter = normalizedMetadata.search(/\u0000/);
            if (controlCharacter > 0) {
                normalizedMetadata = normalizedMetadata.substring(0, controlCharacter);
            }
            if (currentScheduledVideo && currentScheduledVideo.eosMarker && (currentScheduledVideo.eosMarker === normalizedMetadata)) {
                this.debug.log('eos marker for current scheduled video: \'' + currentScheduledVideo['eosMarker'] + '\'');
                this.debug.log('end of stream marker received. Will terminate current stream');
                this._onPlaybackComplete(this.getCurrentPlayingItem(), true);
            }
        }
    }
    /**
     * @method
     * @param {Object} payload
     */
    _onPlayerUserInterfaceInteraction(payload) {
        // if we've never showmn the tooltip
        if (!this._videoSelectionButton.toolTip.exhausted) {
            const deviceType = this.getDeviceType();
            let gamePickerToolTip = document.getElementsByClassName(this._videoSelectionButton.classNames.tooltip);
            if (deviceType === 'smartphone') {
                if (gamePickerToolTip) {
                    if (payload.srcElement === 'video-click') {
                        this._videoSelectionButton.toolTip.surfacedCount++;
                    }
                    // hide after N views
                    if (this._videoSelectionButton.toolTip.surfacedCount >= this._videoSelectionButton.toolTip.surfacedLimit) {
                        // hide after N seconds
                        this._videoSelectionButton.toolTip.timer = setTimeout(function () {
                            if (this._videoSelectionButton.toolTip.timer) {
                                clearTimeout(this._videoSelectionButton.toolTip.timer);
                            }
                            let gamePickerToolTip = document.getElementsByClassName(this._videoSelectionButton.classNames.tooltip)[0];
                            if (gamePickerToolTip) {
                                gamePickerToolTip.style.display = 'none';
                            }
                            this._videoSelectionButton.toolTip.exhausted = true;
                            this._state.tte = Number(this._videoSelectionButton.toolTip.exhausted);
                            this.saveStateToLocalStorage();
                        }.bind(this), this._videoSelectionButton.toolTip.autoHideTimeout);
                    }
                }
            }
        }
    }
}

export {
    PlayerManager
};
