import _ from 'lodash';

import { Prompt } from './prompt';

import './styles/prompt.scss';

class PromptUser extends Prompt {
    /**
     * @class PromptUser
     * @classdesc 
     * @param {Object}    args - Constructor args object that contains the following:
     */
    constructor(args) {
        super(args, 'PromptUser');

    }

}

export {
    PromptUser
};
