import _ from 'lodash';
import fetch from 'cross-fetch';

import vemEvents from '../lib/events';
import constants from '../lib/constants';
import { BaseManager } from '../lib/base';

class NetworkingManager extends BaseManager {
    /**
     * @class NetworkingManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'NetworkingManager');
        
        this._channelId = _.get(args, 'channelId', '');
        this._playlistId = _.get(args, 'playlistId', '');
        this.commonParams = _.get(args, 'commonParams', {});
        this.sessionId = _.get(args, 'sessionId', null);
        this._debugId = _.get(args, 'debugId', null);
        this._runMode = _.get(args, 'runMode', constants.runModes.CLIENT);
        this.nextFetchTime = 0;
        this._defaultSiteParam = 'sports';
        this._isFetchingVideoExperiences = false;
        this._isFetchingRemoteConfig = false;
        this._countFetchVideoExperiences = 0;
        this._countFetchRemoteConfig = 0;
        this._countFetchRemoteConfigThreshold = 2;
        this._testVideoGroup = _.get(args, 'testVideoGroup', '');
        this._preTest = _.get(args, 'preTest', '');
        this._zipWoeid = _.get(args, 'zipWoeid', null);

        this._locationManager = _.get(args, 'locationManager', null);
        this._configManager = _.get(args, 'configManager', null);
        this._stateManager = _.get(args, 'stateManager', null);

        this._eventBus.subscribe(constants.events.STATE_CHANGE, this._onStateChanged.bind(this));

    }
    destroy() {
        this.resetTimer();
        this.nextFetchTime = 0;
        this._channelId = null;
    }

    setNextFetchTime(nextFetchTime=0) {
        this.nextFetchTime = (this._runMode === constants.runModes.SERVER) ? 0 : nextFetchTime;
        this.debug.log('nextFetchTime', ((this._runMode === constants.runModes.SERVER) ? '- disabled on server' : this.nextFetchTime));
    }
    getNextFetchTime() {
        return _.get(this, 'nextFetchTime', 0);
    }
    setCommonParams(params) {
        this.commonParams = params || {};
    }
    getCommonParams() {
        return this.commonParams;
    }
    setTestVideoGroup(group) {
        this._testVideoGroup = String(group);
    }
    getTestVideoGroup() {
        return this._testVideoGroup;
    }
    _buildScheduleUrl(baseUrl, cid, playlistId, location, gameId, teamIds) {
        const scheduleUrl = new URL(baseUrl);
        scheduleUrl.searchParams.append('cid', cid);
        if (playlistId) {
            scheduleUrl.searchParams.append('playlist_uuid', playlistId);
        }
        if (location) {
            scheduleUrl.searchParams.append('latitude', location.latitude);
            scheduleUrl.searchParams.append('longitude', location.longitude);
        }
        if (gameId) {
            scheduleUrl.searchParams.append('game_id', gameId);
        }
        if (teamIds) {
            scheduleUrl.searchParams.append('team_ids', teamIds);
        }
        if (this.commonParams) {
            _.each(this.commonParams, (value, key) => {
                scheduleUrl.searchParams.append(key, value);
            });
        }
        // If these do not match then there has been an sid debug override
        // using the qs param vemdid. In this case we do not want to send the
        // sessionId in the request. We don't want to have a revolving
        // sessionId when debugging as it makes it difficult to replace the
        // response using a proxy.
        if (_.isNull(this._debugId)) {
            scheduleUrl.searchParams.append('session_id', this.sessionId);
        }
        if (this._testVideoGroup) {
            scheduleUrl.searchParams.append('test_video_group', this._testVideoGroup);
        }
        if (this._preTest) {
            scheduleUrl.searchParams.append('pre_test', this._preTest);
        }
        if (this._zipWoeid) {
            scheduleUrl.searchParams.append('zip_woeid', this._zipWoeid);
        }
        return scheduleUrl.toString();
    }
    _buildConfigUrl(baseUrl, channelId) {
        return _.replace(_.replace(baseUrl, '$site', _.get(this, 'commonParams.site', this._defaultSiteParam)), (channelId ? '$cid' : '$cid/'), (channelId || ''));
    }
    _normalizeUrl(inputUrl) {
        let url = null;
        if (inputUrl) {
            const tmpURL = new URL(inputUrl);
            url = tmpURL.protocol + '//' + tmpURL.host + tmpURL.pathname;
        }
        return url;
    }

    /**************************************************************************
        Scheduling
    **************************************************************************/
    resetTimer() {
        if (this._refetchTimer) {
            clearTimeout(this._refetchTimer);
        }
        this._refetchTimer = null;
    }
    scheduleRefetch(period) {
        this.resetTimer();
        if (this._runMode === constants.runModes.CLIENT) {
            this._refetchTimer = setTimeout(this.fetchVideoExperiences.bind(this), period);
        }
    }
    _scheduleRefetch(date) {
        let nextFetchAfter = date;
        if (_.isEmpty(nextFetchAfter)) {
            const nextFetchTime = this.getNextFetchTime();
            if (nextFetchTime > 0) {
                nextFetchAfter = nextFetchTime.getTime() - Date.now();
            }
        }
        if (nextFetchAfter > 0) {
            this.scheduleRefetch(nextFetchAfter);
        }
    }

    /**************************************************************************
        Data Retrieval/Processing
    **************************************************************************/
    /**
    * @method fetchVideoExperiences
    * @param options
    * @param options.gameId
    * @returns void
    */
    async fetchVideoExperiences(options) {
        const logName = 'fetchVideoExperiences';
        if (!_.includes([constants.states.PAUSED], this._stateManager.getState())) {
            if (!this._isFetchingVideoExperiences) {
                /**
                The constructor accepts a property called 'scheduleEndpoint'.
                If this property exists we store it in the ConfigManager and
                use it later to fetch schedule data. The problem is that some
                integrations may send in a fully qualified URL while others
                only send in protocol/host/path. This normalizes the URL to
                just protocol/host/path so we can re-construct it below.
                */
                const baseUrl = this._normalizeUrl(this._configManager._scheduleEndpoint);
                if (baseUrl) {
                    const location = this._locationManager.getCurrentLocation();
                    const gameId = _.get(options, 'gameId', null);
                    if (gameId) {
                        this.debug.log(logName, 'gameId', gameId);
                    }
                    const url = this._buildScheduleUrl(baseUrl, this._channelId, this._playlistId, location, gameId);
                    this.debug.log(logName, url);
                    try {
                        this._isFetchingVideoExperiences = true;
                        this._countFetchVideoExperiences++;
                        const start = Date.now();
                        const response = await fetch(url);
                        // this.debug.log(logName, 'response', response);
                        if (response.status >= 400) {
                            throw response;
                        }
                        this.debug.log(logName, 'resolved');
                        const data = await response.json();
                        // this.debug.log(logName, 'data', data);
                        this._eventBus.publish(vemEvents.onFetch, {
                            url: url,
                            start: start,
                            end: Date.now()
                        });
                        this._isFetchingVideoExperiences = false;
                        this._eventBus.publish(constants.events.FETCH_VIDEO_EXPERIENCES_RESOLVED, {
                            type: logName,
                            data: data,
                            url: url,
                            location: location,
                            status: response.status,
                            statusText: response.statusText
                        });
                    } catch (error) {
                        this._isFetchingVideoExperiences = false;
                        this._eventBus.publish(constants.events.FETCH_VIDEO_EXPERIENCES_REJECTED, {
                            type: logName,
                            data: '',
                            url: url,
                            location: location,
                            status: error.status,
                            statusText: error.statusText
                        });
                    }
                } else {
                    this.debug.log(logName, 'baseUrl not available');
                }
            } else {
                this.debug.log(logName, 'already fetching');
            }
        } else {
            this.debug.log(logName, 'sleeping');
        }
    }
    /**
    * @method fetchRemoteConfig
    * @returns Promise
    */
    async fetchRemoteConfig() {
        const logName = 'fetchRemoteConfig';
        let baseUrl = constants.VE_MODULE_REMOTE_CONFIG_URL;
        let responseType = 'reject';
        let responsePayload = '';

        if (!this._isFetchingRemoteConfig) {
            if (baseUrl) {
                const location = this._locationManager.getCurrentLocation();
                const retry = this._countFetchRemoteConfig ? null : this._channelId;
                const url = this._buildConfigUrl(baseUrl, retry);
                this.debug.log(logName, url);
                try {
                    this._isFetchingRemoteConfig = true;
                    this._countFetchRemoteConfig++;
                    const start = Date.now();
                    const response = await fetch(url);
                    // console.log('-----', logName, 'response', response);
                    if (response.status >= 400) {
                        throw response;
                    }
                    this.debug.log(logName, 'resolved');
                    const data = await response.json();
                    // this.debug.log(logName, 'data', data);
                    this._eventBus.publish(vemEvents.onFetch, {
                        url: url,
                        start: start,
                        end: Date.now()
                    });
                    this._countFetchRemoteConfig = 0;
                    this._isFetchingRemoteConfig = false;
                    responsePayload = {
                        type: logName,
                        data: data,
                        url: url,
                        location: location,
                        status: response.status,
                        statusText: response.statusText
                    };
                    responseType = 'resolve';
                    this._eventBus.publish(constants.events.FETCH_REMOTE_CONFIG_RESOLVED, responsePayload);
                } catch (error) {
                    // console.dir(error);
                    this._isFetchingRemoteConfig = false;
                    responsePayload = {
                        type: logName,
                        data: '',
                        url: url,
                        location: location,
                        status: error.status,
                        statusText: error.statusText
                    };
                    if (this._countFetchRemoteConfig < this._countFetchRemoteConfigThreshold) {
                        this.debug.log(logName, 'making another attempt');
                        responsePayload = await this.fetchRemoteConfig();
                        responseType = 'resolve';
                    } else {
                        this._eventBus.publish(constants.events.FETCH_REMOTE_CONFIG_REJECTED, responsePayload);
                    }
                }
            } else {
                responsePayload = 'configUrl not available';
            }
        } else {
            responsePayload = 'already fetching';
        }

        return Promise[responseType](responsePayload);
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onStateChanged(payload) {
        const states = constants.states;
        const currentState = _.get(payload, 'current', null);
        switch (currentState) {
            case states.PAUSED:
            case states.INSTANT_APP:
            case states.SPORTS_APP:
            case states.REQUEST_LOCATION:
                this.debug.log('clear fetch timer');
                this.resetTimer();
                break;
        }
    }

}

export {
    NetworkingManager
};
