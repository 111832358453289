import _ from 'lodash';

import { BaseManager } from '../lib/base';

import { configs } from '../lib/config';
import constants from '../lib/constants';

class ThemeManager extends BaseManager {
    /**
     * @class ThemeManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     * @param {String}    args.theme='nfl'
     */
    constructor(args) {
        super(args, 'ThemeManager');
        
        this._theme = _.get(args, 'theme', null);
        this._configs = configs;
        /*
        viewportCover
            true: default, use theme background
            false: use first available thumbnail_url from stream metadata
            String: use input as value
        */
        this._viewportCover = _.get(args, 'viewportCover', true);

        /***
         * Extend the cache timeout of these AWS assets
         */
        this.assets = [
            'NFL_Generic%401x.png',
            'nfl_2021_logo_258x288_instant-app.png',
            'nfl_2021_background_375x213_instant-app.png',
            'NFL_2019_small.png',
            '2020-stadium-375x211.png',
            'NBA_2020_logo_196x64.png',
            'nba_2020_background_1220x688_stadium.png',
            'NFL_Generic%401x.png'
        ];
        this.assetContainerId = 'vem-preload-asset-container';

        this._eventBus.subscribe(constants.events.UPDATE_THEME, this._onThemeUpdated.bind(this));
    }
    /**
     * Takes in a string, as a single param, in the format <theme>_<id> or it
     * can accept both an id and theme.
     * @param {String} id - if no theme is provided then it is assumed that the
     *                      string has already been combined with the theme
     * @param {String} theme 
     * @returns {Object} theme
     * @example
     * ```
     * // this would default to using the global 'nfl' theme since none was
     * // provided
     * ThemeManager.get('request_location');
     * // to be explicit you can provide a theme
     * ThemeManager.get('request_location', 'nfl');
     * // you can also provide the entire key already combined
     * ThemeManager.get('nfl_request_location');
     * ```
     */
    get(id, theme) {
        this.debug.info('get', 'id', id);
        this.debug.info('get', 'theme', theme);
        let lookup = null;
        switch (_.size(arguments)) {
            case 0:
                lookup = this._theme;
                break;
            case 1:
                lookup = id;
                break;
            case 2:
                lookup = theme + '_' + id;
                break;
        }
        return _.get(this._configs, lookup, {});
    }
    setTheme(theme) {
        this._theme = theme;
        return theme;
    }
    getTheme() {
        return this._theme;
    }
    /**
     * @returns String
     */
    getViewportCover() {
        let cover = false;
        if (typeof this._viewportCover === 'string') {
            cover = this._viewportCover;
        } else if (this._viewportCover) {
            cover = this.get().background || '';
        }
        return cover;
    }
    /**
     * Apply values(based on a theme) to incoming payload
     * @param {Object} payload
     * @param {String} payload.id - the id which maps to a subset of theme
     *                              configs
     * @param {String} payload.theme - nfl, nbs
     * @returns {Object} incoming payload with theme applied on-top
     * @example
     * ```
     * - 'id' should match a theme definition of '<theme>_<id>'
     * - if 'id' is missing then the existing 'actionName' will be used
     * - if the provided 'id' is not an existing Alert.ACTION_NAMES
     *   then it should define an action.name in it's theme definition
     * - theme definition are located in 'src/lib/config'
     * ```
     */
    applyTheme(payload={}) {
        const theme = payload.theme || this._theme;
        const id = payload?.themeId || payload?.id || payload?.actionName;
        const themeDefault = this.get(id);
        const themeCustom = this.get(id, theme);
        return _.assign({}, _.assign({}, payload), _.assign({}, themeDefault, themeCustom, {
            theme: theme,
            id: id
        }));
    }
    /**
     * 
     */
    _onThemeUpdated(theme) {
        this._theme = theme || this._theme;
    }
    /***
     * @method preLoadAssets
     */
    preLoadAssets() {
        this.debug.log('pre loading assets');
        const bodyElements = document.getElementsByTagName('body');
        const bodyElement = bodyElements[0];
        const loadingContainer = document.createElement('div');
        loadingContainer.id = this.assetContainerId;
        loadingContainer.setAttribute('style', 'position: absolute; width: 0; height: 0; overflow: hidden; z-index: -1; visibility: hidden;');
        let style = 'content:';
        this.assets.forEach((asset) => {
            style += 'url(' + constants.VE_MODULE_CDN_ASSETS + asset + ')';
        });
        style += ';';
        loadingContainer.setAttribute('style', style);
        bodyElement.appendChild(loadingContainer);
    }
}

export {
    ThemeManager
};
