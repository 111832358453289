import _ from 'lodash';

import { utils } from '../lib/utils';

class Alert {
    /**
     * @class Alert
     * @classdesc
     * @description Takes in the alert portion of a schedule api response and produces an alert object with the following structure. Or, takes in a structured payload and produces a prompt object with the following structure.
     * @param {Object} json
     * @param {String} json.id                 - An identifier that will be passed back to the caller with the ack msg
     * @param {String} json.theme              - theme
     * @param {String} json.type               - type of alert [One of 'Chyron', 'Other']
     * @param {String} json.actionName         - actionName for the alert[One of 'UserPrompt']
     * @param {String} json.actionTrigger      - user action [One of 'tap']
     * @param {String} json.title              - Title of the alert e.g. 'Coming Up Next!'
     * @param {String} json.subtitle           - Subtitle for the alert
     * @param {String} json.description        - Description for the alert
     * @param {String} json.logo               - Logo for the alert
     * @param {String} json.buttonTitle        - Title for the button on the alert
     * @param {String} json.backgroundImage    - backgroundImage for the alert
     * @param {String} json.duration           - how long should the prompt stay visible
     * @param {String} json.ipLocation         - ???
     * @param {String} json.startTime          - ???
     * @param {String} json.gradientStartColor - ???
     * @param {String} json.gradientEndColor   - ???
     * @param {String} json.waitForLocationMessage - ???
     * @param {String} json.enableLocationMessage   - ???
     */
    constructor(json) {
        /***
         * The 'json.id' is used as 'ackId' in the AlertPrompt. It's also used
         * as 'themeId' in the ThemeManager. However, 'this.id' itself is
         * internal only and can be anything.
         */
        this.type = _.get(json, 'data.type', Alert.TYPES.OTHER);
        /***
         * Internal id, not really used for anything
         * @property {String} id
         */
        this.id = json?.id || utils.generateSessionId();
        /***
         * Used for comparison against condition requirements
         * @property {String} actionName
         */
        this.actionName = json?.action?.name || (_.includes(Alert.ACTION_NAMES, this.id) ? this.id : Alert.ACTION_NAMES.PromptUser);
        /***
         * Identifier used by the ThemeManager to retrieve them configs
         * @property {String} themeId
         */
        this.themeId = json?.id || this.actionName;
        /***
         * Used by the ThemeManager as a predicator when retrieving theme
         * configs.
         * @property {String} theme
         */
        this.theme = _.get(json, 'theme', null);
        this.actionTrigger = _.get(json, 'action.trigger', Alert.ACTION_TRIGGERS.TAP);
        this.title = _.get(json, 'data.title', '');
        this.subtitle = _.get(json, 'data.subtitle', '');
        this.description = _.get(json, 'data.description', '');
        this.logo = _.get(json, 'data.logo', '');
        this.button = _.get(json, 'data.button', '');
        this.background = _.get(json, 'data.background', '');
        this.duration = _.get(json, 'data.duration', 0);
        this.ipLocation = _.get(json, 'ip_location', false);
        this.startTime = utils.convertStrToDate(_.get(json, 'data.date', 0));
        this.gradientStartColor = _.get(json, 'ui.gradient_start_color', '');
        // TODO: fixed in ve-module-feed/pull/114 but can't be deployed until
        // all VEM customers are updated to handle both, then the server can be
        // updated and only then can this be fully corrected and the misspelled
        // word be removed.
        this.gradientEndColor = _.get(json, 'ui.gradient_end_color', _.get(json, 'ui.grandient_end_color', ''));

    }
}
/**
 * @static ACTION_NAMES
 */
Alert.ACTION_NAMES = {
    RequestLocation: 'request_location',
    RequestNielsen: 'request_nielsen',
    CustomAction: 'custom',
    LaunchSports: 'launch_sports',
    PromptUser: 'prompt_user',
    RequireInstantApp: 'require_instant_app',
    RequireSportsApp: 'require_sports_app'
};
/**
 * @static TYPES
 */
Alert.TYPES = {
    CHYRON: 'chyron',
    OTHER: 'other',
    PROMPT: 'prompt'
};
/**
 * @static ACTION_TRIGGERS
 */
Alert.ACTION_TRIGGERS = {
    AUTO: 'auto',
    TAP: 'tap'
};

export {
    Alert
};
