import _ from 'lodash';

import constants from '../lib/constants';
import vemEvents from '../lib/events';
import { BaseManager } from '../lib/base';
import { RemoteConfig } from '../models/remote-config';

class ConfigManager extends BaseManager {
    /**
     * @class ConfigManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'ConfigManager');
        
        this._lastResponse = {};
        this._lastError = null;

        this._configSignature = _.get(args, 'configSignature', null);
        this._loggerEndpoint = _.get(args, 'loggerEndpoint', null);
        this._scheduleEndpoint = _.get(args, 'scheduleEndpoint', null);

        this._eventBus.subscribe(constants.events.FETCH_REMOTE_CONFIG_RESOLVED, this._onFetchRemoteConfigResolved.bind(this));
        this._eventBus.subscribe(constants.events.FETCH_REMOTE_CONFIG_REJECTED, this._onFetchRemoteConfigRejected.bind(this));
        
    }
    /**
     * @listens event:FETCH_REMOTE_CONFIG_RESOLVED
     * @fires event:CONFIG_UPDATED
     * @param {*} payload 
     */
    _onFetchRemoteConfigResolved(payload) {
        // this.debug.log('_onFetchRemoteConfigResolved', 'payload', payload);
        this._lastResponse = new RemoteConfig(payload?.data);
        // this.debug.log('_onFetchRemoteConfigResolved', 'this._lastResponse', this._lastResponse);
        if (this._lastResponse) {
            this._configSignature = _.get(payload, 'url', null);
            this._scheduleEndpoint = _.get(this._lastResponse, 'scheduleUrl', null);
            this._loggerEndpoint = _.get(this._lastResponse, 'logUrl', null);
            this._eventBus.publish(vemEvents.onRemoteConfigUpdated);
        }
    }
    /**
     * @listens event:FETCH_REMOTE_CONFIG_REJECTED
     * @param {*} payload 
     */
    _onFetchRemoteConfigRejected(payload) {
        // this.debug.log('_onFetchRemoteConfigRejected', 'payload', payload);
        this._lastError = payload;
        this._eventBus.publish(constants.events.DATA_ERROR, payload);
    }
}

export {
    ConfigManager
};
