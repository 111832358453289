import _ from 'lodash';

import constants from '../lib/constants';
import { utils } from '../lib/utils';

import { Chyron } from './chyron';

import './styles/location-chyron.scss';

class LocationChyron extends Chyron {
    /**
     * @class LocationChyron
     * @classdesc 
     * @extends Chyron
     * @param {Object}    args - Constructor args object that contains the following:
     */
    constructor(args) {
        super(args, 'LocationChyron');

        this._type = 'location';
        this._template = require('./templates/location-chyron.html');
        
        this._classNames = {
            container: '.ve-loc-chyron',
            title: '.ve-loc-title',
            subtitle: '.ve-loc-subtitle',
            button: '.ve-loc-button'
        };

    }
    render() {
        const alert = this._alert;
        return utils.render(this.getTemplate(), {
            logo: alert.logo,
            title: alert.title.toUpperCase(),
            subtitle: alert.subtitle,
            button: alert.button,
            background: alert.background
        });
    }
    update(alert) {
        super.update.call(this, alert);

        const containerDiv = this._node.querySelector(this._classNames.container);
        if (containerDiv) {
            containerDiv.style.background = alert.background;
        }
        const buttonDiv = this._node.querySelector(this._classNames.button);
        if (buttonDiv) {
            buttonDiv.innerHTML = alert.button;
        }
        
        this.showButton();
    }

    registerEvents() {
        const button = this._node && this._node.querySelector(this._classNames.button);
        if (button) {
            button.addEventListener('mouseup', this._onSelect.bind(this), false);
            button.addEventListener('touchend', this._onSelect.bind(this), false);
        }
    }
    unregisterEvents() {
        const button = this._node && this._node.querySelector(this._classNames.button);
        if (button) {
            button.removeEventListener('mouseup', this._onSelect.bind(this));
            button.removeEventListener('touchend', this._onSelect.bind(this));
        }
    }
    hideButton() {
        const button = this._node && this._node.querySelector(this._classNames.button);
        if (button) {
            button.style.display = 'none';
        }
    }
    showButton() {
        const button = this._node && this._node.querySelector(this._classNames.button);
        if (button) {
            button.style.display = 'block';
        }
    }
    displayLocationDeniedMessage() {
        this.updateSubtitle(this._alert.enableLocationMessage);
        this.hideButton();
        this._eventBus.publish(constants.events.CHYRON_LOCATION_DENIED_SHOWN, this._alert.enableLocationMessage);
    }
    displayWaitForLocationMessage() {
        this.updateSubtitle(this._alert.waitForLocationMessage);
        this.hideButton();
        this._eventBus.publish(constants.events.CHYRON_WAIT_FOR_LOCATION_SHOWN, this._alert.waitForLocationMessage);
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onSelect(event) {
        this._eventBus.publish(constants.events.CHYRON_TAPPED, {
            alert: this._alert
        });
        // do not hide yet since we want to wait for the location to be set
        event.preventDefault();
    }

}

export {
    LocationChyron
};
