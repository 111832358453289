import _ from 'lodash';

import error from './errors';
import { DebugFactory } from './debugger';

class BaseVem {
    /**
     * @class BaseVem
     * @classdesc 
     * @param {Object}  args - constructor args object
     * @param {String}  name - name of the class
     */
    constructor(args, name) {
        this.name = _.get(args, 'name', name);
        if (!args) {
            console.error('VEM', this.name, error.MISSING_ARGS, 'args', args);
            throw new Error(error.WRONG_INIT);
        }
    }
}

class BaseManager {
    /**
     * @class BaseManager
     * @classdesc 
     * @param {Object}  args - constructor args object
     * @param {String}  name - name of the class
     */
    constructor(args, name) {
        this.name = name;
        if (!args || !_.get(args, 'bus', null) || !_.get(args, 'Debugger', null)) {
            console.error('VEM', this.name, error.MISSING_ARGS, 'args', args);
            throw new Error(error.WRONG_INIT);
        }
        this.debug = new (_.get(args, 'Debugger', null))({ name: this.name });
        this._eventBus = _.get(args, 'bus', null);
    }
}

class BaseBus {
    /**
     * @class BaseBus
     * @classdesc 
     * @param {Object}  args - constructor args object
     * @param {String}  name - name of the class
     */
    constructor(args, name) {
        this.name = name;
        if (!args) {
            console.error('VEM', this.name, error.MISSING_ARGS, 'args', args);
            throw new Error(error.WRONG_INIT);
        }
        const Debugger = _.get(args, 'Debugger', new DebugFactory({
            active: true,
            level: '*',
            session: 'event-bus-static'
        }));
        this.debug = new Debugger({ name: this.name });
    }
}

export {
    BaseVem,
    BaseManager,
    BaseBus
}
