import _ from 'lodash';

import { utils } from '../lib/utils';

import { Alert } from './alert';
import { AlertPrompt } from './alert-prompt';
import { ScheduledVideo } from './scheduled-video';
import { VideoMetadata } from './video-metadata';
import { VideoMetadataLite } from './video-metadata-lite';

class VideoExperiencesResponse {
    /**
     * @class VideoExperiencesResponse
     * @classdesc
     * @param {Object} json
     */
    constructor(json) {
        json = json.video_experiences || json;
        this.isLiteResponseFormat = _.get(json, 'playlist[0].id', false) ? true : false;
        this.alerts = this.convertAlerts(json.alerts);
        this.playlist = this.convertPlaylist(json.playlist);
        this.scheduledVideos = this.convertScheduledVideos(json.scheduled_videos);
        this.nextFetchTime = utils.convertStrToDate(json.next_fetch_time);
    }
    /**
     * 
     * @param {*} jsonVideos 
     */
    convertScheduledVideos(jsonVideos) {
        if (!jsonVideos) {
            return [];
        }
        const scheduledVideos = [];
        for (const video of jsonVideos) {
            let scheduledVideo = new ScheduledVideo(video);
            this._removeFrontpageInstantAppToSportsAppServerWorkaround(scheduledVideo);
            scheduledVideos.push(scheduledVideo);
        }
        return scheduledVideos;
    }
    _removeFrontpageInstantAppToSportsAppServerWorkaround(video) {
        if (video.conditions.indexOf('require_sports_app') !== -1) {
            const instantAppIndex = video.conditions.indexOf('require_instant_app');
            if (instantAppIndex !== -1) {
                video.conditions.splice(instantAppIndex, 1);
            }
        }
    }
    /**
     * 
     * @param {*} jsonAlerts 
     */
    convertAlerts(jsonAlerts) {
        if (!jsonAlerts) {
            return [];
        }
        const alerts = [];
        for (const alert of jsonAlerts) {
            if (alert?.data?.type === Alert.TYPES.PROMPT) {
                alerts.push(new AlertPrompt(alert));
            } else {
                alerts.push(new Alert(alert));
            }
            
        }
        return alerts;
    }
    /**
     * 
     * @param {*} jsonPlaylist 
     */
    convertPlaylist(jsonPlaylist) {
        if (!jsonPlaylist) {
            return [];
        }
        const playlist = [];
        const MetaDataClass = this.isLiteResponseFormat ? VideoMetadataLite : VideoMetadata;
        for (const item of jsonPlaylist) {
            playlist.push(new MetaDataClass(item));
        }
        return playlist;
    }
}

export {
    VideoExperiencesResponse
};
