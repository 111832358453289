import _ from 'lodash';

import { utils } from '../lib/utils';

import { Alert } from './alert';

class AlertPrompt extends Alert {
    /**
     * @class AlertPrompt
     * @classdesc
     * @description Takes in the alert portion of a schedule api response and
     *              produces an alert object with the following structure. Or,
     *              takes in a structured payload and produces a prompt object.
     * @param {Object} json
     * @param {String} json.link
     */
    constructor(json) {
        super(json);
        this.type = _.get(json, 'data.type', Alert.TYPES.PROMPT);
        this.ackId = this.id;
        this.link = json?.data?.link || '';
        this.link2 = json?.data?.link2 || '';
    }
}

export {
    AlertPrompt
};
