import _ from 'lodash';

import constants from '../lib/constants';
import { BaseManager } from '../lib/base';

class StateManager extends BaseManager {
    /**
     * @class StateManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'StateManager');

        this.state = constants.states.UNLOADED;
        this.previousState = constants.states.UNLOADED;
        this._requestLocationResolved = false;

    }
    /**
     * @returns {String}
     */
    getState() { 
        return _.get(this, 'state');
    }
    /**
     * @param {(String|Number)} state - Either a string or number representing the state you want to set.
     * @returns {Number}
     */
    setState(state) {
        state = this.normalizeStateValue(state);
        // The REQUEST_LOCATION state should only ever happen once per session.
        // It only happens because a clients IP fails to resolved, so instead
        // the server wants Geo. Once we get that from the user we have what
        // the server needs and will no longer need to follow that path.
        // This will prevent any possible way for the onWaitForLocationResolved
        // event to be triggered more than once(that's what we want).
        const stateChangeAllowed = ((state === constants.states.REQUEST_LOCATION) && this._requestLocationResolved) ? false : true;
        if (stateChangeAllowed) {
            const previousState = this.state;
            // state can be number or string, so convert to number
            this.state = this.validateState(state) ? state : this.getState();
            if (this.state === constants.states.REQUEST_LOCATION) {
                this._requestLocationResolved = true;
            }
            if (previousState !== this.state) {
                this.previousState = previousState;
                this.debug.log(this.getStateString() + ' <- ' + this.getStateString(this.previousState));
                this._eventBus.publish(constants.events.STATE_CHANGE, {
                    previous: this.previousState,
                    current: this.state
                });
            }
        } else {
            console.log('state change not allowed');
        }
        return this.state;
    }
    /**
     * @param {(String|Number)} state - The string/number value to convert to number.
     * @returns {Number}
     */
    normalizeStateValue(state) {
        return _.isNumber(state) ? state : _.get(constants.states, state, null);
    }
    /**
     * @param {Number} state - Is the provided state a valid state?
     * @returns {Boolean}
     */
    validateState(state) {
        return _.indexOf(_.values(constants.states), state) > -1;
    }
    /**
     * @returns {Number} The previous state
     */
    getPreviousState() {
        return this.previousState;
    }
    /**
     * @param {Number} state
     * @returns {String}
     */
    getStateString(state=null) {
        return _.invert(constants.states)[_.isNull(state) ? this.state : (this.validateState(state) ? state : '')];
    }
    /**
     * @returns {Array}
     */
    getStates() {
        return constants.states;
    }
    /**
     * 
     * @param {Number} state - The state you want to verify is active
     * @returns {Boolean}
     */
    isActive(state) {
        state = this.normalizeStateValue(state);
        return (this.validateState(state) && (this.state === state));
    }
}

export {
    StateManager
};
