import _ from 'lodash';

import constants from '../lib/constants';
import vemEvents from '../lib/events';
import { BaseManager } from '../lib/base';

/**
 * @name SectionItem
 * @param label {String} Title of the section
 * @param index {Number} Position of section in the playlist
 */

class SectionMap {
    /**
     * @class SectionMap
     * @classdesc
     * @description create a lookup table
     * @param {Number} args.sections - An object of original section data from the vbot response.
     */
    constructor(sections) {
        let sectionMap = {};
        _.each(sections, (section, index) => {
            let label = _.get(section, 'label', undefined);
            if (!_.isEmpty(label) && !_.has(sectionMap, label)) {
                sectionMap[label] = Number(index);
            }
        });
        return sectionMap;
    }

}

class SectionList {
    /**
     * @class SectionList
     * @classdesc
     * @returns {Object} mediaItems
     * @example
     *   {
     *      'label': 'index/position',
     *      'label': 'index/position',
     *      ...
     *   }
     */
    constructor(mediaItems) {
        let sectionList = {};
        _.each(mediaItems, (mediaItem, index) => {
            sectionList[index] = _.get(mediaItem, ((_.has(mediaItem, 'meta') ? 'meta.' : '') + 'playlist_config.section'), {});
        });
        return sectionList;
    }

}

class SectionManager extends BaseManager {
    /**
     * @class SectionManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'SectionManager');

        this._dataManager = _.get(args, 'dataManager', null);
        this._playerManager = _.get(args, 'playerManager', null);

        this.reset();

        this.sectionList = this._dataManager.getSections();
        this.sectionMap = this.updateSectionMap(this.sectionList);

        this._publishSectionListUpdate();

        this._eventBus.subscribe(constants.events.PLAYBACK_WILL_START, this._onPlaybackWillStart.bind(this));
        this._eventBus.subscribe(constants.events.PLAYBACK_WILL_COMPLETE, this._onPlaybackWillComplete.bind(this));
        this._eventBus.subscribe(constants.events.DATA_UPDATED, this._onDataUpdated.bind(this));

    }
    destroy() {
        this.reset();
    }
    reset() {
        this.sectionList = {};
        this.sectionMap = {};
        this.currentSectionIndex = -1;
    }
    
    getPlayer() {
        return this._playerManager.getPlayer();
    }
    getSectionList() {
        const sectionList = _.get(this, 'sectionList', {});
        let output = {};
        _.each(sectionList, (section, key) => {
            if (_.has(section, 'label')) {
                output[key] = section;
            }
        });
        return output;
    }
    getSectionMap() {
        return _.get(this, 'sectionMap', {});
    }
    getSectionIndexBySection(section) {
        var sectionIndex = -1;
        _.each(this.sectionList, (sectionItem, key) => {
            if (sectionItem.type === section.type && sectionItem.label === section.label) {
                sectionIndex = key;
                return false;
            }
        });
        return Number(sectionIndex);
    }
    getSectionIndexByMediaItemId(mediaItemId) {
        return Number(this.getSectionIndexBySectionLabel(this.getSectionLabelByMediaItemId(mediaItemId)));
    }
    getSectionMapIndices() {
        return _.sortBy(_.values(this.getSectionMap()));
    }
    getSectionForCurrentItem() {
        // assume first item is section if the player has never started playing an item
        // and the api user is calling for 'current', since there is no 'current' yet
        return this.sectionList[(this.currentSectionIndex === -1) ? 0 : this.currentSectionIndex];
    }
    getSectionLabelByMediaItemIndex(mediaItemIndex) {
        const sortedSectionList = this.getSectionMapIndices();
        let sectionLabel = '';
        _.each(sortedSectionList, (value, index, list) => {
            if (_.inRange(mediaItemIndex, value, ((index + 1) === list.length) ? this._playerManager.getMediaItems().length : list[index + 1])) {
                sectionLabel = this.getSectionLabelBySectionIndex(value);
                return false;
            }
        });
        return sectionLabel;
    }
    getSectionLabelByMediaItemId(id) {
        return this.getSectionLabelByMediaItemIndex(this._playerManager.getMediaItemIndexByMediaItemId(id));
    }
    getSectionLabelBySectionIndex(index) {
        return _.get(_.invert(this.getSectionMap()), index, '');
    }
    getSectionIndexBySectionLabel(label) {
        return Number(_.get(this.getSectionMap(), label, -1));
    }
    getSectionByLabel(sectionLabel) {
        return this.getSectionByIndex(this.getSectionIndexBySectionLabel(sectionLabel));
    }
    getSectionByIndex(sectionIndex) {
        return this.sectionList[sectionIndex];
    }
    getSectionForNextItem() {
        return this.sectionList[this.getNextSectionIndexBySectionIndex((this.currentSectionIndex === -1) ? 0 : this.currentSectionIndex)];
    }
    getNextSectionLabel() {
        return this.getSectionLabelBySectionIndex(this.getNextSectionIndexBySectionIndex(this.currentSectionIndex));
    }
    getNextSectionIndexBySectionIndex(sectionIndex) {
        return _.find(this.getSectionMapIndices(), (value) => {
            return value > sectionIndex;
        }) || -1;
    }
    getNextSectionIndexByMediaItemId(id) {
        return this.getNextSectionIndexBySectionIndex(this._playerManager.getMediaItemIndexByMediaItemId(id));
    }
    playSection(sectionItem) {
        const videoPlayer = this.getPlayer();
        videoPlayer.playlist.setPosition(this.getSectionIndexBySection(sectionItem));
        videoPlayer.controls.play();
    }
    playSectionByLabel(sectionLabel) {
        const videoPlayer = this.getPlayer();
        videoPlayer.playlist.setPosition(this.getSectionIndexBySectionLabel(sectionLabel));
        videoPlayer.controls.play();
    }
    playSectionByPosition(sectionIndex) {
        const videoPlayer = this.getPlayer();
        videoPlayer.playlist.setPosition(sectionIndex);
        videoPlayer.controls.play();
    }
    isMediaItemWithinCurrentSection(mediaItem) {
        let isMediaItemWithinCurrentSection = false;
        if (mediaItem) {
            const nextSectionIndex = this.getNextSectionIndexBySectionIndex(this.currentSectionIndex);
            const currentMediaItemIndex = this._playerManager.getMediaItemIndexByMediaItemId(mediaItem.videoId);
            const sectionRangeEnd = (nextSectionIndex === -1) ? this._playerManager.getMediaItems().length : nextSectionIndex;
            const sectionRangeStart = this.currentSectionIndex;
            isMediaItemWithinCurrentSection = _.inRange(currentMediaItemIndex, sectionRangeStart, sectionRangeEnd);
        }
        return isMediaItemWithinCurrentSection;
    }
    updateSectionMap(sectionList) {
        return _.isEmpty(sectionList) ? this.sectionMap : new SectionMap(sectionList);
    }
    _publishSectionListUpdate() {
        !_.isEmpty(this.sectionList) && this._eventBus.publish(vemEvents.onSectionListUpdate);
    }
    _publishSectionStart(section) {
        this._eventBus.publish(vemEvents.onSectionStart, section);
    }
    _publishSectionComplete(section) {
        this._eventBus.publish(vemEvents.onSectionComplete, section);
    }
    
    /**************************************************************************
        Event Handlers
    **************************************************************************/
    /**
     * received when new data is injected from construction or by a fetch
     * @param {Object} lastResponse - The last videoExperience data received
     */
    _onDataUpdated(lastResponse) {
        this.reset();
        // instanceof SectionList component
        this.sectionList = _.get(lastResponse, 'sections', {});
        this.sectionMap = this.updateSectionMap(this.sectionList);
        this._publishSectionListUpdate();
    }
    /**
     * @param {Object} mediaItems
     */
    _onPlaybackWillStart(mediaItem) {
        this.currentMediaItemId = mediaItem.videoId;
        const currentMediaItemSectionIndex = this.getSectionIndexByMediaItemId(this.currentMediaItemId);
        if ((this.currentSectionIndex < 0) || !this.isMediaItemWithinCurrentSection(mediaItem)) {
            this.currentSectionIndex = currentMediaItemSectionIndex;
            this._publishSectionStart(this.getSectionForCurrentItem());
        }
    }
    /**
     * @param {Object} mediaItems
     */
    _onPlaybackWillComplete(mediaItem) {
        if (!this.isMediaItemWithinCurrentSection(this._playerManager.getNextMediaItemByCurrentMediaItemId(mediaItem.videoId))) {
            this._publishSectionComplete(this.getSectionForCurrentItem());
        }
    }
}

export {
    SectionManager,
    SectionList,
    SectionMap
};
