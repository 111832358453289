import _ from 'lodash';

import constants from '../lib/constants';
import { BaseManager } from '../lib/base';

import { Alert } from '../models/alert';

class AlertManager extends BaseManager {
    /**
     * @class AlertManager
     * @classdesc 
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */    
    constructor(args) {
        super(args, 'AlertManager');

        this._dataManager = _.get(args, 'dataManager');
        this._locationManager = _.get(args, 'locationManager');
        this._queue = [];
        this._locationAlert = null;
        this._instantAppAlert = null;
        this._sportsAppAlert = null;

        this.updateData();

        this._eventBus.subscribe(constants.events.DATA_UPDATED, this._onDataUpdated.bind(this));
    }
    destroy() {
        this.resetTimer();
        this._dataManager = null;
        this._locationManager = null;
        this._queue = [];
    }
    clean() {
        if (this._queue && this._queue.length > 0) {
            this._queue = this._queue.filter(a => {
                 return a.startTime.getTime() - Date.now() > 0;
            });
        }
    }

    updateData() {
        const alerts = this._dataManager.getAlerts();
        if (alerts) {
            this._queue = alerts.slice(0, alerts.length);
        }
        for (const alert of alerts) {
            if (alert.startTime.getTime() < Date.now()) {
                let shouldShowAlert = this._shouldShowAlert(alert);
                if (shouldShowAlert) {
                    this.fireOnAlert(alert);
                }
            }
            if (alert.type === Alert.TYPES.CHYRON && alert.actionName === Alert.ACTION_NAMES.RequestLocation) {
                this._locationAlert = alert;
            }
            if ((alert.type === Alert.TYPES.PROMPT) && (alert.actionName === Alert.ACTION_NAMES.RequireInstantApp)) {
                this._instantAppAlert = alert;
            }
            if ((alert.type === Alert.TYPES.PROMPT) && (alert.actionName === Alert.ACTION_NAMES.RequireSportsApp)) {
                this._sportsAppAlert = alert;
            }
        }
        this.scheduleNext();
    }
    resetTimer() {
        if (this._scheduledEventTimer) {
            clearTimeout(this._scheduledEventTimer);
        }
        this._scheduledEventTimer = null;
    }
    scheduleNext() {
        this.resetTimer();
        this.clean();
        if (this._queue && this._queue.length > 0) {
            // Sort by startTime
            this._queue.sort((a, b) => {
                return a.startTime.getTime() - b.startTime.getTime();
            });

            const first = this._queue[0];
            this._scheduledEventTimer = setTimeout(this._onScheduledTimer.bind(this), first.startTime.getTime() - Date.now());
        }
    }
    fireOnAlert(alert) {
        this._eventBus.publish(constants.events.ALERT_START, alert);
    }
    getLocationAlert() {
        return this._locationAlert;
    }
    getInstantAppAlert() {
        return this._instantAppAlert;
    }
    getSportsAppAlert() {
        return this._sportsAppAlert;
    }
    _shouldShowAlert(alert) {
        return ((alert.actionName === Alert.ACTION_NAMES.RequestLocation) && this._locationManager.getCurrentLocation()) ? false : true;
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onScheduledTimer() {
        if (this._queue && this._queue.length > 0) {
            const item = this._queue[0];
            let startTime = item.startTime.getTime();
            let alertsWithSameTime = [];
            for (const alert of this._queue) {
                if (startTime === alert.startTime.getTime()) {
                    alertsWithSameTime.push(alert);
                }
            }
            for (const alert of alertsWithSameTime) {
                this._queue.splice(this._queue.indexOf(alert), 1);
                let shouldShowAlert = this._shouldShowAlert(alert);
                if (shouldShowAlert) {
                    this.fireOnAlert(alert);
                }
            }
            this.scheduleNext();
        }
    }
    _onDataUpdated() {
        this.updateData();
    }

}

export {
    AlertManager
};
