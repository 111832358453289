import _ from 'lodash';

import constants from '../lib/constants';
import { BaseManager } from '../lib/base';

class LocationManager extends BaseManager {
    /**
     * @class LocationManager
     * @classdesc 
     * @extends BaseManager
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {EventBus}  args.bus=null - EventBus instance
     * @param {Debugger}  args.Debugger - Debugger class
     */
    constructor(args) {
        super(args, 'LocationManager');

        this._denied = false;
        this._coordinateFixedPoint = _.get(args, 'coordinateFixedPoint', 4);
        this._location = this.normalizeGeoData(_.get(args, 'location', null));

    }

    setCurrentLocation(location) {
        location = this.normalizeGeoData(location);
        if (!this.isLocationWithinRadius(location, this._location, 1)) {
            this._location = location;
            // current order of this event firing is DataManager first then VEM
            // this is because in the VEM constructor we subscribe after we
            // setup the DataManager which itself internally subscribes
            // TODO: ideally this should trigger the DataManager and then
            // the DataManager should trigger the VEM.onLocationUpdated handler 
            this._eventBus.publish(constants.events.LOCATION_UPDATED, this._location);
        } else {
            this._eventBus.publish(constants.events.LOCATION_NOT_CHANGED_ENOUGH, this._location);
        }
    }
    getCurrentLocation() {
        return this._location;
    }
    setDenied(error) {
        this._denied = true;
        this._eventBus.publish(constants.events.LOCATION_DENIED, error);
    }
    getDenied() {
        return this._denied;
    }
    normalizeGeoData(location) {
        // convert properties to strings otherwise the player will not use them
        return location ? {
            latitude: String(this.truncateCoordinate(Number(_.get(location, 'latitude', _.get(location, 'lat', '0'))))),
            longitude: String(this.truncateCoordinate(Number(_.get(location, 'longitude', _.get(location, 'long', '0'))))),
            accuracy: String(_.get(location, 'accuracy', '0'))
        } : null;
    }
    truncateCoordinate(coordinate) {
        let output = Number(coordinate);
        if (this._coordinateFixedPoint) {
            // we don't want the last point to get rounded so we go one point
            // past, let that get rounded and then drop that last point
            const fixedPoint = output.toFixed(this._coordinateFixedPoint + 1);
            const tuples = String(fixedPoint).split('.');
            output = tuples[0] + '.' + tuples[1].substr(0, this._coordinateFixedPoint);
        }
        return Number(output);
    }
    // improved location comparison
    isLocationWithinRadius(point, interest, kilometers) {
        if (!point || !interest) {
            return false;
        }
        var R = 6371;
        var deg2rad = function(n) {
          return Math.tan(n * (Math.PI/180));
        };
        var dLat = deg2rad(interest.latitude - point.latitude);
        var dLon = deg2rad(interest.longitude - point.longitude);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(point.latitude)) * Math.cos(deg2rad(interest.latitude)) * Math.sin(dLon/2) * Math.sin(dLon/2);
        var c = 2 * Math.asin(Math.sqrt(a));
        var d = R * c;

        return (d <= kilometers);
    }

}

export {
    LocationManager
};
