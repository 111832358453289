import _ from 'lodash';

import constants from '../lib/constants';
import { utils } from '../lib/utils';

import { Chyron } from './chyron';

import './styles/prompt.scss';

class Prompt extends Chyron {
    /**
     * @class Prompt
     * @classdesc
     * @param {Object}    args - Constructor args object that contains the following:
     */
    constructor(args, name='Prompt') {
        super(args, name);

        this._type = 'prompt';
        this._template = require('./templates/prompt.html');
        // 'ackId' is used to respond to the caller
        this._ackId = _.get(args, 'ackId', null);

        this._classNames = {
            container: '.ve-prompt',
            title: '.ve-prompt-title',
            description: '.ve-prompt-description',
            button: '.ve-prompt-button',
            button2: '.ve-prompt-button2'
        };

    }
    create() {
        super.create();
        // adjust container scale after appended
        // this won't change once it's rendered
        this.adjustScale(this._container);
        if (this._alert.link2 && this._alert.button2) {
            const button2Div = this._node.querySelector(this._classNames.button2);
            if (button2Div) {
                button2Div.parentNode.style.display = 'block';
            }
        }
    }
    render() {
        const alert = this._alert;
        if (alert.templateName) {
            this._template = require('./templates/' + alert.templateName);
        }
        const renderConfig = {
            logo: alert.logo,
            title: alert.title,
            subtitle: alert.subtitle || '',
            description: alert.description,
            button: alert.button,
            button2: alert.button2 || '',
            background: alert.background
        };
        let renderButton2 = false;
        if (alert.link2) {
            renderConfig.link2 = alert.link2;
            if (alert.button2) {
                renderConfig.button2 = alert.button2;
                renderButton2 = true;
            }
        }
        const element = utils.render(this.getTemplate(), renderConfig);
        if (alert.className) {
            element.className = element.className + ' ' + alert.className;
        }
        return element;
    }
    update(alert) {
        super.update.call(this, alert);

        const containerDiv = this._node.querySelector(this._classNames.container);
        if (containerDiv) {
            containerDiv.style.background = this._alert.background;
        }
        const descriptionDiv = this._node.querySelector(this._classNames.description);
        if (descriptionDiv) {
            descriptionDiv.innerHTML = this._alert.description;
        }
        const buttonDiv = this._node.querySelector(this._classNames.button);
        if (buttonDiv) {
            buttonDiv.innerHTML = this._alert.button;
        }
        if (this._alert.link2 && this._alert.button2) {
            const button2Div = this._node.querySelector(this._classNames.button2);
            if (button2Div) {
                button2Div.style.display = 'block';
                button2Div.innerHTML = this._alert.button2;
            }
        }
    }
    registerEvents() {
        const button = this._node.querySelector(this._classNames.button);
        if (button) {
            button.addEventListener('mouseup', this._onSelect.bind(this), false);
            button.addEventListener('touchend', this._onSelect.bind(this), false);
        }
        if (this._alert.link2 && this._alert.button2) {
            const button2 = this._node.querySelector(this._classNames.button2);
            if (button2) {
                button2.addEventListener('mouseup', this._onSelect2.bind(this), false);
                button2.addEventListener('touchend', this._onSelect2.bind(this), false);
            }
        }
        const button2Container = this._node.querySelector(this._classNames.button2);
        if (button2Container) {
            button2Container.addEventListener('mouseup', this._onSelect2.bind(this), false);
            button2Container.addEventListener('touchend', this._onSelect2.bind(this), false);
        }
    }
    unregisterEvents() {
        const button = this._node.querySelector(this._classNames.button);
        if (button) {
            button.removeEventListener('mouseup', this._onSelect.bind(this));
            button.removeEventListener('touchend', this._onSelect.bind(this));
        }
        if (this._alert.link2 && this._alert.button2) {
            const button2 = this._node.querySelector(this._classNames.button2);
            if (button2) {
                button2.removeEventListener('mouseup', this._onSelect2.bind(this));
                button2.removeEventListener('touchend', this._onSelect2.bind(this));
            }
        }
        const button2Container = this._node.querySelector(this._classNames.button2);
        if (button2Container) {
            button2Container.removeEventListener('mouseup', this._onSelect2.bind(this));
            button2Container.removeEventListener('touchend', this._onSelect2.bind(this));
        }
    }
    adjustScale(container) {
        const logTag = 'adjustScale';
        this.debug.log('container', container);
        // width(px): font-size(rem)
        // TODO: replace map with linear interpolation
        const widthMap = {
            850: 2.92, 800: 2.75,
            750: 2.58, 700: 2.40,
            650: 2.23, 600: 2.06,
            575: 1.95, 550: 1.89, 525: 1.80, 500: 1.72,
            495: 1.68, 490: 1.66, 485: 1.65, 480: 1.63, 475: 1.63, 470: 1.59, 465: 1.58, 460: 1.56, 455: 1.54, 450: 1.53, 445: 1.51, 440: 1.49, 435: 1.48, 430: 1.46, 425: 1.44, 420: 1.42, 415: 1.41, 410: 1.39, 405: 1.37, 400: 1.36,
            395: 1.34, 390: 1.33, 385: 1.31, 380: 1.29, 375: 1.27, 370: 1.26, 365: 1.24, 360: 1.22, 355: 1.21, 350: 1.19, 345: 1.17, 340: 1.15, 335: 1.13, 330: 1.11, 325: 1.10, 320: 1.08, 315: 1.07, 310: 1.05, 305: 1.03, 300: 1.02,
            295: 1.00, 290: 0.97, 285: 0.96, 280: 0.95, 275: 0.93, 270: 0.93, 265: 0.90, 260: 0.88, 255: 0.86, 250: 0.84, 245: 0.82, 240: 0.80, 235: 0.78, 230: 0.76, 225: 0.74, 220: 0.72, 215: 0.70, 210: 0.68, 205: 0.64, 200: 0.67
        };
        const parentContainerWidth = parseInt(container.getBoundingClientRect().width, 10);
        // set default to common screen width
        let parentScale = widthMap[380];
        for (let width in widthMap) {
            // set a value and exit once we find a mapping that exceeds our
            // container width
            if (parentContainerWidth >= width) {
                parentScale = widthMap[width];
            } else {
                // we're below the threshold now, skip the rest of calcs
                break;
            }
        }
        this.debug.log(logTag, 'parent width', parentContainerWidth, 'rem scale', parentScale);
        container.querySelector('.ve-prompt-block').style.setProperty('font-size', (parentScale + 'rem'));
    }
    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onSelect(event) {
        event.preventDefault();
        this._eventBus.publish(constants.events.CHYRON_TAPPED, {
            alert: this._alert
        });
        if (this._alert?.link) {
            window.location = this._alert.link;
        }
    }
    _onSelect2(event) {
        event.preventDefault();
        this._eventBus.publish(constants.events.CHYRON_TAPPED, {
            alert: this._alert,
            buttonContext: constants.rapid.buttonNames.UPDATE_OS,
            buttonPosition: 2
        });
        if (this._alert?.link2) {
            window.location = this._alert.link2;
        }
    }

}

export {
    Prompt
};
