/*
-------------------------------------------------------------------------------
| Legend                                                                      |
| Search for these globally in the code to find notes about specific things.  |
-------------------------------------------------------------------------------
| Label     | Description                                                     |
-------------------------------------------------------------------------------
| BUG       | something needs fixed                                           |
| DEBUG     | debug snippet that can be used to test/debug                    |
| NOTE      | comment about code usage                                        |
| SEE       | search reference to something else                              |
| TODO      | task list of sorts                                              |
| TDB       | something needs figured out                                     |
| WARNING   | pay attention to making changes in these locations              |
-------------------------------------------------------------------------------

CAUTION: using bound functions as subscribe handlers will not allow jest.spyOn
to see the function. We could use lambdas as long as we define within scope.
But arrow functions in our class composition are considered properties which is
still a proposal.

TODO: improve loading performance of background images for StreamPicker
TODO: refactor PlayerManager.playScheduledVideo once UP bugs/inconsistencies
have been resolved
TODO: remove setImmediate polyfill UP once PlayerManager.playScheduledVideo has
been resolved
TODO: replace VEM.events with vemEvents
TODO: we could replace 95% of our lodash usage by enabling both optional
chaining and nullish coalescing operator using Babel plugin proposals

TBD: we could switch the timers source of truth once we get closer to the
start time. So we start out with the count_down_until and once we have the
scheduled video we switch to use its start time, that way the countdown
matches with the start time.

TEMPLATES:
To include markup within the strings that get replaced within the templates,
use triple curly braces.

incorrect:
    template string:
        title = 'hello<br/>world'
    template markup:
        <div>{{title}}</div>
    result:
        <div>hello world</div>
correct:
    template string:
        title = 'hello<br/>world'
    template markup:
        <div>{{{title}}}</div>
    result:
        <div>hello<br/>world</div>

*/
import 'setimmediate';
import _ from 'lodash';
import url from 'url';
import querystring from 'querystring';

import packageInfo from '../package.json';
import errorCodes from './lib/errors';
import constants from './lib/constants';
import vemEvents from './lib/events';

import { EventBus } from './lib/bus';
import { BaseVem } from './lib/base';
import { DebugFactory } from './lib/debugger';
import { InputController } from './lib/input';
import { utils } from './lib/utils';

import { AlertManager } from './managers/alert';
import { SchedulingManager } from './managers/scheduling';
import { DataManager } from './managers/data';
import { MetricsManager } from './managers/metrics';
import { PlayerManager } from './managers/player';
import { LocationManager } from './managers/location';
import { SectionManager } from './managers/section';
import { EntityManager } from './managers/entity';
import { HistoryManager } from './managers/history';
import { ConfigManager } from './managers/config';
import { NetworkingManager } from './managers/networking';
import { StateManager } from './managers/state';
import { ThemeManager } from './managers/theme';

import { ScheduledVideo } from './models/scheduled-video';
import { Alert } from './models/alert';
import { AlertPrompt } from './models/alert-prompt';

import { PromptUser } from './ui/prompt-user';
import { Chyron } from './ui/chyron';
import { LocationChyron } from './ui/location-chyron';
import { VideoSelection } from './ui/video-selection';
import { Countdown } from './ui/countdown';

const vemVersion = packageInfo.version;

class VEM extends BaseVem {
    /**
     * @class VEM
     * @classdesc
     * @extends BaseVem
     * @param {Object}    args - Constructor args object that contains the following:
     * @param {String}    args.channelId=null - The channel identifier(cid) to send to VES.
     * @param {Boolean}   [args.debug=false] - To activate the debugger.
     * @param {Object}    args.commonParams={} - Common parameters to send to VES (site, lang, region, etc).
     * @param {Object}    [args.videoplayer=null] - VideoPlayer instance
     * @param {Object}    [args.location=null] - GeoLocation to send to VES. {lat:'',long:'',accuracy:''}
     * @param {Boolean}   [args.enableNFLLocationChyron=false] - Enable NFL specific designed LocationChyron that
     * covers the video.
     * @param {Boolean}   [args.shouldShowChyron=true] - To enable/disable the Chyrons.
     * @param {DomObject} args.chyronContainerElem=null - Where the Chyron should be attached to the DOM. Attempts to
     * get the player's containerElem(if not passed) will only work as expected if there is only one player on the
     * page.
     * @param {Number}    [args.videoSelectionAutoHideTimeout=8000] - A number(ms) that controls the timeout of the
     * Chyron.
     * @param {Boolean}   [args.watchHistory=false] - If true, then the HistoryManager is invoked.
     * @param {Object}    [args.videoExperiences=null] - This contains the data that a channelId would eventually
     * fetch. In this case we are providing the data and skipping the initial fetch. This data can be provided in
     * 3 ways, prioritized as follows:
     *    1. as a return value from 'initializer' function
     *    2. as an argument passed into the 'init' function
     *    3. as a property of the constructor config
     * @param {Object}    [args.rapid=null] - Rapid instance
     * @param {Function}  [args.initializer=null] - If this is provided it will execute within the init method. Any
     * videoExperiences returned from this function take priority over videoExperiences passed in via the constructor
     * config.
     * @param {Boolean}   [args.loadPlaylistAfterScheduledVideo=true] - when false, only scheduled videos will play the
     * playlist will be ignored
     * @param {Boolean}   [args.showVideoSelection] - if false, prevents the StreamPicker from being shown
     * @param {Boolean}   [args.await=false] - If true, then the program will only initialize but not run until the
     * init method is explicitly called.
     * @param {Boolean}   [args.autoPlaybackOnSchedule=true] - If false will not automatically play scheduled videos
     * when they reach their start time
     */
    constructor(args) {
        const startTime = Date.now();

        super(args, 'VEM');

        this._startTime = startTime;
        this._initializedTime = 0;

        const _querystring = querystring.parse(url.parse(window.location.href).query);
        const _channelId = _.get(_querystring, 'cid', '');
        this.channelId = (_channelId.length > 0) ? _channelId : _.get(args, 'channelId', null);

        if (!this.channelId) {
            console.error('VEM', this.name, errorCodes.MISSING_ARGS, 'channelId');
            throw new Error(errorCodes.MISSING_ARGS, 'channelId');
        } else {

            this._videoExperiences = _.get(args, 'videoExperiences.video_experiences', _.get(args, 'videoExperiences', null));
            this._sessionId = _.get(args, 'sessionId', utils.generateSessionId());
            this._debugId = _.get(_querystring, 'vemdid', _.get(args, 'debugId', null));
            this._runMode = _.get(args, 'runMode', constants.runModes.CLIENT);
            this._playlistId = _.get(args, 'playlistId', null);
            this.version = vemVersion;

            this.Debugger = new DebugFactory({
                active: Boolean(Number(_.get(_querystring, 'vemd', 0))) || _.get(args, 'debug', false),
                level: !_.isEmpty(_.get(_querystring, 'vemdl', '')) ? _.get(_querystring, 'vemdl', '').split(',') : _.get(args, 'level', ['log']),
                module: this,
                session: this._sessionId
            });
            this.debug = new this.Debugger({ name: this.name });

            this._initializer = _.get(args, 'initializer', null);
            this._await = _.get(args, 'await', _.get(args, 'waitForInit', false));
            this._destroyed = false;
            this._watchTokens = _.get(args, 'watchTokens', []);
            this._rapidInstance = _.get(args, 'rapid', null);
            this._completedVideoIds = null;
            this._currentPlayingScheduledVideo = null;
            this._scheduledStartsNotified = {};
            this._waitForLocationVideo = null;
            this._waitForPlayerVideo = null;
            this._vemsData = _.get(args, 'vemsData', null);
            // TODO: IMPORTANT: 2020-12-10: EJF
            // we should use the force_play flag from the server instead of these 2 switches
            this._autoAdvanceOnSchedulePlaybackComplete = _.get(args, 'autoAdvanceOnSchedule', _.get(args, 'autoAdvanceOnSchedulePlaybackComplete', true));
            this._refetchOnLiveEventComplete = _.get(args, 'refetchOnLiveEventComplete', true);
            this._liveCountdown = _.get(args, 'liveCountdown', false);
            /***
             * EJF: 2022-10-26
             * We are hard-coding this option for now. We don't want to respawn
             * on finance for now until we can determine what is going on with
             * some failures.
             */
            // this._respawnPlayer = _.get(args, 'respawnPlayer', constants.RespawnPlayerLevels.liveOnly);
            this._respawnPlayer = 0;
            this._respawnPlayerAttempts = 0;
            this._respawnPlayerAttemptsThreshold = 5;
            this._watchHistory = _.get(args, 'watchHistory', false);
            this._shouldLoadPlaylistAfterScheduledVideo = _.get(args, 'loadPlaylistAfterScheduledVideo', true);
            this._shouldShowVideoSelection = _.get(args, 'showVideoSelection', true);
            this._shouldAutoShowVideoSelection = true;
            this._testVideoGroup = _.get(_querystring, 'test_video_group', _.get(args, 'testVideoGroup', ''));
            this._preTest = _.get(_querystring, 'pre_test', _.get(args, 'preTest', ''));
            this._scheduleActive = false;
            this._videoSelectionAutoHideTimeout = _.get(_querystring, 'vemvst', _.get(args, 'videoSelectionAutoHideTimeout', 8000));
            // legacy support for old property name 'enableNFLLocationChyron'
            // removed to de-NFL-ize the code
            this._enableFullChyron = _.get(args, 'enableFullChyron', _.get(args, 'enableNFLLocationChyron', true));
            this._shouldShowChyron = this._getShouldShowChyron(_.get(args, 'shouldShowChyron', true));
            this._chyron = null;
            this._alertTimer = null;
            this._countdown = null;

            this._eventBus = new EventBus({
                Debugger: this.Debugger,
                runMode: this._runMode
            });

            new InputController({
                bus: this._eventBus
            });

            this._stateManager = new StateManager({
                bus: this._eventBus,
                Debugger: this.Debugger
            });

            let state = this._stateManager.getStateString(this._stateManager.setState('LOADING'));

            if (this.debug.active) {
                this.debug.info(state, 'querystring', _querystring);
                this.debug.log(state, 'version', this.version);
                this.debug.log(state, 'runMode', this._runMode);
                this.debug.log(state, 'sessionId', this._sessionId);
                this.debug.log(state, 'debugId', this._debugId);
                this.debug.log(state, 'channelId', this.channelId);
                this.debug.log(state, 'playlistId', this._playlistId);
                this.debug.log(state, 'testVideoGroup', this._testVideoGroup);
                this.debug.log(state, 'vemsData', !!this._vemsData);
                this.debug.log(state, 'scheduleSignature', this._vemsData?.schedule_url);
                this.debug.log(state, 'intercept', _.get(this, '_videoExperiences.intercept', false));
                this.debug.log(state, 'await', this._await);
                this.debug.log(state, 'initializer', !!this._initializer);
                this.debug.log(state, 'videoExperiences', !!this._videoExperiences);
                this.debug.log(state, 'enableFullChyron', this._enableFullChyron);
                this.debug.log(state, 'showVideoSelection', this._shouldShowVideoSelection);
                this.debug.log(state, 'loadPlaylistAfterScheduledVideo', this._shouldLoadPlaylistAfterScheduledVideo);
                this.debug.log(state, 'autoAdvanceOnSchedulePlaybackComplete', this._autoAdvanceOnSchedulePlaybackComplete);
                this.debug.log(state, 'refetchOnLiveEventComplete', this._refetchOnLiveEventComplete);
                this.debug.log(state, 'liveCountdown', this._liveCountdown);
                this.debug.log(state, 'respawnPlayer', _.invert(constants.RespawnPlayerLevels)[this._respawnPlayer]);                
            }

            this._themeManager = new ThemeManager({
                bus: this._eventBus,
                Debugger: this.Debugger,
                // fallback to nfl since none of those clients are passing this in
                theme: _.get(args, 'theme', 'nfl')
            });
            this.debug.log(state, 'theme', this._themeManager._theme);

            this._locationManager = new LocationManager({
                bus: this._eventBus,
                location: _.get(args, 'location', null),
                coordinateFixedPoint: 3,
                Debugger: this.Debugger
            });
            if (this.debug.active) {
                _.each(_.pick(this._locationManager.getCurrentLocation(), ['latitude', 'longitude']), (value, key) => {
                    this.debug.log(state, key, value);
                });
            }

            this._configManager = new ConfigManager({
                bus: this._eventBus,
                Debugger: this.Debugger,
                configSignature: _.get(args, 'configSignature', null),
                scheduleEndpoint: _.get(args, 'scheduleEndpoint', null),
                loggerEndpoint: _.get(args, 'loggerEndpoint', null)
            });
            if (this.debug.active) {
                this.debug.log(state, 'configSignature', this._configManager._configSignature);
                this.debug.log(state, 'scheduleEndpoint', this._configManager._scheduleEndpoint);
                this.debug.log(state, 'loggerEndpoint', this._configManager._loggerEndpoint);
            }

            this._networkingManager = new NetworkingManager({
                bus: this._eventBus,
                channelId: this.channelId,
                playlistId: this._playlistId,
                locationManager: this._locationManager,
                configManager: this._configManager,
                sessionId: this._sessionId,
                debugId: this._debugId,
                commonParams: _.get(args, 'commonParams', {}),
                Debugger: this.Debugger,
                stateManager: this._stateManager,
                testVideoGroup: this._testVideoGroup,
                preTest: this._preTest,
                runMode: this._runMode,
                zipWoeid: _.get(args, 'zipWoeid', null),
                teamIds: _.get(args, 'teamIds', [])
            });
            if (this.debug.active) {
                _.each(this._networkingManager.getCommonParams(), (value, key) => {
                    this.debug.log(state, key, value);
                });
            }

            this._dataManager = new DataManager({
                bus: this._eventBus,
                channelId: this.channelId,
                locationManager: this._locationManager,
                networkingManager: this._networkingManager,
                sessionId: this._sessionId,
                Debugger: this.Debugger,
                stateManager: this._stateManager,
                watchTokens: this._watchTokens,
                containerNode: _.get(args, 'chyronContainerElem', _.get(args, 'containerNode', null)),
                themeManager: this._themeManager,
                debugCountdownSeconds: _.get(_querystring, 'vemdcdus', null),
                debugPlaylistLoopSeconds: _.get(_querystring, 'vemdplus', null)
            });
            if (this.debug.active) {
                this.debug.log(state, 'watchTokens', this._dataManager._watchTokens);
                this.debug.log(state, 'chyronContainer', this._dataManager.getContainerNode()?.id);
                // this.debug.log(state, 'debugCountdownSeconds', this._dataManager._debugCountdownSeconds);
            }

            this._alertManager = new AlertManager({
                bus: this._eventBus,
                dataManager: this._dataManager,
                locationManager: this._locationManager,
                Debugger: this.Debugger
            });

            this._schedulingManager = new SchedulingManager({
                bus: this._eventBus,
                dataManager: this._dataManager,
                Debugger: this.Debugger
            });

            this._playerManager = new PlayerManager({
                videoplayer: _.get(args, 'videoplayer', _.get(args, 'videoPlayer', _.get(args, 'playerInstance', null))),
                bus: this._eventBus,
                dataManager: this._dataManager,
                Debugger: this.Debugger,
                stateManager: this._stateManager,
                locationManager: this._locationManager,
                playerConfig: _.get(args, 'playerConfig', null)
            });
            if (this.debug.active) {
                this.debug.log(state, 'playerInstance', !!this._playerManager.getPlayer());
                this.debug.log(state, 'playerConfig', !!this._playerManager._playerConfig);
            }

            this._sectionManager = new SectionManager({
                bus: this._eventBus,
                dataManager: this._dataManager,
                playerManager: this._playerManager,
                Debugger: this.Debugger
            });

            this._entityManager = new EntityManager({
                bus: this._eventBus,
                dataManager: this._dataManager,
                playerManager: this._playerManager,
                Debugger: this.Debugger
            });

            if (this._watchHistory) {
                this._historyManager = new HistoryManager({
                    bus: this._eventBus,
                    dataManager: this._dataManager,
                    playerManager: this._playerManager,
                    threshold: 90,
                    expiration: 259200,
                    Debugger: this.Debugger
                });
                this.debug.log(state, 'WatchHistory is enabled');
            }

            this._metricsManager = new MetricsManager({
                bus: this._eventBus,
                channelId: this.channelId,
                sessionId: this._sessionId,
                debugId: this._debugId,
                vemVersion: this.version,
                dataManager: this._dataManager,
                configManager: this._configManager,
                alertManager: this._alertManager,
                Debugger: this.Debugger,
                devLogs: Boolean(Number(_.get(_querystring, 'vemddl', 0))) || _.get(args, 'devLogs', false),
                rapidInstance: this._rapidInstance,
                stateManager: this._stateManager,
                runMode: this._runMode,
                site: this._networkingManager.getCommonParams().site
            });

            this._eventBus.subscribe(constants.events.DATA_UPDATED, this._onDataUpdated.bind(this));
            this._eventBus.subscribe(constants.events.DATA_ERROR, this._onDataError.bind(this));
            this._eventBus.subscribe(constants.events.CHYRON_TAPPED, this._onAlertAction.bind(this));
            this._eventBus.subscribe(constants.events.VIDEO_SELECT_TAPPED, this._onVideoSelectAction.bind(this));
            this._eventBus.subscribe(constants.events.SCH_VIDEO_PLAYBACK, this._onScheduledVideoPlaybackStart.bind(this));
            this._eventBus.subscribe(constants.events.SCH_VIDEO_PLAYBACK_FAILED, this._onScheduledVideoPlaybackFailed.bind(this));
            this._eventBus.subscribe(constants.events.LOCATION_UPDATED, this._onLocationUpdated.bind(this));
            this._eventBus.subscribe(constants.events.LOCATION_NOT_CHANGED_ENOUGH, this._onLocationNotChangedEnough.bind(this));
            this._eventBus.subscribe(constants.events.LOCATION_DENIED, this._onLocationDenied.bind(this));
            this._eventBus.subscribe(constants.events.SHOW_VIDEO_SELECT, this.showVideoSelection.bind(this));
            this._eventBus.subscribe(constants.events.PLAYBACK_COMPLETE, this._onPlaybackComplete.bind(this));
            this._eventBus.subscribe(constants.events.VIDEOPLAYER_ATTACHED, this._onVideoplayerAttached.bind(this));
            this._eventBus.subscribe(constants.events.STATE_CHANGE, this._onStateChanged.bind(this));
            this._eventBus.subscribe(constants.events.ALERT_START, this._onAlertStart.bind(this));
            this._eventBus.subscribe(constants.events.SCH_EVENT_INIT, this._onSchedule.bind(this));
            this._eventBus.subscribe(constants.events.COUNTDOWN_COMPLETE, this._onCountdownComplete.bind(this));
            this._eventBus.subscribe(constants.events.PLAYBACK_WILL_START, this._onPlaybackWillStart.bind(this));
            this._eventBus.subscribe(constants.events.VIDEOPLAYER_ERROR, this._onVideoplayerError.bind(this));

            this._stateManager.setState('LOADED');

            this._await || this.init();

        }

    }
    /**
     * @param {Object} [videoExperiences=null] - Response from a schedule api call
     * @fires event:INITIALIZE
     */
    async init(videoExperiences=null) {
        // get videoExperiences from either...
        // 1. the args from this function
        // 2. the return value from the clients initializer
        // 3. the vem constructor config
        let state = this._stateManager.getStateString(this._stateManager.setState('INITIALIZING'));
        this.debug.log(state, 'videoExperiences (incoming argument)', String(Boolean(videoExperiences)));
        // if provided, run the client initializer allowing them to wire up handlers and return videoExperiences
        if (this._initializer && (typeof(this._initializer) === 'function')) {
            this.debug.info(state, 'client initializer', 'loading');
            // if the client returns videoExperiences then those take precedence
            videoExperiences = this._initializer(this) || videoExperiences;
            this.debug.info(state, 'client initializer', 'loaded');
            this.debug.log(state, 'videoExperiences (client initializer)', String(Boolean(videoExperiences)));
        }
        // if no videoExperiences are present then check the vem constructor config
        videoExperiences = videoExperiences || _.get(this, '_videoExperiences', videoExperiences);
        const locationRequestServer = _.get(videoExperiences, 'state.requestLocation', false);
        const currentLocation = this._locationManager.getCurrentLocation();

        this._eventBus.init();

        // block for remote configuration options
        if (!this._configManager._scheduleEndpoint) {
            try {
                await this._networkingManager.fetchRemoteConfig();
            } catch (error) {
                this.debug.log('ERROR', error);
                // without the remote config we cannot continue
                return false;
            }
        } else {
            this.debug.log(state, 'schedule endpoint found', 'skipping remote config fetch');
        }

        if (this._playerManager._playerConfig) {
            this.debug.log(state, 'managing player construction');
            try {
                await this._playerManager.constructPlayer();
            } catch (error) {
                console.log('ERROR', error);
                // without the video player we cannot continue
                return false;
            }
        }

        this._onInitialized(videoExperiences);

        if (locationRequestServer) {
            this._stateManager.setState('REQUEST_LOCATION');
            if (currentLocation) {
                this._networkingManager.fetchVideoExperiences();
            } else {
                this._dataManager.setVideoExperiences(videoExperiences);
            }
        } else {
            this._stateManager.setState('RUNNING');
            if (videoExperiences) {
                this._dataManager.setVideoExperiences(videoExperiences);
            } else {
                this._networkingManager.fetchVideoExperiences();
            }
        }
    }
    /**
     * Destroy the VEM and clear all event handlers and other objects.
     */
    destroy() {
        this.debug.log('destroy');

        this._eventBus.destroy();

        this._dataManager.destroy();
        this._dataManager = null;
        this._schedulingManager.destroy();
        this._schedulingManager = null;
        this._alertManager.destroy();
        this._alertManager = null;
        this._playerManager.destroy();
        this._playerManager = null;
        this._networkingManager.destroy();
        this._networkingManager = null;

        // these need destroy methods implemented
        this._locationManager = null;
        this._sectionManager = null;
        this._entityManager = null;
        if (this._historyManager) {
            this._historyManager = null;
        }

        if (this._chyron) {
            this._chyron.destroy();
        }

        this._destroyed = true;

    }

    /**************************************************************************
        Messaging
    **************************************************************************/
    /**
     * Register the event listeners for VEM's events.
     * @param event {String} Event name. One of VEM.events.
     * @param listener {Function} Callback function that will be called when the event occurs.
     * @returns {} Reference to assigned handler
     */
    addListener(event, listener) {
        return this._eventBus.subscribe(event, listener);
    }
    /**
     * Register the event listeners for VEM's events.
     * @param event {String} Event name. One of VEM.events.
     * @param listener {Function} Callback function that will be called when the event occurs.
     * @returns {} Reference to assigned handler
     */
    on(event, listener) {
        return this.addListener(event, listener);
    }
    /**
     * Removes a single handler from the eventBus
     * @param event {String} Event name. One of VEM.events.
     * @param handler {Function} The handler returned from subscribing using 'VEM.on'
     */
    removeListener(event, handler) {
        this._eventBus.unsubscribe(event, handler);
    }

    /**************************************************************************
        Data Retrieval
    **************************************************************************/
    /**
     * Returns an array containing the available playlist
     * @returns {array} playlist videos
     */
    getPlaylist() {
        return this._dataManager.getPlaylist();
    }
    _getPlaylistIds(playlist) {
        var list = [];
        if (playlist) {
            for (var i = 0; i < playlist.length; i++) {
                var item = playlist[i];
                list.push(item.videoId);
            }
        }
        return list;
    }

    /**************************************************************************
        Location
    **************************************************************************/
    /**
     * Sets the location for the user as an object with either latitude/longitude values or woeid
     * @param {*} location Object that contains latitude/longitude fields or just pass false if location is denied.
     * @param {*} error Object that contains error information
     */
    setLocation(location=null, error=null) {
        if (!location) {
            this.debug.log('setLocation', 'error', error);
            // TODO: if there is no location we should look at the error code
            // to determine what message to display to user.
            // GeoPositionError object is "special" so make a copy or we won't
            // be able to use it. It doesn't like to be cloned, assigned or
            // conditionally checked. Could not find much information on this.
            if (error && error.code) {
                error = {
                    code: error.code,
                    message: error.message
                };
            }
            this._locationManager.setDenied(error);
        } else {
            this.debug.log('setLocation', 'location', (location ? 'found' : 'missing'), JSON.stringify(location));
            this._locationManager.setCurrentLocation(location);
        }
    }
    _requestLocation(alert=null, video=null) {
        const locationAlert = (alert && (alert.actionName === Alert.ACTION_NAMES.RequestLocation)) || this._alertManager.getLocationAlert();
        this.debug.log('_requestLocation', 'locationAlert', !!locationAlert);
        if (locationAlert) {
            const shouldShowChyron = this._shouldShowChyron[locationAlert.actionName];
            this.debug.log('_requestLocation', 'shouldShowChyron', shouldShowChyron);
            if (shouldShowChyron) {
                this._showChyron(locationAlert);
                this.debug.log('_requestLocation', 'video', !!video);
                if (video) {
                    // if a stream is about to start and needs location
                    this._waitForLocationVideo = video;
                    this._playerManager.saveCurrentPlaylist();
                }
                this._eventBus.publish(VEM.events.onAlertStart, locationAlert);
            }
        }
    }

    /**************************************************************************
        Countdown
    **************************************************************************/
    updateLiveCountdown() {
        if (this._liveCountdown) {
            const countdownTimerEndTime = this._dataManager.getNextCountdown(this.getCurrentPlayingId());
            if (countdownTimerEndTime && ((countdownTimerEndTime.valueOf() > Date.now()))) {
                if (this._countdown) {
                    this._countdown.update(countdownTimerEndTime);
                } else {
                    this._createCountdownInterface(_.first(this.getUpcomingVideos()), 'liveEvent', countdownTimerEndTime);
                }
            } else {
                this._destroyCountdownInterface();
            }
        }
    }
    _createCountdownInterface(video, type, until) {
        this._hideChyron();
        this._destroyCountdownInterface();
        this._countdown = new Countdown({
            bus: this._eventBus,
            Debugger: this.Debugger,
            container: this._dataManager.getContainerNode(),
            video: video,
            type: type,
            until: until,
            themeManager: this._themeManager
        });
        this._countdown.create();
        this.debug.log('_createCountdownInterface', 'showing countdown', 'type', type);
        this._countdown.show();
    }
    _destroyCountdownInterface() {
        if (!_.isNull(this._countdown)) {
            this._countdown.destroy();
        }
    }
    _hideCountdown() {
        if (this._countdown) {
            this._countdown.destroy();
        }
    }

    /**************************************************************************
        Chyron
    **************************************************************************/
    /**
     * Sets whether chyron should be shown or not. Default is true.
     * If actionName is specified, only chyrons having that actionName will be affected.
     * @param {Boolean} enabled
     * @param {String} actionName
     */
    setShouldShowChyron(enabled, actionName) {
        this._shouldShowChyron = this._getShouldShowChyron(enabled, actionName);
        this._hideChyron();
    }
    /**
     * Sets setEnableFullChyron to use the full chyron
     * @param enabled
     */
    setEnableFullChyron(enabled) {
        this._enableFullChyron = enabled;
    }
    setEnableNFLLocationChyron(enabled) {
        this.setEnableFullChyron(enabled);
    }
    /**
     *
     * @param {Object} alert
     */
    _showChyron(alert) {
        this.debug.log('_showChyron', 'alert.actionName', alert.actionName);
        const containerNode = this._dataManager.getContainerNode();

        if (containerNode) {

            this._hideVideoSelection();
            this._hideCountdown();

            if (this._chyron) {
                this.debug.log('_showChyron', 'chyron found');
                if ((this._chyron.getType() === alert.type) && (this._chyron.getActionName() === alert.actionName)) {
                    this.debug.info('_showChyron', 'alerts match');
                    this._chyron.update(alert);
                } else {
                    this.debug.info('_showChyron', 'alerts differ');
                    this._chyron.hide();
                    this._chyron.destroy();
                }
            }

            const chyronDestroyed = _.isNull(_.get(this, '_chyron._node', null));
            if (!this._chyron || chyronDestroyed) {
                this.debug.log('_showChyron', 'chyron', (chyronDestroyed ? 'destroyed' : 'missing'));
                // 2020-11-24: EJF
                // Our LocationChyron is not a chyron at all, it's a modal dialog.
                //
                // The distinction here is that a 'chyron' should only cover
                // the lower-third whereas a 'prompt' (aka modal) floats above the
                // space and represents a window covering as much space as is required.
                //
                // We need to remove the _enableFullChyron property (previously
                // _enableNFLLocationChyron) altogether and migrate the LocationChyron
                // to a LocationPrompt as a subclass of Prompt. This would not only be
                // more proper but would then allow us to better handle that distinction
                // without all of the conditions.
                if (this._enableFullChyron && (alert.type === Alert.TYPES.CHYRON)) {
                    this.debug.log('_showChyron', 'LocationChyron');
                    this._chyron = new LocationChyron({
                        bus: this._eventBus,
                        container: containerNode,
                        alert: alert,
                        Debugger: this.Debugger,
                        themeManager: this._themeManager
                    });
                } else if (alert.type === Alert.TYPES.PROMPT) {
                    this.debug.log('_showChyron', 'PromptUser: ' + alert.actionName);
                    this._chyron = new PromptUser({
                        bus: this._eventBus,
                        container: containerNode,
                        alert: alert,
                        Debugger: this.Debugger,
                        themeManager: this._themeManager
                    });
                } else {
                    this.debug.log('_showChyron', 'Chyron');
                    this._chyron = new Chyron({
                        bus: this._eventBus,
                        container: containerNode,
                        alert: alert,
                        Debugger: this.Debugger,
                        themeManager: this._themeManager
                    });
                }
                this._chyron.create();
            }
            this._chyron.show();

            // use value===0 (default) to create a persistent chyron
            if (this._chyron._alert.duration !== 0) {
                this._alertTimer = setTimeout(this._onAlertFinished.bind(this), this._chyron._alert.duration * 1000);
            } else {
                clearTimeout(this._alertTimer);
                this._alertTimer = null;
            }
        } else {
            this.debug.log('_showChyron', 'containerNode', 'missing');
        }
    }
    /**
     * hides a chyron if one is found
     */
    _hideChyron() {
        if (this._chyron) {
            this._chyron.hide();
        }
    }
    /**
     * @returns LocationChyron|null
     */
    _getLocationChyron() {
        if (this._chyron && (this._chyron.getType() === 'location')) {
            return this._chyron;
        }
        return null;
    }
    /**
     * @param {Boolean} enabled
     * @param {String} actionName
     * @returns {Boolean}
     */
    _getShouldShowChyron(enabled, actionName) {
        const shouldShowChyron = this._shouldShowChyron || {};
        if (!actionName) {
            _.each(_.keys(Alert.ACTION_NAMES), (key) => {
                shouldShowChyron[Alert.ACTION_NAMES[key]] = enabled;
            });
        } else {
            shouldShowChyron[actionName] = enabled;
        }
        return shouldShowChyron;
    }
    /**
     * Used to surface a chyron with custom instructions
     * @param {String|Object} options
     * @returns {String} id - These are pre-defined and will end up matching
     *                        up with a theme config.
     */
    promptUser(options) {
        this.debug.log('promptUser', 'options', options);
        let ackId = null;

        if (_.get(options, 'id', null)) {
            const alert = new AlertPrompt(options);
            ackId = alert.ackId;
            this.debug.log('promptUser', 'ackId', ackId);
            this._showChyron(alert);
        } else {
            this.debug.log('promptUser', 'missing id');
        }

        return ackId;
    }

    /**************************************************************************
        Video Selection
    **************************************************************************/
    /**
     * Displays the video selection UI
     */
    showVideoSelection(payload) {
        if (this._shouldShowVideoSelection) {
            const videos = this.getLiveVideos();
            this._hideChyron();
            // only show video selection if there is more than one live video
            if (_.isEmpty(videos)) {
                this.debug.log('showVideoSelection', 'live videos missing');
            } else {
                this.debug.log('showVideoSelection', 'live videos found');
                if (this._videoSelection) {
                    this._updateVideoSelection(videos);
                } else {
                    const containerNode = this._dataManager.getContainerNode();
                    if (containerNode) {
                        this._createVideoSelection(videos, containerNode, {
                            autoHideTimeout: payload?.autoHideTimeout
                        });
                    } else {
                        this.debug.log('showVideoSelection', 'missing containerNode');
                    }
                }
                if (this._videoSelection && !this._videoSelection.isShown()) {
                    this._videoSelection.show(_.get(payload, 'userActivated', false));
                }
            }
        } else {
            this.debug.log('showVideoSelection', 'video selection disabled');
        }
    }
    updateVideoSelection() {
        const videos = this.getLiveVideos();
        if (this._videoSelection && this._videoSelection.isShown()) {
            this._updateVideoSelection(videos);
        }
    }
    _addOtherFeedsButton() {
        if (this._shouldShowVideoSelection) {
            this._playerManager.addOtherFeedsButton(_.size(this.getLiveVideos()) > 1);
        } else {
            this.debug.info('_addOtherFeedsButton', 'video selection disabled');
        }
    }
    _createVideoSelection(videos, containerNode, options) {
        if (videos && containerNode) {
            this._videoSelection = new VideoSelection({
                bus: this._eventBus,
                container: containerNode,
                videos: videos,
                autoHideTimeout: options?.autoHideTimeout || this._videoSelectionAutoHideTimeout,
                currentPlayingId: this.getCurrentPlayingId(),
                Debugger: this.Debugger,
                themeManager: this._themeManager
            });
            this._videoSelection.create();
        }
    }
    _updateVideoSelection(videos) {
        if (videos) {
            this._videoSelection.update(videos, this.getCurrentPlayingId());
        }
    }
    _hideVideoSelection() {
        if (this._videoSelection) {
            this._videoSelection.hide();
        }
    }
    _getVideoSelection() {
        if (this._videoSelection) {
            return this._videoSelection;
        }
        return null;
    }
    // show the video selection only for the first of multiple games
    _firstShowVideoSelection() {
        const videos = this.getLiveVideos();
        const hasMultipleGames = videos && this._hasMultipleGames(videos);
        if (this._shouldShowVideoSelection && this._shouldAutoShowVideoSelection) {
            if (hasMultipleGames) {
                this._shouldAutoShowVideoSelection = false;
                this.showVideoSelection();
            }
        } else {
            this.debug.log('_firstShowVideoSelection', 'video selection disabled');
        }
    }

    /**************************************************************************
        Player/Playback
    **************************************************************************/
    /**
     * Returns a VideoPlatform object
     */
    getVideoPlatform() {
        return this._playerManager.getVideoPlatform();
    }
    /**
     * Returns the player instance if there is one set
     * @returns {object} player
     */
    getPlayer() {
        return this._playerManager.getPlayer();
    }
    /**
     * Sets the player instance so that VEM can itself manage the switching between live video and playlist
     * As of Fall 2020 this method now takes in setPlayer calls while waiting
     * for a playerInstance. If one is detected and we are "waiting" then we
     * pass a flag to the PlayerManager.setPlayer indicating it should perform
     * a videoPlayer.render.
     * @param {object} player
     * @param {object} preventVideoStart
     */
    setPlayer(player, preventVideoStart=false) {
        // this.debug.log('setPlayer', 'player', player);
        if (player) {
            const locationPlayer = _.get(player, 'config.geoData', null);
            if (!this._locationManager.getCurrentLocation() && !_.isEmpty(locationPlayer)) {
                this.debug.log('setPlayer', 'location missing', 'using what was found in player');
                this.setLocation(locationPlayer);
            }
            this._playerManager.setPlayer(player, preventVideoStart, !!this._waitForPlayerVideo);
        } else {
            this.debug.log('setPlayer', 'player missing');
        }
    }
    /**
     * Removes the current player reference from VEM
     */
    removePlayer() {
        this._playerManager.destroy();
        this._waitForLocationVideo = null;
    }
    async respawnPlayer(playerInstance, metrics={}, mediaItems=[]) {
        metrics.respawnPlayerAttempts = this._respawnPlayerAttempts;
        metrics.respawnPlayerAttemptsThreshold = this._respawnPlayerAttemptsThreshold;
        this._eventBus.publish(constants.events.PLAYER_RESPAWN, metrics);
        this.debug.log('respawnPlayer', metrics.playerId, metrics.playerErrorType + '-' + metrics.playerErrorCode);
        
        if (this._respawnPlayerAttempts < this._respawnPlayerAttemptsThreshold) {
            mediaItems = _.size(mediaItems) && mediaItems || null;
            playerInstance = playerInstance || this.getPlayer();
            const playerConfig = this._playerManager.getPlayerConfig();
            let playerMediaItems = playerInstance?.playlist?.getItems() || [];
            playerMediaItems = _.size(playerMediaItems) && this._dataManager.getPlayerPlaylist(playerMediaItems) || null;
            let configMediaItems = playerConfig?.playlist?.mediaItems || [];
            configMediaItems = _.size(configMediaItems) && configMediaItems || null;
            playerConfig.playlist.mediaItems = mediaItems || playerMediaItems || configMediaItems;
            if (_.size(playerConfig.playlist.mediaItems)) {
                this.removePlayer();
                try {
                    this._respawnPlayerAttempts++;
                    await this._playerManager.constructPlayer(playerConfig);
                } catch (error) {
                    this.debug.log('ERROR', error);
                }
            } else {
                this.debug.log('ERROR', 'mediaItems missing');
            }
        } else {
            this.debug.log('ERROR', 'player respawn attempt threshold exceeded');
        }
    }
    getCurrentPlayingId() {
        return this._playerManager.getCurrentPlayingId();
    }
    /**
     * Used externally to play streams
     * @param {String|Object} options
     */
    playStream(options) {
        const logName = 'playStream';
        // get the stream id
        const id = _.isString(options) ? options : options.id;
        const location = _.get(options, 'location', null);
        const watchTokens = _.get(options, 'watchTokens', null);
        // find the video, based on the id type, in our existing dataset
        const idType = utils.isUUID(id) ? 'videoId' : 'gameId';
        const scheduledVideos = this.getScheduledVideos();
        const video = _.find(scheduledVideos, [idType, id]);
        const startTime = _.get(video, 'startTime', null);
        const guid = _.get(options, 'guid', null);
        const player = this.getPlayer();

        if (this.debug.active) {
            this.debug.log(logName, 'id', id);
            this.debug.log(logName, 'startTime', String(startTime));
            this.debug.info(logName, 'options', options);
            this.debug.info(logName, 'video', video);
        }

        if (video) {
            this.debug.log(logName, 'video', (video ? 'found': 'missing'), (video ? video.videoId : ''));
            // TBD: this should not surface if the game starts in less
            // than X number of seconds from now
            const tooEarly = video.startTime > Date.now();
            if (player) {
                // we are attempting to switch streams so pause playback
                player.pause();
            }
            if (tooEarly) {
                // start countdown screen
                this.debug.log(logName, 'video start time not reached');
                this._createCountdownInterface(video);
            } else {
                this._destroyCountdownInterface();
                this.debug.log(logName, 'location', (location ? 'found' : 'missing'), JSON.stringify(_.pick(location, ['latitude', 'longitude'])));
                if (location) {
                    this.setLocation(location);
                }
                this.debug.log(logName, 'watchTokens', (watchTokens ? 'found' : 'missing'));
                if (watchTokens) {
                    this.setWatchTokens(watchTokens);
                }
                this.debug.log(logName, 'player', (player ? 'found' : 'missing'));
                if (player) {
                    this._playScheduledVideo(video);
                } else {
                    // keep track of the stream we attempted to play
                    // once we get a player instance we can use it to continue
                    // playback
                    this._waitForPlayerVideo = video;
                    this._eventBus.publish(VEM.events.onRequestPlayerInstance, {
                        mediaItems: this._dataManager.getPlayerPlaylist(scheduledVideos, video.videoId),
                        location: location
                    });
                }
            }
        } else {
            this.debug.log(logName, 'video missing');
        }

        this._eventBus.publish(constants.events.API_PLAY_STREAM, {
            id: id,
            idType: idType,
            video: !!video,
            uuid: video?.videoId || video?.uuid || video?.id,
            guid: guid,
            player: !!player
        });
    }
    /**
     * Used externally to pause playback
     */
    pauseStream(clearPlaylist=false) {

        const player = this.getPlayer();
        if (player) {
            if (clearPlaylist) {
                player.playlist.clear();
            } 
            player.pause();
        }
    }
    _startPlaylist(playlist) {
        // this.debug.log('_startPlaylist', 'playlist', playlist);
        this._currentPlayingScheduledVideo = null;
        return this._playerManager.startPlaylist(playlist);
    }
    _startNextPlaylist() {
        const playlist = this.getPlaylist();
        // this.debug.log('_startNextPlaylist', 'playlist', playlist);
        if (this.getPlayer() && (playlist.length > 0)) {
            if (this._startPlaylist(playlist)) {
                return playlist;
            } else {
                return null;
            }
        }
        return null;
    }
    _currentPlaylistContains(videoId) {
        const currentPlaylist = this.getPlaylist();
        if (currentPlaylist) {
            for (const video of currentPlaylist) {
                if (video.videoId == videoId) {
                    return true;
                }
            }
        }
        return false;
    }
    _handlePlaylistStart(mediaItem) {
        // this.debug.log('_handlePlaylistStart');
        const currentPlaylist = this.getPlaylist();
        if (_.size(currentPlaylist)) {
            if (_.first(currentPlaylist).videoId === mediaItem.videoId) {
                this._eventBus.publish(VEM.events.onPlaylistStart, currentPlaylist);
            }
        }
    }
    _handlePlaylistComplete(playlist) {
        // this.debug.log('_handlePlaylistComplete');
        this._handlePlaylistLoopComplete();
        this._eventBus.publish(VEM.events.onPlaylistComplete, playlist);
    }
    _handlePlaylistLoopStart() {
        const logName = '_handlePlaylistLoopStart';
        const playlistLoopUntil = this._dataManager.getPlaylistLoopUntil();
        const isPlayingScheduledVideo = this.isPlayingScheduledVideo();
        const videoPlayer = this.getPlayer();

        if (isPlayingScheduledVideo) {
            this.debug.log(logName, 'playing scheduled video, disable looping');
            // If we are playing a scheduled video then the loop should be
            // stopped. its only purpose is to loop a playlist over-and-over
            // until a scheduled video starts.
            videoPlayer.controls.setLoop(false);
            videoPlayer.controls.setContinuousPlay(false);
        } else {
            // We always want continuousPlay to be true during vod playback
            // to ensure we continue playing all items in the current playlist
            videoPlayer.controls.setContinuousPlay(true);
            // If we are NOT playing a scheduled video then derive the loop
            // settings from the 'playlistLoopUntil' value
            if (playlistLoopUntil instanceof Date) {
                if (playlistLoopUntil.valueOf() > Date.now()) {
                    this.debug.log(logName, 'playing playlist video, enable looping');
                    videoPlayer.controls.setLoop(true);
                }
            }
        }
    }
    _handlePlaylistLoopComplete() {
        // The "playlist loop end" time will be programmed to happen after live
        // stream start. A live stream will clear out the playlist anyway so
        // that path is taken care of. We need to protect in here against
        // users walking away from the loop.
        const playlistLoopUntil = this._dataManager.getPlaylistLoopUntil();
        const isPlayingScheduledVideo = this.isPlayingScheduledVideo();
        const videoPlayer = this.getPlayer();
        
        if (playlistLoopUntil instanceof Date) {
            // trim off the milliseconds and only compare seconds because the
            // internal timer isn't that precise
            const now = new Date(Date.now()).setMilliseconds(0).valueOf();
            const until = playlistLoopUntil.setMilliseconds(0).valueOf();
            if (now >= until) {
                if (!isPlayingScheduledVideo) {
                    if (videoPlayer) {
                        this.debug.log('_handlePlaylistLoopComplete', 'emptying playlist');
                        // clear all but self
                        // we can't just clear all items, if we do then the metadata
                        // for the ui goes missing and causes all kinds of havoc
                        this._playerManager.emptyPlaylist(true);
                        // deactivate looping
                        videoPlayer.controls.setLoop(false);
                        videoPlayer.controls.setContinuousPlay(false);
                    }
                }
            }
        }
    }

    /**************************************************************************
        Scheduled/Live Videos
    **************************************************************************/
    /**
     * Returns an array containing the currently scheduled videos at any given time
     * @returns {array} of scheduled videos.
     */
    getScheduledVideos() {
        if (this._dataManager == null || this._dataManager.getScheduledVideos() == null) {
            return null;
        }
        const scheduledVideos = this._dataManager.getScheduledVideos();
        var scheduledVideosNotCompleted = [];
        if (scheduledVideos) {
            for (const video of scheduledVideos) {
                // Only return it if we know it hasn't been completed
                if (!this._isVideoIdCompleted(video.videoId)) {
                    scheduledVideosNotCompleted.push(video);
                }
            }
        }
        return scheduledVideosNotCompleted;
    }
    /**
     * Returns an array containing ONLY currently live videos at any given time
     * @returns {array} of currently live scheduled videos.
     */
    getLiveVideos() {
        const scheduledVideos = this.getScheduledVideos();
        var liveVideos = [];
        if (scheduledVideos) {
            var now = Date.now();
            for (const video of scheduledVideos) {
                if ((video.startTime.getTime() <= now) && !this._isVideoIdCompleted(video.videoId)) {
                    liveVideos.push(video);
                }
            }
        }
        return liveVideos;
    }
    /**
     * Returns an array containing ONLY upcoming live videos at any given time
     * @returns {array} of upcoming live scheduled videos.
     */
    getUpcomingVideos() {
        const scheduledVideos = this.getScheduledVideos();
        var upcomingVideos = [];
        if (scheduledVideos) {
            var now = Date.now();
            for (const video of scheduledVideos) {
                if (video.startTime.getTime() > now) {
                    upcomingVideos.push(video);
                }
            }
        }
        return upcomingVideos;
    }
    /**
     * Indicates whether the player is playing a scheduled event.
     * The app will likely want to check this value before setting an updated playlist on the player,
     * and defer that action if a scheduled event is playing.
     * @returns boolean
     */
    isPlayingScheduledVideo() {
        const currentPlayingId = this.getCurrentPlayingId();
        if (currentPlayingId && this._currentPlayingScheduledVideo) {
            return currentPlayingId === this._currentPlayingScheduledVideo.videoId;
        }
        return false;
    }
    _playScheduledVideo(video=null, manual=false) {
        this.debug.log('_playScheduledVideo', _.get(video, 'videoId', ''));
        this._playerManager.playScheduledVideo(video, manual);
        this._firstShowVideoSelection();
    }
    _startLivePlayback(video) {
        const logName = '_startLivePlayback';
        const locationRequestServer = this._stateManager.isActive('REQUEST_LOCATION');
        this.debug.log(logName, video.videoId);
        this.debug.log(logName, 'request location (server)', locationRequestServer);
        if (!locationRequestServer) {
            // Check that we've met conditions before starting playback
            // TBD: should there be a forcePlay check here ?
            const videoConditions = this._canLoadVideoWithConditions(video);
            const currentLocation = this._locationManager.getCurrentLocation();
            this.debug.log(logName, 'request location (video)', videoConditions.requireLocation);
            this.debug.log(logName, 'current location', currentLocation);
            this.debug.log(logName, 'require InstantApp', videoConditions.requireInstantApp);
            this.debug.log(logName, 'require SportsApp', videoConditions.requireSportsApp);
            this.debug.log(logName, 'canLoadVideo', videoConditions.canLoadVideo);
            if (videoConditions.canLoadVideo) {
                if ((locationRequestServer || videoConditions.requireLocation) && !currentLocation) {
                    this._requestLocation(null, video);
                } else {
                    this._playScheduledVideo(video);
                }
            } else {
                if (videoConditions.requireInstantApp || videoConditions.requireSportsApp) {
                    let alert = {};
                    if (videoConditions.requireInstantApp) {
                        alert = this._alertManager.getInstantAppAlert();
                    } else if (videoConditions.requireSportsApp) {
                        alert = this._alertManager.getSportsAppAlert();
                    }
                    if (this._shouldShowChyron[alert.actionName]) {
                        this._showChyron(alert);
                        this._eventBus.publish(VEM.events.onAlertStart, alert);
                    }
                }
            }
        }
    }
    _startNextScheduledVideo(blockedGameId) {
        // If we have a currently scheduled video, play it
        const logName = '_startNextScheduledVideo';
        const liveVideos = this.getLiveVideos();
        let result = null;
        if (liveVideos && liveVideos.length > 0) {
            this.debug.log(logName, 'uuid', _.get(item, 'videoId', ''));
            this.debug.log(logName, 'gameId', _.get(item, 'gameId', ''));
            var item = liveVideos[0];
            this.debug.log(logName, _.get(item, 'videoId', ''));
            if (item.gameId === blockedGameId) {
                this.debug.log('_startNextScheduledVideo', 'last/next gameId match, prevent playback');
                this._dataManager.dedupeScheduledVideosByGameId(blockedGameId);
                return result;
            }
            var conditions = this._canLoadVideoWithConditions(item);
            const currentLocation = this._locationManager.getCurrentLocation();
            const locationAllowed = !conditions.requireLocation || (conditions.requireLocation && currentLocation) ? true : false;
            // Show video selection for sports streams that don't require location chyron
            // (normal flow is to show location chyron first, then video selection, but in this case we don't need the location dialog), currently finance doesn't need this
            this.debug.log(logName, 'conditions met', conditions.canLoadVideo);
            this.debug.log(logName, 'request location (video)', conditions.requireLocation);
            this.debug.log(logName, 'current location', currentLocation);
            this.debug.log(logName, 'locationAllowed', locationAllowed);
            this.debug.log(logName, 'gameId', item.gameId);
            if (conditions.canLoadVideo && locationAllowed && item.gameId) {
                this._startLivePlayback(item);
                result = item;
            } else {
                const nextVideo = this._findNextScheduledVideo(liveVideos);
                if (nextVideo && nextVideo.forcePlay) {
                    this._startLivePlayback(nextVideo);
                    result = nextVideo;
                }
            }
        }
        return result;
    }
    _findNextScheduledVideo(liveVideos) {
        let result = null;
        if (!_.isEmpty(liveVideos)) {
            const prioritizedVideo = _.some(liveVideos, (video) => {
                return video.isPrioritized && (video.startTime.getTime() <= Date.now());
            });
            result = prioritizedVideo || liveVideos[0];
        }
        return result;
    }
    _addCompletedVideoId(videoId) {
        this.debug.log('_addCompletedVideoId', 'videoId', videoId);
        if (this._completedVideoIds === null) {
            this._completedVideoIds = {};
        }
        this._completedVideoIds[videoId] = true;
    }
    _isVideoIdCompleted(videoId) {
        if (this._completedVideoIds) {
            if (this._completedVideoIds[videoId] === true) {
                return true;
            }
        }
        return false;
    }
    _isVideoInList(video, list) {
        return _.some(list, (liveVideoItem) => {
            return liveVideoItem.videoId === video.videoId;
        });
    }
    _isVideoInLiveVideosList(video) {
        return this._isVideoInList(video, this.getLiveVideos());
    }
    _isVideoInScheduledVideosList(video) {
        return this._isVideoInList(video, this.getScheduledVideos());
    }
    _canLoadVideoWithConditions(video) {
        let canLoadVideo = true;
        let requireLocation = false;
        let requireCellular = false;
        let requireInstantApp = false;
        let requireSportsApp = false;
        if (video && video.conditions) {
            if (video.conditions.indexOf(ScheduledVideo.CONDITIONS.REQUIRE_LAT_LONG) !== -1) {
                requireLocation = true;
                if (this._locationManager.getDenied()) {
                    canLoadVideo = false;
                }
            }
            if (video.conditions.indexOf(ScheduledVideo.CONDITIONS.CELLULAR_ONLY) !== -1) {
                requireCellular = true;
            }
            if (video.conditions.indexOf(ScheduledVideo.CONDITIONS.REQUIRE_INSTANT_APP) !== -1) {
                requireInstantApp = true;
                canLoadVideo = false;
            }
            if (video.conditions.indexOf(ScheduledVideo.CONDITIONS.REQUIRE_SPORTS_APP) !== -1) {
                requireSportsApp = true;
                canLoadVideo = false;
            }
        }
        return {
            canLoadVideo: canLoadVideo,
            requireLocation: requireLocation,
            requireCellular: requireCellular,
            requireInstantApp: requireInstantApp,
            requireSportsApp: requireSportsApp
        };
    }
    /**
     * utility method used to determine if there are multiple live games based
     * on game id. this is used to filter out multiple live english and spanish
     * streams of the same game.
     * @param liveVideos {LiveVideos}
     */
    _hasMultipleGames(liveVideos) {
        var liveGames = [];
        if (liveVideos) {
            for (const video of liveVideos) {
                if (liveGames.indexOf(video.gameId) === -1) {
                    liveGames.push(video.gameId);
                }
            }
        }
        return liveGames.length > 1;
    }
    _isRespawnAllowed(playerErrorType, playerErrorCode) {
        const allowList = constants.PlayerRespawnAllowList;
        let allowedType = false;
        let allowedCode = false;
        for (let type in allowList) {
            if (type === playerErrorType) {
                allowedType = true;
                const codeList = allowList[type];
                codeList.forEach((code) => {
                    if ((code === playerErrorCode) || (code === '*')) {
                        allowedCode = true;
                    }
                });
            }
        }

        return allowedType && allowedCode;
    }

    /**************************************************************************
        Sections
    **************************************************************************/
    /**
     * Forces the playlist to start at the position of the mediaItem that
     * contains the provided section object. Note that only the first mediaItem
     * in the section group contains a section label.
     * @param sectionItem {section} The object for a specific section which can
     *                              be found in the list received from the
     *                              'getSectionList' call.
     */
    playSection(section) {
        this._sectionManager.playSection(section);
    }
    /**
     * Forces the playlist to start at the position of the mediaItem that
     * contains the provided sectionLabel. Note that only the first mediaItem
     * in the section group contains a section label.
     * @param sectionLabel {String} The label for the section
     */
    playSectionByLabel(sectionLabel) {
        return this._sectionManager.playSectionByLabel(sectionLabel);
    }
    /**
     * Forces the playlist to start at the position of the mediaItem that
     * is in the position matching the sectionIndex
     * @param sectionIndex {Number} The position in the playlist for the section
     */
    playSectionByPosition(sectionIndex) {
        return this._sectionManager.playSectionByPosition(sectionIndex);
    }
    /**
     * Returns an object containing all section items in the playlist
     * the items are in the same ordinal position as the playlist
     * @returns {SectionList}
     */
    getSectionList() {
        return this._sectionManager.getSectionList();
    }
    /**
     * Returns an object containing the mapped section labels with their
     * position in the playlist
     * @returns {SectionMap}
     */
    getSectionMap() {
        return this._sectionManager.getSectionMap();
    }
    /**
     * Returns a SectionItem for the currently playing mediaItem
     * @returns {SectionItem}
     */
    getSectionForCurrentItem() {
        return this._sectionManager.getSectionForCurrentItem();
    }
    /**
     * Returns a SectionItem for the next mediaItem in the playlist
     * @returns {SectionItem}
     */
    getSectionForNextItem() {
        return this._sectionManager.getSectionForNextItem();
    }
    /**
     * Returns a SectionItem based on the section label
     * @param sectionLabel {String} The label for the section. The list
     *                              of labels can be retrieved using
     *                              getSectionMap
     * @returns {sectionLabel}
     */
    getSectionByLabel(sectionLabel) {
        return this._sectionManager.getSectionByLabel(sectionLabel);
    }
    /**
     * Returns a SectionItem based on its ordinal position in the playlist
     * @param sectionPosition {Number} The index for the section relative to
     *                                 its position in the playlist. The list
     *                                 of indices can be retrieved using
     *                                 getSectionMap
     * @returns {SectionItem}
     */
    getSectionByPosition(sectionIndex) {
        return this._sectionManager.getSectionByIndex(sectionIndex);
    }

    /**************************************************************************
        Entities
    **************************************************************************/
    /**
     * Returns an object containing all entities in the playlist
     * @returns {EntityList}
     */
    getEntityList() {
        return this._entityManager.getEntityList();
    }
    /**
     * Returns an object containing current entity information
     * @returns {EntitiesObject}
     */
    getEntitiesForCurrentItem() {
        return this._entityManager.getEntitiesForCurrentItem();
    }
    /**
     * Returns an object containing all entities related to a mediaItem
     * @param videoId {String} The id/uuid/videoId of the mediaItem
     * @returns {EntitiesObject}
     */
    getEntitiesByMediaItemId(videoId) {
        return this._entityManager.getEntitiesByMediaItemId(videoId);
    }
    /**
     * Returns an object containing all entities related to a mediaItem
     * @param position {Number} The index/position of the mediaItem
     * @returns {EntitiesObject}
     */
    getEntitiesByPosition(position) {
        return this._entityManager.getEntitiesByPosition(position);
    }

    /**************************************************************************
        Segments/Markers
    **************************************************************************/
    /**
     * Returns updated title for currently playing video for the videoSegment
     * label passed in.
     * @param {String} videoSegmentLabel -
     * @returns {updatedVideoTitle}
     */
    getUpdatedTitle(videoSegmentLabel) {
        return this._playerManager.getUpdatedTitle(videoSegmentLabel);
    }

    /**************************************************************************
        Watch History
    **************************************************************************/
    /**
     * Returns an object containing all watched items
     * @returns {WatchHistory}
     */
    getWatchHistory() {
        let history = [];
        if (this._historyManager) {
            history = this._historyManager.getHistory();
        }
        return history;
    }

    /**************************************************************************
        Helpers
    **************************************************************************/
    /**
     * stub method for older clients that are still calling veModule.log
     * @deprecated since version 1.0
     * @todo remove this when no other clients are making this call
     */
    log() {}
    /**
     * Sets whether the VEM should automatically load and populate the player with a playlist after a scheduled video ends (default is true).
     * When scheduled video ends, VEM will fire the event onScheduledVideoComplete.
     * If this is set to false, it is up to the app to handle what happens to the player after the scheduled video finishes.
     * @param enabled {boolean}
     */
    setShouldLoadPlaylistAfterScheduledVideo(enabled) {
        this._shouldLoadPlaylistAfterScheduledVideo = enabled;
    }
    /**
     * Returns the session ID for this VEM instance which can be used for logging purposes
     * @returns {String} sessionId
     */
    getSessionId() {
        return this._sessionId;
    }
    getScheduleUrl() {
        return this._configManager._scheduleEndpoint;
    }
    getConfigUrl() {
        return this._configManager._configSignature;
    }
    getNextFetchTime() {
        return this._dataManager.getNextFetchTime();
    }
    setNextFetchTime(date) {
        this._dataManager.setNextFetchTime(date);
        this._networkingManager._scheduleRefetch();
    }
    getTestVideoGroup() {
        return this._networkingManager.getTestVideoGroup();
    }
    setTestVideoGroup(group) {
        this._networkingManager.setTestVideoGroup(group);
    }
    getCommonParams() {
        return this._dataManager.getCommonParams();
    }
    setCommonParams(params) {
        this._dataManager.setCommonParams(params);
    }
    setWatchTokens(tokens) {
        this._dataManager.setWatchTokens(tokens);
    }
    getWatchTokens() {
        return this._dataManager.getWatchTokens();
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onInitialized(videoExperiences) {
        this._initializedTime = Date.now();
        // this.debug.log('videoExperiences', videoExperiences);

        const state = this._stateManager.getStateString();
        const playlistCount = _.size(_.get(videoExperiences, 'playlist'));
        const scheduledVideos = _.get(videoExperiences, 'scheduled_videos');
        const scheduledVideosCount = _.size(scheduledVideos);
        const alerts = _.get(videoExperiences, 'alerts');
        const alertsCount = _.size(alerts);
        const locationRequestServer = _.get(videoExperiences, 'state.requestLocation', false);
        const currentLocation = this._locationManager.getCurrentLocation();

        if (this.debug.active) {
            this.debug.log(state, 'videoExperiences (constructor config)', String(Boolean(_.get(this, '_videoExperiences', false))));
            if (videoExperiences) {
                this.debug.log(state, 'videoExperiences found');
                this.debug.log(state, 'scheduled_videos[' + scheduledVideosCount + ']', 'playlist[' + playlistCount + ']', 'alerts[' + alertsCount + ']');
                _.each(scheduledVideos, (item, index) => {
                    const date = new Date(_.get(item, 'date')).toString();
                    this.debug.log(state, 'scheduled video[' + index + ']', _.get(item, 'uuid'), date)
                });
                _.each(alerts, (item, index) => {
                    this.debug.log(state, 'alert[' + index + ']', _.get(item, 'action.name'))
                });
                this.debug.log(state, 'schedule state', _.get(videoExperiences, 'state'));
                this.debug.log(state, 'videoExperiences', videoExperiences);
                this.debug.log(state, 'intercept', _.get(videoExperiences, 'intercept', false));
                this.debug.log(state, 'request location (server)', locationRequestServer);
            } else {
                this.debug.log(state, 'videoExperiences missing');
            }
            this.debug.log(state, 'current location', currentLocation);
            if (this._vemsData) {
                const slaData = this._metricsManager._getSlaData(_.assign({}, this._vemsData, {
                    sla_trt_if_vem: this._startTime,
                    sla_trt_et: this._initializedTime
                }));
                // this prints the url used to make the server-side call to
                // the schedule api
                this.debug.info(state, 'vems schedule signature: ', _.get(this._vemsData, 'scheduleSignature', ''));
                this.debug.info(state, 'sla: start -> vems: ', slaData.startTime_to_vemsTime, '(' + slaData.startTime_to_vemsTime + ')');
                this.debug.info(state, 'sla: vems -> page: ', slaData.vemsTime_to_pageTime, '(' + slaData.startTime_to_pageTime + ')');
                this.debug.info(state, 'sla: page -> vem: ', slaData.pageTime_to_vemTime, '(' + slaData.startTime_to_vemTime + ')');
                this.debug.info(state, 'sla: vem -> end: ', slaData.vemTime_to_endTime, '(' + (slaData.totalRoundTrip) + ')');
                this.debug.log(state, 'sla: total round trip: ', slaData.totalRoundTrip);
            }

        }

        const initPayload = {
            locationRequestServer: Number(locationRequestServer),
            currentLocation: Number(!!currentLocation),
            videoPlayer: Number(!!this._playerManager.getPlayer()),
            videoExperiences: Number(!!videoExperiences),
            scheduledVideosCount: scheduledVideosCount,
            playlistCount: playlistCount,
            alertsCount: alertsCount,
            startTime: this._startTime,
            initializedTime: this._initializedTime
        };

        this._eventBus.publish(constants.events.INITIALIZE, initPayload);
        this._eventBus.publish(vemEvents.onInitialized, initPayload);

        this._themeManager.preLoadAssets();

    }
    _onDataUpdated() {
        // This event has never historically contained any payload
        const logName = '_onDataUpdated';
        const currentState = this._stateManager.getState();
        const previousState = this._stateManager.getPreviousState();
        const locationRequestServer = this._stateManager.isActive('REQUEST_LOCATION');
        const waitForLocationVideo = !!this._waitForLocationVideo;
        const locationRequestVideo = waitForLocationVideo || _.get(this._canLoadVideoWithConditions(this._findNextScheduledVideo(this.getScheduledVideos())), 'requireLocation', false);
        const currentLocation = this._locationManager.getCurrentLocation();
        const currentPlayingId = this.getCurrentPlayingId();
        const requireInstantApp = this._canLoadVideoWithConditions(_.first(this.getScheduledVideos())).requireInstantApp;
        const requireSportsApp = this._canLoadVideoWithConditions(_.first(this.getScheduledVideos())).requireSportsApp;

        this.debug.log(logName, 'state', this._stateManager.getStateString(currentState) + ' <- ' + this._stateManager.getStateString(previousState));
        this.debug.log(logName, 'current player uuid', currentPlayingId);
        this.debug.log(logName, 'current location', currentLocation);
        this.debug.log(logName, 'request location (server)', locationRequestServer);
        this.debug.log(logName, 'request location (video)', locationRequestVideo, (locationRequestVideo ? locationRequestVideo.videoId : null));
        this.debug.log(logName, 'require InstantApp', requireInstantApp);
        this.debug.log(logName, 'require SportsApp', requireSportsApp);

        this._eventBus.publish(VEM.events.onDataLoaded);

        // If first video requires instant app then show the Alert and do
        // nothing else.
        if (requireInstantApp || requireSportsApp) {
            // Because we are in INSTANT_APP mode, we cannot show any live
            // videos. Setting the state to INSTANT_APP or SPORTS_APP will
            // pause the program.
            let alert = {};
            if (requireInstantApp) {
                this._stateManager.setState('INSTANT_APP');
                alert = this._alertManager.getInstantAppAlert();
            } else if (requireSportsApp) {
                this._stateManager.setState('SPORTS_APP');
                alert = this._alertManager.getSportsAppAlert();
            }
            if (this._shouldShowChyron[alert.actionName]) {
                this._showChyron(alert);
                this._eventBus.publish(VEM.events.onAlertStart, alert);
            }
        } else {
            // There are 2 paths that can be followed here:
            // (1) vem.state.REQUEST_LOCATION
            //     During this state we are waiting for location. So we need to
            //     handle both cases where we either have location of we do not
            //     have location.
            // (2) vem.state.RUNNING
            //     This is the normal(default) flow.
            if (locationRequestServer) {
                if (currentLocation) {
                    const playerPayload = {
                        mediaItems: this._dataManager.getPlayerPlaylist(this.getLiveVideos(), true),
                        location: this._locationManager.getCurrentLocation()
                    }
                    this.debug.log(logName, 'wait for location resolved', playerPayload);
                    this._stateManager.setState('RUNNING');
                    this._hideChyron();
                    // This is the signal to clients that follow the force location
                    // path. The REQUEST_LOCATION state indicates we paused the
                    // program for input. If we are now RUNNING and were previously
                    // REQUEST_LOCATION then broadcast an event letting the client
                    // know that we are RUNNING again and send a payload of our
                    // current location data as well as a list of player ready
                    // mediaItems.
                    this._eventBus.publish(vemEvents.onWaitForLocationResolved, playerPayload);
                    this._eventBus.publish(constants.events.LOCATION_REQUEST_SERVER_RESOLVED, {
                        scheduledVideosCount: _.size(this.getScheduledVideos()),
                        liveVideosCount: _.size(this.getLiveVideos()),
                        mediaItem: playerPayload.mediaItems[0].id,
                        currentLocation: !!playerPayload.location
                    });
                } else {
                    this.debug.log(logName, 'wait for location');
                    // if no location yet and no alert has been thrown
                    // then send the alert
                    this.debug.log(logName, '-> request location');
                    if (!this._chyron || !this._chyron.isShown()) {
                        this.debug.log(logName, '-> attempting to show request location chyron');
                        this._requestLocation();
                    }
                }
            } else {
                this.updateLiveCountdown();
                this.updateVideoSelection();
                // follow the normal(default) path. If there is nothing in the
                // player, attempt to start with playlist items.
                if (_.isNull(currentPlayingId)) {
                    this.debug.log(logName, 'current stream missing');
                    if (this._shouldLoadPlaylistAfterScheduledVideo) {
                        this._startNextPlaylist();
                    } else {
                        this.debug.log(logName, 'playlist loading disabled');
                    }
                } else {
                    this.debug.log(logName, 'current stream found', currentPlayingId);
                }
            }
        }

    }
    _onDataError(error) {
        // always print errors from this event
        this.debug.error('_onDataError', 'error', error);
        this._eventBus.publish(VEM.events.onDataError, error);
    }
    _onVideoplayerAttached(preventVideoStart) {
        const logName = '_onVideoplayerAttached';
        this.debug.log(logName, 'preventVideoStart', preventVideoStart, 'waitForPlayerVideo', !!this._waitForPlayerVideo);

        // attempt to add the stream picker button to the player interface
        this._addOtherFeedsButton();

        if (this._waitForPlayerVideo) {
            // if we have a waitForPlayerVideo then we received this event
            // because we requested a player instance
            this._playScheduledVideo(this._waitForPlayerVideo);
            this._waitForPlayerVideo = null;
        } else {
            if (!preventVideoStart) {
                this._startNextScheduledVideo();
            }
        }
    }
    /***
     * @vzmi/videoplayer-common-lib/src/error-codes.ts
     */
    _onVideoplayerError(payload) {
        const logName = '_onVideoplayerError';
        const error = payload.error;
        const player = payload.player;
        this.debug.log(
            logName,
            'type', error.type,
            'code', error.code,
            'isRecoverable', error.isRecoverable
        );
        const playerErrorType = String(error.type);
        const playerErrorCode = String(error.code);
        const respawnPlayerLevel = this._respawnPlayer;
        const respawnPlayerLevels = constants.RespawnPlayerLevels;
        const isPlayingScheduledVideo = this.isPlayingScheduledVideo();
        const liveStreamEnded = (Number(playerErrorType) === '200') && (Number(playerErrorCode) === '800');
        const respawnAllowed = this._isRespawnAllowed(playerErrorType, playerErrorCode);
        const metrics = {
            respawnPlayerLevel: respawnPlayerLevel,
            respawnAllowed: respawnAllowed,
            playerErrorType: playerErrorType,
            playerErrorCode: playerErrorCode,
            isPlayingScheduledVideo: isPlayingScheduledVideo,
            liveStreamEnded: liveStreamEnded,
            playerVersion: player.version,
            playerId: player.config.playerId
        };
        console.log(logName, 'metrics', metrics);

        if (respawnAllowed) {
            if (respawnPlayerLevel === respawnPlayerLevels.off) {
                this.debug.log(logName, 'respawn disabled(off)');
            } else {
                if (liveStreamEnded) {
                    // This is not really an error but a signal that the live stream
                    // from sapi has ended.
                    this.debug.log(logName, 'live stream ended');
                    this._onPlaybackComplete(this._currentPlayingScheduledVideo);
                    if (respawnPlayerLevel === respawnPlayerLevels.liveOnly) {
                        this.debug.log(logName, 'respawn disabled');
                    } else {
                        this.debug.log(logName, 'respawn enabled');
                        // we are transitioning from live to vod based on an fatal
                        // error so we need to respawn using the next playlist
                        this.respawnPlayer(player, metrics, this._dataManager.getPlayerPlaylist());
                    }
                } else {
                    if (respawnPlayerLevel === respawnPlayerLevels.on) {
                        this.respawnPlayer(player, metrics);
                    } else {
                        if (
                            (respawnPlayerLevel === respawnPlayerLevels.liveOnly)
                            &&
                            isPlayingScheduledVideo
                        ) {
                            this.respawnPlayer(player, metrics);
                        } else if (
                            (respawnPlayerLevel === respawnPlayerLevels.vodOnly)
                            &&
                            !isPlayingScheduledVideo
                        ) {
                            this.respawnPlayer(player, metrics);
                        }
                    }
                }
            }
        } else {
            this.debug.log(logName, 'respawn not allowed');
        }
        this._eventBus.publish(VEM.events.onVideoplayerError, payload);
    }
    _onPlaybackWillStart(mediaItem) {
        this._handlePlaylistStart(mediaItem);
        this._handlePlaylistLoopStart();
    }
    _onPlaybackComplete(mediaItem) {
        const logName = '_onPlaybackComplete';
        const videoId = mediaItem.videoId;
        const currentPlayingScheduledVideo = this._currentPlayingScheduledVideo;
        const currentPlaylist = this.getPlaylist();
        // There are 2 paths in here
        // 1. scheduled video
        // 2. playlist video
        if (currentPlayingScheduledVideo) {
            // We've finished playing back a scheduled video
            if (currentPlayingScheduledVideo.videoId === videoId) {
                const currentGameId = currentPlayingScheduledVideo.gameId;
                this._addCompletedVideoId(videoId);
                this._addOtherFeedsButton();
                this._currentPlayingScheduledVideo = null;
                this._playerManager.setStartInBackground(false);
                this._onScheduledVideoPlaybackComplete(currentPlayingScheduledVideo);

                if (this._autoAdvanceOnSchedulePlaybackComplete) {
                    if (_.isEmpty(this.getLiveVideos())) {
                        this._scheduleActive = false;
                        this._onScheduleCompleted(currentPlayingScheduledVideo);
                    }
                    // Once a live event ends, first try to play another live event if one is available
                    const nextScheduledVideo = this._startNextScheduledVideo(currentGameId);
                    this.debug.log(logName, 'nextScheduledVideo', nextScheduledVideo);
                    if (_.isNull(nextScheduledVideo)) {
                        // If there is no live event, try a playlist
                        const playlist = this.getPlaylist();
                        // this.debug.log(logName, 'playlist', playlist);
                        if (this._shouldLoadPlaylistAfterScheduledVideo && !_.isEmpty(playlist)) {
                            // if a playlist exists then use it
                            this._startNextPlaylist();
                        } else {
                            // if no playlist, fetch to see if there are updates
                            this._networkingManager.fetchVideoExperiences();
                        }
                    }
                } else {
                    this.debug.log(logName, 'automatic playback disabled');
                }

            }

        } else {
            const player = this.getPlayer();
            if (player) {
                // The playlists here need to come from the player. When the
                // countdownTimerEndTime is reached we will delete items from
                // the player but not our data store.
                const currentPlayerPlaylist = player.playlist.getItems();
                const isInPlayerPlaylist = _.some(currentPlayerPlaylist, (value) => {return value === videoId})
                const isLastItemInPlayerPlaylist = _.last(currentPlayerPlaylist) === videoId;
                if (currentPlayerPlaylist && isInPlayerPlaylist && isLastItemInPlayerPlaylist) {
                    // this.debug.log(logName, 'last item in playlist');
                    // We just got done playing the last item in the playlist.
                    // We're using a playlist from the player right now but we
                    // want to send a payload that contains our VideoMetadata
                    // structure. Send only the items we have in our data store
                    // that match items that currently exist in the players
                    // playlist
                    const videoMetadata = [];
                    _.each(currentPlayerPlaylist, (uuid) => {
                        videoMetadata.push(_.find(currentPlaylist, ['videoId', uuid]));
                    });

                    this._handlePlaylistComplete(videoMetadata);

                    if (!this._dataManager.getNextCountdown(this.getCurrentPlayingId())) {
                        // We can now start playing the next available
                        // scheduled video
                        this._startNextScheduledVideo();
                    }
                }
            }
        }
    }
    _onLocationUpdated(location) {
        const logName = '_onLocationUpdated';
        const locationRequestServer = this._stateManager.isActive('REQUEST_LOCATION');

        this.debug.log(logName, 'current location', (location ? 'found' : 'missing'), JSON.stringify(location));
        this.debug.info(logName, 'request location (server)', locationRequestServer);
        this.debug.info(logName, 'request location (video)', this._waitForLocationVideo);
        this.debug.info(logName, 'current uuid', this.getCurrentPlayingId());

        const chyron = this._getLocationChyron();
        if (chyron) {
            if (locationRequestServer) {
                chyron.displayWaitForLocationMessage();
            } else {
                chyron.hide();
            }
        }

        // If we are in a REQUEST_LOCATION state, this is because...
        //
        // (A) We received a force location request
        //
        // requestLocation === true
        // this._waitForLocationVideo === (null || mediaItem)
        //
        // If we are in a REQUEST_LOCATION state then we do not want to play
        // anything because we need new schedule data first. Once we are
        // back in here with location we want to fetch new schedule data.
        //
        // (B) We are playing a stream that requires missing location
        //
        // requestLocation === false
        // this._waitForLocationVideo === mediaItem
        //
        // This is the case where we were already playing streams that did not
        // require location but got a schedule update with a video that does
        // require location which we do not have. During _requestLocation
        // the _waitForLocationVideo property was set with the video that
        // requires location. We are returning from the Alert and can now play.

        // In either scenario, update the PlayerManager with new location data.
        this._playerManager.updateLocation(location);

        this._eventBus.publish(vemEvents.onLocationUpdated, location);

        if (this._waitForLocationVideo && !locationRequestServer) {
            // Scenario (B)
            this._stateManager.setState('RUNNING');
            this._playScheduledVideo(this._waitForLocationVideo);
            this._waitForLocationVideo = null;
        } else if (locationRequestServer) {
            // Scenario (A)
            // we have been waiting for the devices location
            // now that we have it, refetch for updates
            this._networkingManager.fetchVideoExperiences();
        }

    }
    _onLocationNotChangedEnough(location) {
        this.debug.log('_onLocationNotChangedEnough', 'current location', location);
        const chyron = this._getLocationChyron();
        if (chyron) {
            chyron.hide();
        }
    }
    _onLocationDenied(error=null) {
        this.debug.log('_onLocationDenied', 'error', error);
        const locationRequestServer = this._stateManager.isActive('REQUEST_LOCATION');

        // If location chyron denied then we show "enable location
        // services" message
        const chyron = this._getLocationChyron();
        if (chyron) {
            chyron.displayLocationDeniedMessage();
        }

        if (locationRequestServer) {
            // There is no VideoPlayer loaded at this time and the timer in the
            // NetworkingManager that controls the fetches has been cleared.
            // Right now there is a LocationChyron up displaying a msg to the
            // user that they need to enable location to watch games.
            // There is no need to re-enable anything because the design is to
            // force user to reload in this case.
            // If '_waitForLocationVideo' is not set then this is happening due
            // to a schedule.state.requestLocation===true response
            // We need to send a rejection notice to the client so they can
            // handle this failure case.
            if (!this._waitForLocationVideo) {
                this._eventBus.publish(vemEvents.onWaitForLocationRejected, {
                    mediaItems: null,
                    location: null,
                    error: error
                });
            }
        }

        this._eventBus.publish(vemEvents.onLocationDenied, error);

    }
    _onAlertStart(alert) {
        const logName = '_onAlertStart';
        const locationRequestServer = this._stateManager.isActive('REQUEST_LOCATION');

        this.debug.log(logName, 'type:' + alert.type, 'name:' + alert.actionName, 'trigger:' + alert.actionTrigger);
        this.debug.info(logName, 'Alert.TYPES.CHYRON', Alert.TYPES.CHYRON);
        this.debug.info(logName, 'Alert.ACTION_NAMES.RequestLocation', Alert.ACTION_NAMES.RequestLocation);
        this.debug.info(logName, 'Alert.ACTION_TRIGGERS.AUTO', Alert.ACTION_TRIGGERS.AUTO);
        this.debug.info(logName, 'shouldShowChyron', this._shouldShowChyron[alert.actionName]);
        this.debug.log(logName, 'request location (server)', locationRequestServer);

        if (locationRequestServer) {
            // if we have this signal set then we surface the chyron no matter
            // what the other rules are
            this._requestLocation(alert);
        } else {
            if (this._shouldShowChyron[alert.actionName] && (alert.type === Alert.TYPES.CHYRON)) {
                if (alert.actionName === Alert.ACTION_NAMES.RequestLocation) {
                    // do not fire the location alert event since this will be fired right before we start video playback
                    return;
                }
            }
            if (alert.actionTrigger === Alert.ACTION_TRIGGERS.AUTO) {
                this._onAlertAction(alert);
            }
            this._eventBus.publish(VEM.events.onAlertStart, alert);
        }
    }
    _onAlertFinished() {
        this.debug.log('_onAlertFinished');
        if (this._alertTimer) {
            clearTimeout(this._alertTimer);
            this._alertTimer = null;
        }
        this._hideChyron();
    }
    _onAlertAction(payload) {
        const alert = payload.alert;
        this.debug.log('_onAlertAction');
        /*
        2021-12-14: EJF
        This is a workaround for an issue with AOL.
        When clicking on the StaticPromo an event is sent to the page
        indicating that a button on the Prompt UI was clicked. As a response at
        the page level, they are incorrectly attempting to handle this Alert as
        if it were a LocationPrompt.
        At the time the integration work was done there was only one(1) Alert
        type.
        */
       const site = this._networkingManager.getCommonParams().site;
       const actionRequireSportsApp = ScheduledVideo.CONDITIONS.REQUIRE_SPORTS_APP;
        if ((site === 'aol') && (alert.actionName === actionRequireSportsApp)) {
            this.debug.log('site', site);
            this.debug.log('skip throwing the onAlertAction for the require_sports_app condition on AOL');
        } else {
            this._eventBus.publish(VEM.events.onAlertAction, alert);
        }
    }
    _onVideoSelectAction(payload) {
        const video = payload.video;
        this.debug.log('_onVideoSelectAction', 'video', video);
        this._eventBus.publish(VEM.events.onVideoSelectAction, video);
        this._hideVideoSelection();
        this._startLivePlayback(video);
    }
    _onCountdownComplete(video) {
        // the default when a countdown completes is to play a provided video
        // 2021-04-22: if the liveCountdown flag is set then a live video is
        // starting and we don't need to manually play anything
        if (this._countdown) {
            this._countdown.destroy();
        }
        if (!this._liveCountdown) {
            this.playStream(video.videoId);
        }
        this._eventBus.publish(VEM.events.onCountdownComplete, video);
    }
    _onSchedule(item) {
        let logName = '_onSchedule';
        
        if (item) {
            let videoId = item.videoId;
            logName += ' ' + String(videoId);
            this.debug.log(logName, 'checking...');
            this.debug.log(logName, item.startTime.toString());
            this.debug.log(logName, 'type', item.type);
            // If we know this uuid is already done (via player event), don't attempt to start it up again
            // Not sure if this would ever happen, but catch it in case it does
            if (this._isVideoIdCompleted(item.videoId) || this._scheduledStartsNotified[item.videoId]) {
               this.debug.log(logName, 'completed or already notified');
               return;
            }
            // Only start playback of our scheduled event if:
            // 1. the video selection UI is not shown
            // 2. there is just one live video
            // 3. We have a player
            // 4. We aren't already playing a scheduled video (ie, don't replace the current one with a new one)
            // or we are playing a scheduled video and the incoming video is marked as isPrioritized
            // 5. The video should force play
            // 2019-?-?
            // Show video selection for sports streams that don't require location chyron
            // (normal flow is to show location chyron first, then video selection, but in this case we don't need the location dialog), currently finance doesn't need this
            // 2019-12-05
            // first item[0] in incoming scheduled_videos list is considered the priorityVideo unless another video in the list contains an isPrioritized=true flag in its meta data
            // 2020-12-11
            // we are not stopping playback for a video selection UI like stated above
            // although the checks were defined they were never used

            // attempt to add/update the other feeds button based on new items
            // being added to LiveVideos based on internal timers
            this._addOtherFeedsButton();

            const liveVideos = this.getLiveVideos();
            let startLivePlayback = false;

            if (this._currentPlayingScheduledVideo) {
                let currentlyPlayingScheduledVideoId = this._currentPlayingScheduledVideo.videoId;
                this.debug.log(logName, 'currently playing scheduled video is \'' + currentlyPlayingScheduledVideoId + '\'');
                if (currentlyPlayingScheduledVideoId === videoId) {
                    this.debug.log(logName, 'currently playing video matches this scheduled item, so keep playing');
                    startLivePlayback = false;
                } else {
                    let currentlyPlayingScheduledVideoForcePlay = _.get(_.find(liveVideos, ['videoId', currentlyPlayingScheduledVideoId]), 'forcePlay', false);
                    this.debug.log(logName, 'currently playing video does not match this scheduled item');
                    if (currentlyPlayingScheduledVideoForcePlay) {
                        this.debug.log(logName, 'currently playing video is forcePlay');
                        if (item.forcePlay && item.isPrioritized) {
                            this.debug.log(logName, 'this scheduled item is prioritized, so play this scheduled item');
                            startLivePlayback = true;
                        } else {
                            // make sure the currently playing video still exists in the live videos list
                            if (this._isVideoInLiveVideosList(this._currentPlayingScheduledVideo)) {
                                this.debug.log(logName, 'currently playing video exists in liveVideos');
                                if (currentlyPlayingScheduledVideoForcePlay) {
                                    this.debug.log(logName, 'currently playing video from liveVideos is forcePlay, so continue playing');
                                    startLivePlayback = false;
                                } else {
                                    // EJF: 2020-12-10
                                    // this was incorrect, we already know
                                    // that the incoming item is not force
                                    // play so we should continue playing
                                    // the existing stream
                                    this.debug.log(logName, 'currently playing video from liveVideos is not forcePlay and neither is this scheduled item, so continue playing');
                                    startLivePlayback = false;
                                }
                            } else {
                                this.debug.log(logName, 'currently playing video does not exist in liveVideos, so play this scheduled item');
                                startLivePlayback = true;
                            }
                        }
                    } else {
                        this.debug.log(logName, 'currently playing video is not forcePlay');
                        if (item.forcePlay && item.isPrioritized) {
                            this.debug.log(logName, 'this scheduled item is forcePlay and is prioritized, so play this scheduled item');
                            startLivePlayback = true;
                        } else {
                            this.debug.log(logName, 'this scheduled item is not forcePlay, so continue playing');
                            startLivePlayback = false;
                        }
                    }
                }
            } else {
                this.debug.log(logName, 'no scheduled video is playing');
                const priorityVideo = (_.size(liveVideos) > 0) ? this._findNextScheduledVideo(liveVideos) : item;
                if (this._stateManager.isActive('REQUEST_LOCATION')) {
                    this.debug.log(logName, 'request location (server), so do not play this scheduled item');
                } else {
                    if (item.forcePlay && (videoId === priorityVideo.videoId)) {
                        this.debug.log(logName, 'this scheduled video is forcePlay and is the priorityVideo, so play this scheduled item');
                        startLivePlayback = true;
                    } else {
                        this.debug.log(logName, 'this scheduled video is not forcePlay or priorityVideo, so do not play this scheduled item');
                    }
                }
            }
            this.debug.log(logName, 'should start live playback', startLivePlayback);

            // Only send 'onScheduledVideoStart' once per scheduled event. Make
            // sure we don't send it on subsequent data fetches for the same
            // instance of VEM.
            if (_.isUndefined(this._scheduledStartsNotified[videoId])) {
                this.updateVideoSelection();
                this._scheduledStartsNotified[videoId] = true;
                this._onScheduledVideoStart(item);
            }

            if (startLivePlayback) {
                this._startLivePlayback(item);
            }

        } else {
            this.debug.log(logName, 'item missing');
        }
    }
    _onScheduleStarted(videoMetadata) {
        this._eventBus.publish(VEM.events.onScheduleStarted, videoMetadata);
    }
    _onScheduleCompleted(videoMetadata) {
        this._eventBus.publish(VEM.events.onScheduleCompleted, videoMetadata);
    }
    // This publishes the 'onScheduledVideoStart' client event that indicates
    // the event has started. This does not indicate that the scheduled event
    // is playing, just that the 'startTime' has been reached.
    // The event that indicates playback has started is
    // 'onScheduledVideoPlaybackStart'.
    // TODO: rename this to _onScheduledEventStarted
    _onScheduledVideoStart(videoMetadata) {
        this.debug.log('_onScheduledVideoStart');
        this._eventBus.publish(VEM.events.onScheduledVideoStart, videoMetadata);
    }
    // TODO: rename this to _onScheduledEventCompleted
    _onScheduledVideoComplete(videoMetadata) {
        this.debug.log('_onScheduledVideoComplete');
        this._eventBus.publish(VEM.events.onScheduledVideoComplete, videoMetadata);
        if (this._refetchOnLiveEventComplete) {
            this._networkingManager.fetchVideoExperiences();
        }
    }
    // The difference between _onScheduledVideoPlaybackStart and _onVideoStart
    // is confusing for integrators. Maybe we could just use _onEventStart
    // which differentiates from the players sympathetic event called
    // onPlaybackStart and provide a new vem interface (leave legacy interfaces
    // alone). We could then add a "state" property (which represents the
    // metadata.type) that is backed by enums (pre,live,post) and could be used
    // to switch on within the handler.
    _onScheduledVideoPlaybackStart(payload) {
        this.debug.log('_onScheduledVideoPlaybackStart');
        // this.debug.log('_onScheduledVideoPlaybackStart', 'payload', payload);
        const video = payload.video;
        // keep track of the currently playing scheduled video id
        this._currentPlayingScheduledVideo = video;

        if (!this._scheduleActive) {
            this._scheduleActive = true;
            this._onScheduleStarted(video);
        }
        this._eventBus.publish(VEM.events.onScheduledVideoPlaybackStart, video);

        this._addOtherFeedsButton();
        
        if (this._isVideoInScheduledVideosList(video)) {
            // the incoming video is a scheduled video item
            const currentPlayingId = this.getCurrentPlayingId();
            if (currentPlayingId) {
                // there is a currently playing video
                if (currentPlayingId === video.videoId) {
                    // the incoming video has a gameId. It is used to populate
                    // the post-game vod playlist with highlight clips
                    if (this._shouldLoadPlaylistAfterScheduledVideo) {
                        // the currently playing video matches the id of the
                        // incoming video argument
                        const gameId = _.get(video, 'gameId', null);
                        if (gameId) {
                            this._networkingManager.fetchVideoExperiences({
                                gameId: gameId
                            });
                        }
                    }
                }
            }
        }
    }
    _onScheduledVideoPlaybackComplete(videoMetadata) {
        this.debug.log('_onScheduledVideoPlaybackComplete');
        this._eventBus.publish(VEM.events.onScheduledVideoPlaybackComplete, videoMetadata);
        // when playback is complete the event is complete
        this._onScheduledVideoComplete(videoMetadata);
    }
    _onScheduledVideoPlaybackFailed(payload) {
        this._eventBus.publish(VEM.events.onScheduledVideoPlaybackFailed, _.assign(payload, {
            state: this._stateManager.getState()
        }));
    }
    _onStateChanged(payload) {
        this._eventBus.publish(vemEvents.onStateChanged, payload);
    }
}

VEM.version = vemVersion;

/**
 * @desc VEM events
 */
VEM.events = vemEvents;

/**
 * @desc VEM states
 */
VEM.states = constants.states;

VEM.StreamEventTypes = constants.StreamEventTypes;
VEM.PositionEventTypes = constants.PositionEventTypes;

/**
 * For allowing outside access to Alert's enums
 */
VEM.Alert = Alert;
VEM.AlertPrompt = AlertPrompt;

if (typeof(window) !== 'undefined') {

    /*
    make the constructor available globally
    */
    window.VEM = VEM;
    // legacy support (still used almost everywhere)
    window.VEModule = VEM;

    /*
    Add a mechanism for clients to listen to so they can be notified when the
    module has been parsed and evaluated. This supports both async and defer.
    This is not the preferred method of detecting loading. If there is a
    Promise based loader that provides an 'onload' callback that should be
    used in preference of this approach. This could be used by clients that
    cannot supply that style of load detection.
    */
    window.vemLibraryLoadingTimer = setInterval(() => {
        if ('VEModule' in window) {
            window.dispatchEvent(new Event('onVemLibraryLoaded'));
            clearInterval(window.vemLibraryLoadingTimer);
            delete window.vemLibraryLoadingTimer;
        }
    }, 2);

}

export {
    VEM
};
