/*
TODO: create enums for type
TODO: we have more than one type now, we need to subclass
*/
import _ from 'lodash';

import { BaseManager } from '../lib/base';

import constants from '../lib/constants';
import { utils } from '../lib/utils';

import '../ui/styles/core.scss';
import './styles/countdown.scss';

class Countdown extends BaseManager {
    /**
     * @class Countdown
     * @classdesc 
     * @param {Object}    args - Constructor args object that contains the following:
     */
    constructor(args, name='Countdown') {
        super(args, name);

        this._themeManager = _.get(args, 'themeManager', null);
        this._container = _.get(args, 'container', null);
        this._video = _.get(args, 'video', {});
        this._videoId = _.get(this, '_video.videoId', '');
        this._stopCountdown = this.normalizeDateByFlooringMinutes(_.get(args, 'until', 0));
        this._node = null;
        this._timer = null;
        this._classNameForClockContainer = '.ve-countdown-clock-container';
        this._classNamePrefixForClock = 've-countdown-clock-value-';
        this._defaultTemplateType = 'team';
        this._type = _.get(args, 'type', this._defaultTemplateType);
        this._templates = {
            team: require('./templates/countdown-team.html'),
            liveEvent: require('./templates/countdown-live.html')
        };
        this._theme = this._themeManager.applyTheme({
            id: 'countdown'
        });
        this._stepInterval = 1000;
        this._padNumbers = _.get(args, 'padNumbers', _.get(this._theme, 'padNumbers', false));
        this._removeCompleted = _.get(args, 'removeCompleted', _.get(this._theme, 'removeCompleted', false));

    }
    destroy() {
        this.hide();
        this.clearTimer();
        if (this._node && this._node.parentNode) {
            this._node.parentNode.removeChild(this._node);
        }
        this._node = null;
    }
    create() {
        this._node = this.render();
        this._container.appendChild(this._node);
        this.startCountdown(this._padNumbers, this._removeCompleted);
        return this._node;
    }    
    update(endTime) {
        this._stopCountdown = this.normalizeDateByFlooringMinutes(endTime);
        this.startCountdown(this._padNumbers, this._removeCompleted);
    }
    render() {
        // this.debug.log('render', 'type', this._type);
        const template = this.getTemplate(this._type);
        let data = {};
        switch (this._type) {
            case 'team': {
                const normalizeTeamData = (team) => {
                    const output = {
                        abbr: team.abbr,
                        title: team.last_name,
                        logo: _.first(team.icons).url,
                        colors: {}
                    };
                    _.each(team.colors, (item) => {
                        output.colors[item.type] = item.value;
                    });
                    return output;
                };
                const video = this._video;
                const home = normalizeTeamData(video.homeTeam);
                const away = normalizeTeamData(video.awayTeam);
                data = {
                    homeTitle: home.title.toUpperCase(),
                    homeAbbr: home.abbr,
                    homeLogo: home.logo,
                    homeColorPrimary: home.colors.primary,
                    homeColorSecondary: home.colors.secondary,
                    awayTitle: away.title.toUpperCase(),
                    awayAbbr: away.abbr,
                    awayLogo: away.logo,
                    awayColorPrimary: away.colors.primary,
                    awayColorSecondary: away.colors.secondary
                };
                break;
            }
            case 'liveEvent': {
                // customize LiveCountdown
                // we need to break the title up into 2 lines so we can keep
                // their bounding box static despite the rest of the content
                // being fluid
                data = {
                    titleA: this._theme?.titleA,
                    titleB: this._theme?.titleB
                };
                break;
            }
        }

        return utils.render(template, data);
    }
    show() {
        if (this._node && (this._node.style.display !== 'block')) {
            this._node.style.display = 'block';
            this._eventBus.publish(constants.events.CHYRON_SHOWN, this._video);
        }
    }
    hide() {
        if (this._node && (this._node.style.display !== 'none')) {
            this._node.style.display = 'none';
            this._eventBus.publish(constants.events.COUNTDOWN_HIDDEN, this._video);
        }
    }

    parseDuration(milliseconds) {
        let remain = milliseconds;
        let d = Math.floor(remain / (1000 * 60 * 60 * 24));
        remain = remain % (1000 * 60 * 60 * 24);
        let h = Math.floor(remain / (1000 * 60 * 60));
        remain = remain % (1000 * 60 * 60);
        let m = Math.floor(remain / (1000 * 60));
        remain = remain % (1000 * 60);
        let s = Math.floor(remain / (1000));
        remain = remain % (1000);
        return {
            days: d,
            hours: h,
            minutes: m,
            seconds: s,
            milliseconds: remain
        };
    }
    clearTimer() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }
    /**
     * @params duration {Number}
     * @params padNumbers {Boolean} - should leading zeros be added to single digit numbers
     * @params removeCompleted {Boolean} - should unused values (0) be removed from the display
     */
    startCountdown(padNumbers=false, removeCompleted=true) {
        const stopCountdown = new Date(this._stopCountdown.getTime());
        let duration = stopCountdown - Date.now();
        this.clearTimer();
        
        const iterator = function (expiration) {
            const timeParts = this.parseDuration(expiration);
            let timeTally = 0;
            let stop = false;
            _.each(['days', 'hours', 'minutes', 'seconds'], (value) => {
                let timePart = Number(timeParts[value]);
                // 2020-04-21: use the removeCompleted feature flag to
                // determine if we should remove unused numbers(0) from
                // the display
                let shouldBeVisible = true;
                if (removeCompleted) {
                    if ((timePart > 0) || (timeTally > 0)) {
                        shouldBeVisible = false;
                    }
                }

                timeTally += timePart;

                let element = document.getElementById(this._classNamePrefixForClock + value);
                if (element) {
                    let elementContainer = element.parentNode;
                    if (elementContainer) {
                        let elementShim = elementContainer.nextElementSibling;
                        let elementSibling = element.nextElementSibling;

                        if (shouldBeVisible) {
                            elementContainer.style.display = 'flex';
                            if (elementShim) {
                                elementShim.style.display = 'flex';
                            }
                            elementSibling.style.display = 'block';
                        } else {
                            elementContainer.style.display = 'none';
                            if (elementShim) {
                                elementShim.style.display = 'none';
                            }
                            elementSibling.style.display = 'none';
                        }

                        // for values
                        if (timePart >= 0) {
                            timePart = String(timePart);
                            if (padNumbers) {
                                timePart = timePart.padStart(2, 0);
                            }
                            element.textContent = timePart;
                        }

                    } else {
                        stop = true;
                    }
                } else {
                    stop = true;
                }
            });

            return stop;
        };

        this.startTimer(
            duration,
            iterator.bind(this),
            this.onCountdownComplete.bind(this)
        );

    }
    startTimer(duration, onInterval, onComplete) {
        let expectedInterval = Date.now() + this._stepInterval;
        
        const step = (function () {
            let actualInterval = Date.now() - expectedInterval;
            const stepInterval = this._stepInterval;
            // console.log('duration[BEFORE]', duration);
            // console.log('expected[BEFORE]', expectedInterval);
            if (actualInterval > stepInterval) {
                // console.log('  actualInterval', actualInterval);
                // console.log('    stepInterval', stepInterval);
                // Something unexpected happened. This might happen if the
                // clients clock is running slow. Maybe the browser (tab) was
                // inactive? Possibly add special handling to avoid futile
                // "catch up" run.
            }
            // reduce duration by single stepInterval
            duration -= stepInterval;
            const abort = onInterval(duration);
            if (abort || (duration < 0)) {
                onComplete();
            } else {
                // track next expected stepInterval
                expectedInterval += stepInterval;
                const interval = Math.max(0, (stepInterval - actualInterval));
                // take into account drift
                this._timer = setTimeout(step, interval);
            }
        }).bind(this);

        // initial render
        onInterval(duration);

        // set first timeout to a single interval value
        this._timer = setTimeout(step, this._stepInterval);

    }
    getTemplate(type) {
        return this._templates[type || this._defaultTemplateType];
    }
    isShown() {
        return this._node && (this._node.style.display == 'block');
    }
    normalizeDateByFlooringMinutes(date) {
        return new Date(date.setSeconds(0, 0).valueOf());
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    onCountdownComplete() {
        this.clearTimer();
        this._eventBus.publish(constants.events.COUNTDOWN_COMPLETE, this._video);
    }

}

export {
    Countdown
};
