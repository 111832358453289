
class VideoMetadataLite {
    /**
     * @class VideoMetadataLite
     * @classdesc
     * @param {Object} json 
     */
    constructor(json) {
        this.videoId = json.id;
    }
}

export {
    VideoMetadataLite
};
