import { utils } from '../lib/utils';

class RemoteConfig {
    /**
     * @class RemoteConfig
     * @classdesc
     * @param {Object} json 
     */
    constructor(json) {
        this.scheduleUrl = json.schedule_url;
        this.configTtl = json.config_ttl;
        this.cachedTime = utils.convertStrToDate(json.cached_time);
        this.logUrl = json.log_url;
    }
}

export {
    RemoteConfig
};
