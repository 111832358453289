
const errors = {
    WRONG_INIT: 'WRONG_INIT',
    INVALID_INIT: 'INVALID_INIT',
    MISSING_ARGS: 'MISSING_ARGS',
    MISSING_NAME: 'MISSING_NAME',
    SKIP_REMOTE_LOG: 'SKIP_REMOTE_LOG'
};

export default errors;
