import _ from 'lodash';

import { utils } from '../lib/utils';

import { Alert } from '../models/alert';

const configs = {};
const alertNames = Alert.ACTION_NAMES;

/******************************************************************************
    Countdown
******************************************************************************/
configs.countdown = {
    titleA: 'Live stream',
    titleB: 'starts in:',
    padNumbers: true,
    removeCompleted: false
};

/******************************************************************************
    Live Events
******************************************************************************/
configs.liveEvents = {
    background: ''
};
// if we want to override the default title for countdown we could do this
// configs.liveEvents_countdown = {
//     titleA: 'custom title',
// };

/******************************************************************************
    NBA
******************************************************************************/
configs.nba = {
    logo: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/NBA_2020_logo_196x64.png',
    background: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/nba_2020_background_1220x688_stadium.png'
};
configs.nba_buyNow = _.merge({}, configs.nba, {
    actionName: alertNames.PromptUser,
    title: 'WATCH LIVE NBA GAMES',
    description: 'To watch live NBA games, please purchase NBA League Pass.',
    button: 'Buy now'
});
configs.nba_signIn = _.merge({}, configs.nba_buyNow, {
    actionName: alertNames.PromptUser,
    description: 'To watch live NBA games, please sign in to your Yahoo account.',
    button: 'Sign in to Yahoo Sports'
});
configs.nba_stream_picker = _.merge({}, configs.nba, {
    localeSupport: {
        'en-us': 'In English',
        'es-us': 'En Español'
    },
    messages: {
        betStream: "BetStream Broadcast",
        nowStreaming: 'Now Streaming',
        alsoAvailable: 'Also available in your region',
        noGames: 'No games available in your region'
    }
});

/******************************************************************************
    NFL
******************************************************************************/
configs.nfl = {
    logo: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/NFL_2019_small.png',
    background: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/2020-stadium-375x211.png'
};
configs.nfl_request_location = _.merge({}, configs.nfl, {
    actionName: alertNames.RequestLocation,
    title: 'WATCH LIVE NFL GAMES',
    subtitle: 'Allow location to watch live games in your region',
    button: 'Allow Location',
    waitForLocationMessage: 'Loading...',
    enableLocationMessage: utils.isSafariIOS() ? 'To watch live NFL games, please enable Safari Location Services under Privacy in the Settings app': 'To watch live NFL games, please enable browser Location settings'
});
configs.nfl_stream_picker = _.merge({}, configs.nfl, {
    localeSupport: {
        'en-us': 'In English',
        'es-us': 'En Español'
    },
    messages: {
        nowStreaming: 'Now Streaming',
        alsoAvailable: 'Also available in your region',
        noGames: 'No games available in your region'
    }
});
configs.require_instant_app = _.merge({}, configs.nfl, {
    logo: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/nfl_2021_logo_258x288_instant-app.png',
    background: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/nfl_2021_background_375x213_instant-app.png',
    title: 'Watch NFL<br/>Live now',
    description: 'Stream free on mobile using the instant app',
    button: 'Watch Now',
    /***
     * This will be applied to the top level DOM element when creating the
     * chyron.
     */
    className: 'require-instant-app',
    /***
     * This will get loaded and used as the template.
     */
    templateName: 'requireInstantApp.html'
});
configs.require_sports_app = _.merge({}, configs.nfl, {
    logo: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/nfl_2021_logo_258x288_instant-app.png',
    background: 'https://s.yimg.com/rb/screwdriver/ctv/ve-module/assets/nfl_2021_background_375x213_instant-app.png',
    title: 'Watch NFL<br/>live games',
    description: 'Stream free using the Yahoo Sports app',
    button: 'Watch Now in app',
    button2: 'Or update to IOS 15.1 to watch here',
    /***
     * This will be applied to the top level DOM element when creating the
     * chyron.
     */
    className: 'require-sports-app',
    /***
     * This will get loaded and used as the template.
     */
    templateName: 'requireSportsApp.html'
});

export {
    configs
};
