import _ from 'lodash';

import { BaseManager } from '../lib/base';
import constants from '../lib/constants';
import { utils } from '../lib/utils';

import '../ui/styles/core.scss';
import './styles/video-selection.scss';

class VideoSelection extends BaseManager {
    /**
     * @class VideoSelection
     * @classdesc 
     * @param {Object}    args - Constructor args object that contains the following:
     */
    constructor(args) {
        super(args, 'VideoSelection');

        this._themeManager = _.get(args, 'themeManager', null);
        
        this._themeId = 'stream_picker';
        this._autoHideTimeout = _.get(args, 'autoHideTimeout', 15000);
        this._videos = _.get(args, 'videos', undefined);
        this._currentPlayingId = _.get(args, 'currentPlayingId', null);
        this._container = _.get(args, 'container', null);
        this._hideTimer = null;

        this._classNames = {
            title: '.ve-vid-title',
            buttonList: '.ve-btn-list',
            button: '.ve-vid-btn',
            note: '.ve-game-notes',
            flag: '.ve-team-flag'
        };

        this._templates = {
            videoSelection: require('./templates/video-selection.html'),
            gameTitle: require('./templates/game-title.html')
        };

        this._themeData = this._applyTheme();
        
    }
    create() {
        this._node = utils.render(this._templates.videoSelection, {
            logo: _.get(this._themeData, 'logo', ''),
            background: _.get(this._themeData, 'background', ''),
            title: this._getTitleMessage()
        });
        if (this._node) {
            this._adjustOverlayPosition();
            this._createButtons(this._videos);
            this._container.appendChild(this._node);
            this._registerButtonEvents();
            this._registerContainerEvents();
        }
        return this._node;
    }
    update(videos, currentPlayingId) {
        this._themeData = this._applyTheme();
        this._videos = videos;
        this._currentPlayingId = currentPlayingId;
        this._adjustOverlayPosition();
        this._updateMessage();
        this._removeButtons();
        this._createButtons(this._videos);
        this._registerButtonEvents();
    }
    show(userActivated=false) {
        if (this._node && !this.isShown()) {
            this._node.style.display = 'block';
            if (this._hideTimer) {
              clearTimeout(this._hideTimer);
            }
            if (this._autoHideTimeout !== '*') {
              this._hideTimer = setTimeout(this.hide.bind(this), this._autoHideTimeout);
            }
            this._eventBus.publish(constants.events.VIDEO_SELECT_SHOWN, {
                userActivated: userActivated,
                videos: this._videos
            });
        }
    }
    hide(userActivated=false) {
        if (this._node && this.isShown()) {
            this._node.style.display = 'none';
            this._eventBus.publish(constants.events.VIDEO_SELECT_HIDDEN, {
                userActivated: userActivated,
                videos: this._videos
            });
        }
        if (this._hideTimer) {
          clearTimeout(this._hideTimer);
        }
    }
    destroy() {
        if (this._node) {
            this._unregisterContainerEvents();
            this._unregisterButtonEvents();
            this._node.parentNode.removeChild(this._node);
            this._node = null;
        }
    }
    _applyTheme() {
        return this._themeManager.applyTheme({
            id: this._themeId
        });
    }

    _getClassNameFromClass(classId) {
        return classId.substring(1);
    }
    _getTitleMessage() {
        // console.log('this._themeData', this._themeData);
        return (_.isEmpty(this._videos) ? this._themeData?.messages?.noGames : this._themeData?.messages?.alsoAvailable) || '';
    }
    _adjustOverlayPosition() {
        // if (this._node) {
        //     const playerStyles = window.getComputedStyle(document.querySelector('.vp-main') || document.querySelector('.yvp-main'), null);
        //     if (playerStyles) {
        //         this._node.style.width = parseInt(playerStyles.width, 10) + 'px';
        //         this._node.style.height = parseInt(playerStyles.height, 10) + 'px';
        //         this._node.style.top = '-' + this._node.style.height;
        //     }
        // }
    }
    _updateMessage() {
        if (this._node) {
            var title = this._node.querySelector(this._classNames.title);
            if (title) {
                title.innerHTML = this._getTitleMessage();
            }
        }
    }
    _createButtons(videos) {
        const buttonList = this._node && this._node.querySelector(this._classNames.buttonList);
        if (buttonList) {
            _.each(videos, (value, index) => {
                let button = this._getButton(videos[index], index);
                if (button) {
                    buttonList.appendChild(button);
                }
            });
        }
    }
    _removeButtons() {
        if (this._node) {
            this._unregisterButtonEvents();
            var buttonList = this._node.querySelector(this._classNames.buttonList)
            if (buttonList) {
                buttonList.innerHTML = '';
            }
        }
    }
    _getButton(video, index) {
        let button = null;
        if (video.homeTeam && video.awayTeam) {
            let note = '';
            let color = '';
            let flag = false;
            let videoLang = _.get(video, 'lang', 'en-us').toLowerCase();
            let lang = this._themeData.localeSupport[videoLang];
            let betStream = _.get(this._themeData, 'messages.betStream', null);
            if (video.videoId === this._currentPlayingId) {
                note = _.get(this._themeData, 'messages.nowStreaming', '');
                color = 'orange';
                flag = true;
            }
            if (video.betStream) {
                note += color ? (' ' + betStream) : betStream;
            }
            if (videoLang === 'es-us') {
                note += color ? (' ' + lang) : lang;
            }
            button = utils.render(this._templates.gameTitle, {
                id: 'btn-' + index,
                lang: lang,
                vs: 'vs',
                home_team_name: _.get(video, 'homeTeam.abbr', ''),
                home_team_logo: _.get(video, 'homeTeam.icons[0].url', ''),
                away_team_name: _.get(video, 'awayTeam.abbr', ''),
                away_team_logo: _.get(video, 'awayTeam.icons[0].url', ''),
                note: note
            });
            if (note) {
                const notesElement = button.querySelector(this._classNames.note);
                notesElement.style.display = 'block';
                if (color) {
                    notesElement.style.color = color;
                }
            }
            const flagElement = button.querySelector(this._classNames.flag);
            if (flagElement) {
                var highlightTop = 'rgb(255, 41, 70)';
                var highlightBot = 'rgb(255, 104, 22)';
                var normalTop = 'rgb(79, 68, 62)';
                var normalBot = 'rgb(162, 148, 141)';
                if (flag) {
                  flagElement.style.borderColor = highlightTop;
                  flagElement.style.background = 'linear-gradient(' + highlightTop + ', ' + highlightBot + ')';
                } else {
                  flagElement.style.borderColor = normalTop;
                  flagElement.style.background = 'linear-gradient(' + normalTop + ', ' + normalBot + ')';
                }
            }
        }
        return button;
    }
    _registerContainerEvents() {
        if (this._node) {
            window.addEventListener('resize', this._adjustOverlayPosition.bind(this), false);
            this._node.addEventListener('mousemove', this._onVoid.bind(this), false);
            this._node.addEventListener('mouseover', this._onVoid.bind(this), false);
            this._node.addEventListener('mouseout', this._onVoid.bind(this), false);
            this._node.addEventListener('touchstart', this._onVoid.bind(this), false);
            const buttonClose = document.querySelector('.ve-vid-button-close');
            if (buttonClose) {
                buttonClose.addEventListener('mouseup', this._onClose.bind(this), false);
                buttonClose.addEventListener('touchend', this._onClose.bind(this), false);
            }
        }
    }
    _unregisterContainerEvents() {
        if (this._node) {
            window.removeEventListener('resize', this._adjustOverlayPosition.bind(this));
            this._node.removeEventListener('mousemove', this._onVoid.bind(this));
            this._node.removeEventListener('mouseover', this._onVoid.bind(this));
            this._node.removeEventListener('mouseout', this._onVoid.bind(this));
            this._node.removeEventListener('touchstart', this._onVoid.bind(this));
            const buttonClose = document.querySelector('.ve-vid-button-close');
            if (buttonClose) {
                buttonClose.removeEventListener('mouseup', this._onClose.bind(this));
                buttonClose.removeEventListener('touchend', this._onClose.bind(this));
            }
        }
    }
    _registerButtonEvents() {
        let buttons = this._node && this._node.getElementsByClassName(this._getClassNameFromClass(this._classNames.button));
        _.each(buttons, (value, index) => {
            let button = buttons[index];
            if (button) {
                button.addEventListener('mouseup', this._onSelect.bind(this, { video: this._videos[index], index: index }), false);
                button.addEventListener('touchend', this._onSelect.bind(this, { video: this._videos[index], index: index }), false);
            }
        });
    }
    _unregisterButtonEvents() {
        const buttons = this._node && this._node.getElementsByClassName(this._getClassNameFromClass(this._classNames.button));
        _.each(buttons, (value, index) => {
            let button = buttons[index];
            if (button) {
                button.removeEventListener('mouseup', this._onSelect.bind(this));
                button.removeEventListener('touchend', this._onSelect.bind(this));
            }
        });
    }
    isShown() {
        return this._node && (this._node.style.display === 'block');
    }

    /**************************************************************************
        Event Handlers
    **************************************************************************/
    _onSelect() {
        this._eventBus.publish(constants.events.VIDEO_SELECT_TAPPED, arguments[0]);
        this.hide(true);
    }
    _onClose() {
        this.hide(true);
    }
    _onVoid(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

}

export {
    VideoSelection
};
